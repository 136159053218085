import React, { useEffect, useState } from 'react'
import Modal from '../Modal'
import isMobile from 'is-mobile'
import { navigate } from 'vike/client/router'
import { SEARCH_QUERY_FLAGS } from '../../utils/routing'
import { useSearchParamMutation } from '../../utils/searchParams'
import { useAuth } from '../../utils/platform_auth'
import { useSearchParamValue } from '../../utils/searchParams'

//------------------------------------------------------------
//credits components
//------------------------------------------------------------
import PaymentCreditsMobile from './credits/mobile.jsx'
import PaymentCreditsDesktop from './credits/desktop.jsx'
import SelectPackCredits from './credits/selectPack.jsx'
const CreditsInterface = {
  mobile: PaymentCreditsMobile,
  desktop: PaymentCreditsDesktop,
  select: SelectPackCredits,
}
//------------------------------------------------------------
//subscription components
//------------------------------------------------------------
import PaymentSubscriptionMobile from './subscription/mobile.jsx'
import PaymentSubscriptionDesktop from './subscription/desktop.jsx'
import SelectPlanSubscription from './subscription/selectPlan.jsx'
const SubscriptionInterface = {
  mobile: PaymentSubscriptionMobile,
  desktop: PaymentSubscriptionDesktop,
  select: SelectPlanSubscription,
}
//------------------------------------------------------------

//resolve the payment component based payment type
const PaymentInterface = {
  credits: CreditsInterface,
  subscription: SubscriptionInterface,
}

/**
 *
 * @param {*} configuration with the following properties
 * @param {boolean} configuration.isOpen is the panel open
 * @param {function} configuration.onCloseRequested function to close the panel
 * @param {string} configuration.type the type of the panel: credits, subscription
 * @returns
 */
export default function ({ type, isOpen, onCloseRequested }) {
  const auth = useAuth()
  const [product, setProduct] = useState()
  const { replaceAndSetReturnTo } = useSearchParamMutation()
  //on success, reset the product
  const success =
    useSearchParamValue(SEARCH_QUERY_FLAGS.SUCCESS, false) !== false

  //reset the product on successful purchase
  useEffect(() => {
    if (success) {
      setProduct(undefined)
    }
  }, [success])

  const onClose = () => {
    onCloseRequested()
    setProduct(undefined)
  }

  const [isMobileDevice, setIsMobileDevice] = useState(undefined)
  //once mounted wet the mobile state
  useEffect(() => {
    setIsMobileDevice(isMobile())
  }, [])
  //wait until we determine the device
  if (isMobileDevice === undefined) return null

  //resolve the payment component
  const deviceType = isMobileDevice ? 'mobile' : 'desktop'
  const PaymentComponent = PaymentInterface[type][deviceType]
  //resolve the select component
  const SelectComponent = PaymentInterface[type].select

  const modalClassName =
    type === 'credits'
      ? 'relative w-full h-full sm:h-auto sm:max-w-[700px] bg-canvas overflow-y-auto scrollbar-thin scrollbar-thumb-neutral_3 scrollbar-track-canvas'
      : 'relative w-full md:w-[92%] max-w-[1280px] h-full sm:h-auto bg-canvas overflow-y-auto scrollbar-thin scrollbar-thumb-neutral_3 scrollbar-track-canvas'

  return (
    <Modal
      isOpen={isOpen}
      onCloseRequested={onClose}
      className={modalClassName}
    >
      {/* No selected product yet */}
      {isOpen && !product && (
        <SelectComponent
          onSelected={(product) => {
            if (!auth)
              navigate(replaceAndSetReturnTo(SEARCH_QUERY_FLAGS.SIGNIN))
            setProduct(product)
          }}
          onCloseRequested={onClose}
        />
      )}
      {/* There is a selected Product */}
      {isOpen && product && (
        <PaymentComponent
          pid={product?.pid}
          onCancel={() => {
            setProduct(undefined)
          }}
        />
      )}
    </Modal>
  )
}
