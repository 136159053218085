import React from 'react'
import Typography from '../Typography'
import { useTranslation } from '../../utils/localization'

const AwardTopRated = () => {
  const t = useTranslation()

  return (
    <div className="h-[40px] flex flex-row gap-2">
      <img
        src="/static/images/award-badge.webp"
        className="h-[40px]"
      />
      <div className="h-[40px] flex flex-col justify-between text-text  leading-none">
        <Typography>2023</Typography>

        <Typography className="font-bold">
          {t`Top Rated`}
        </Typography>
      </div>
    </div>
  )
}

export default AwardTopRated
