import axios from 'axios'
import { useEffect, useRef, useState } from 'react'
import { useRecoilValueLoadable } from 'recoil'
import { useTranslation } from '../../utils/localization.js'
import { useAuth, userProfileSelector } from '../../utils/platform_auth.jsx'
import { getApiUrl } from '../../utils/routing.js'
import Button from '../Button'
import Modal from '../Modal/index.jsx'
import TextField from '../TextField/index.jsx'
import Typography from '../Typography'

const getUnsubscribeLink = (platform) => {
  if (platform === 'apple') {
    return 'https://support.apple.com/en-us/118428'
  }
  if (platform === 'google') {
    return 'https://support.google.com/googleplay/answer/7018481'
  }
  return ''
}

const getPlatformName = (platform) => {
  if (platform === 'apple') return 'Apple App Store'
  if (platform === 'google') return 'Google Play'
  return ''
}

export default function CancelSubscription({ isOpen, onCloseRequested }) {
  const auth = useAuth()
  const { contents: userProfile } = useRecoilValueLoadable(userProfileSelector)
  const nameInputRef = useRef()
  const [waitingCancelSub, setWaitingCancelSub] = useState(false)
  const [error, setError] = useState('')
  const t = useTranslation()
  const apiUrl = getApiUrl()
  const [isPlatformDialogOpen, setPlatformDialogOpen] = useState(false)
  const [platform, setPlatform] = useState('')

  useEffect(() => {
    if (isOpen) {
      setError('')
      nameInputRef.current.value = ''
    }
  }, [isOpen])

  const defaultName = t`Aldoo User` // @@ Default User Name

  async function cancelSubscription() {
    if (
      nameInputRef.current.value !== (userProfile.name.trim() || defaultName)
    ) {
      setError(t`Incorrect value`) // @@ Error message
      nameInputRef.current.focus()
      return
    }

    setWaitingCancelSub(true)
    try {
      const response = await axios.post(
        `${apiUrl}/payment-gateway/cancel-subscription`,
        {
          credentials: auth,
        },
      )

      const { result, error, platform } = response.data
      if (result) {
        onCloseRequested()
        //refresh the page
        window.location.reload()
      }

      if (error) {
        if (error === 'not_web_subscription') {
          setPlatform(platform)
          onCloseRequested()
          setPlatformDialogOpen(true)
        } else {
          setError(response.data.error)
        }
      }
    } catch (e) {
      console.log(e.message)
    }
    setWaitingCancelSub(false)
  }

  return (
    <div>
      <Modal
        isOpen={isPlatformDialogOpen}
        onCloseRequested={() => {
          setPlatformDialogOpen(false)
        }}
        className="max-w-[450px] sm:w-[500px] h-full sm:h-auto"
      >
        {/* Add a column with a title: this is a mobile subscription made at %platform%.
        then add a button that opens a link in a new tab to the platform's support page. */}
        <div className="pb-4">
          <div className="justify-center items-center sm:flex sm:items-start">
            <div className="text-center sm:text-left">
              <Typography variant="h3" className="text-text" id="modal-title">
                {t`Mobile Subscription`}
              </Typography>

              <div className="flex flex-col gap-4 mt-6">
                <Typography className="text-text_secondary text-left">
                  {t(
                    `This is a mobile subscription made at %platform%.`,
                    getPlatformName(platform),
                  )}
                </Typography>
                <Typography className="text-text_secondary text-left">
                  {t`Please follow the instructions on the platform's support page to cancel your subscription.`}
                </Typography>
                <Button
                  variant="action"
                  onClick={() => {
                    setPlatformDialogOpen(false)
                    window.open(getUnsubscribeLink(platform), '_blank')
                  }}
                >
                  {t(`Unsubscribe with %platform%`, getPlatformName(platform))}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={isOpen}
        onCloseRequested={onCloseRequested}
        className="max-w-[450px] sm:w-[500px] h-full sm:h-auto"
      >
        <div className="pb-4">
          <div className="justify-center items-center sm:flex sm:items-start">
            <div className="text-center sm:text-left">
              <Typography variant="h3" className="text-text" id="modal-title">
                {t`Cancel your subscription?`}
              </Typography>

              <div className="flex flex-col gap-4 mt-6">
                <Typography className="text-text_secondary text-left">
                  {t(`Do you really want to cancel your subscription?`)}
                </Typography>
                <Typography className="text-text_secondary text-left">
                  {t(
                    `Confirm by typing in your account name: %username%`,
                    userProfile?.name ?? defaultName,
                  )}
                </Typography>
                <TextField
                  label={t`Your Account Name`}
                  type="text"
                  inputRef={nameInputRef}
                  onChange={() => setError('')}
                  error={error}
                  isFocused
                />
              </div>
            </div>
          </div>
        </div>
        <div className="pt-3 flex gap-10 items-center justify-center">
          {!waitingCancelSub && (
            <Button
              variant="outlined-primary"
              className="rounded-full"
              onClick={onCloseRequested}
            >
              {t`Close`}
            </Button>
          )}
          <Button
            variant="action"
            className={` rounded-full ${
              waitingCancelSub ? 'opacity-50 cursor-not-allowed' : ''
            }`}
            onClick={cancelSubscription}
            disabled={waitingCancelSub}
          >
            {waitingCancelSub ? t`Please wait...` : t`Confirm`}
          </Button>
        </div>
      </Modal>
    </div>
  )
}
