import { RecoilRoot } from 'recoil'
import NotificationProvider from '../components/Notification'
import useThemeController from '../utils/useThemeController'

export { Layout }

import React, { useEffect } from 'react'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import './Layout.css'
import MessageDialogProvider from '../components/MessageDialog'
import PromptDialogProvider from '../components/PromptDialog'
import MobileRedirect from '../components/MobileRedirect'
import useFetchSupportedLanguages from '../utils/useFetchSupportedLanguages'
import useNavigateConfirm from '../utils/useNavigateConfirm'
import GoogleTracking from '../components/Tracking/google'
import FacebookTracking from '../components/Tracking/facebook'
import useSentry from '../components/SentryWrapper'
import PageViewTracker from '../components/Tracking/PageViewTracker'
import MicrosoftTracking from '../components/Tracking/microsoft'
import Chat from '../components/Chat'
import EnsureStableLogin from '../components/EnsureStableLogin'

function Layout({ children }) {
  useSentry()
  //theme controller
  useThemeController()
  //navigation confirm support: ex: Would you like to leave this translation?
  useNavigateConfirm()

  return (
    <React.StrictMode>
      <RecoilRoot>
        <PageLayout>
          <EnsureStableLogin />
          <NotificationProvider />
          <MessageDialogProvider />
          <PromptDialogProvider />
          <GoogleTracking />
          <MicrosoftTracking />
          <FacebookTracking />
          <PageViewTracker />
          <Navbar />
          <Content>{children}</Content>
          <Chat />
          <Footer />
        </PageLayout>
      </RecoilRoot>
    </React.StrictMode>
  )
}

function PageLayout({ children }) {
  useFetchSupportedLanguages()
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        maxWidth: '100%',
        margin: 'auto',
      }}
    >
      {children}
    </div>
  )
}

function Content({ children }) {
  return (
    <div
      className="bg-canvas"
      style={{
        minHeight: '100vh',
      }}
    >
      {children}
    </div>
  )
}
