import React, { useEffect, useRef } from 'react'
import { PaperAirplaneIcon, PaperClipIcon } from '@heroicons/react/24/outline'
import TypingNotification from './typing-notification'

export default function ChatFooter({
  selectedUser,
  newMessage,
  setNewMessage,
  handleSendMessage,
  handleFileUpload,
  handleKeyPress,
  sendTypingStatus
}) {
  const chatInputRef = useRef(null)
  useEffect(() => {
    if (chatInputRef.current)
      chatInputRef.current.focus({ preventScroll: false })
  }, [chatInputRef])

    // Don't send typing status on every change
  const debouncedTypingStatus = useRef(null);
  useEffect(() => {
    if (!selectedUser || newMessage == '') return;

    // Reset the typing status
    if (debouncedTypingStatus.current) return;

    sendTypingStatus();
    debouncedTypingStatus.current = setTimeout(() => {
      debouncedTypingStatus.current = null;
    }, 1000); // Send typing status every 1 second
  }, [newMessage, selectedUser, sendTypingStatus]);

  return (
    <>
      {/* Footer */}
      <div className="p-3 bg-gray-100 flex flex-col items-left space-x-2">
        <div className="bg-gray-100 flex items-center space-x-2">
          <input
            ref={chatInputRef}
            type="text"
            className="flex-grow bg-white border rounded-lg px-3 py-2 text-sm focus:outline-none"
            placeholder="Write a message"
            value={newMessage}
            onChange={(e) => setNewMessage(e.target.value)}
            onKeyPress={handleKeyPress}
          />
          <PaperAirplaneIcon
            className="w-6 h-6 text-red-500 cursor-pointer"
            onClick={handleSendMessage}
          />
          <PaperClipIcon
            className="w-6 h-6 text-red-500 cursor-pointer"
            onClick={handleFileUpload}
          />
        </div>
        <TypingNotification
          isVisible={selectedUser?.isTyping}
          name={selectedUser?.name}
        />
      </div>

      {/* Powered by footer */}
      <div className="bg-gray-100 text-gray-500 pb-3 text-[0.8em] text-center">
        Powered by <span className="font-bold">Aldoo</span>
      </div>
    </>
  )
}
