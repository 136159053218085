import { atom } from "recoil";
import { recoilPersist } from 'recoil-persist';
import { vsprintf } from "sprintf-js";
import _isArray from 'lodash/isArray'
import { useMemo } from "react";
import isNumber from "lodash/isNumber.js";
import { usePageContext } from "vike-react/usePageContext";

const { persistAtom } = recoilPersist();

export const currentLanguageAtom = atom({
    key: 'current-book-language',
    default: 'bg',
    effects: [persistAtom]
})

export const autoDetectedLanguageAtom = atom({
    key: 'auto-detected-language',
    default: false,
    effects: [persistAtom]
})


const templatedRegex = /%([a-zA-Z0-9-]+)%/g;
function rawPrintf(str, args) {
    if (!str) return
    let replacements = {};
    let index = -1;
    return str.replace(templatedRegex, (match, group1) => {
        if (!replacements[group1]) {
            replacements[group1] = index;
            index++;
        }
        return args[index];
    });
}

export function useMonthsNames() {
    const t = useTranslation()
    return useMemo(() => {
        return [
            t`Jan`,
            t`Feb`,
            t`Mar`,
            t`Apr`,
            t`May`,
            t`Jun`,
            t`Jul`,
            t`Aug`,
            t`Sep`,
            t`Oct`,
            t`Nov`,
            t`Dec`,
        ]
    }, [])
}

export function useFormatTranslatedDate() {
    const t = useTranslation()
    const monthNames = useMonthsNames()
    return (date) => {
        date = new Date(date)
        return t('%month% %day% %year%, %hour%:%minute%',
            monthNames[date.getMonth()], date.getDate(), date.getFullYear(),
            date.getHours(), date.getMinutes())
    }
}

export function useSupportedLanguages() {
    const pc = usePageContext()
    const { languages } = usePageContext()
    return languages
}

export function useTranslation() {
    const context = usePageContext()
    const { localizationTable } = context

    return useMemo(() => (s, ...args) => {
        if (_isArray(s)) {
            if (s.length > 1) {
                throw `Cannot use t with string template that have \${...}`
            }
            return localizationTable[s[0]] ?? s[0]
        }
        const translated = localizationTable[s] ?? rawPrintf(s, args)

        let result = ""
        try {
            result = vsprintf(translated ?? s, args)
        } catch (error) {
            console.error("TODO: Add Sentry to capture this: Failed to translate", s, args)
        }
        return result
    })
}

export function useCurrencyTranslation() {
    const t = useTranslation()
    const currencyTemplates = useMemo(() => ({
        usd: t`$%amount%`, // @ US Dollar short
        bgn: t`%amount% lv`, // @ Bulgarian Lev short
    }), [t])
    const currencies = useMemo(() => ({
        usd: t`$`, // @ US Dollar short
        bgn: t`lv`, // @ Bulgarian Lev short
    }), [t])

    return {
        currencyTemplates,
        localizedCost: (cost, currency, factor) => {
            factor = factor ?? 1
            const template = currencyTemplates[currency]

            const amountAsString = isNumber(cost)
                ? (cost / factor).toFixed(2)
                : '-'
            if (!template) {
                return `${amountAsString} ${currency}`
            }
            try {
                return t(template, amountAsString)
                // return vsprintf(template, [amountAsString])
            } catch {
                // console.log("Failed to translate currency(using fallback)", cost, currency, factor);
                return `${amountAsString} ${currency}`
            }

        },
        localizedCurrency: (currency) => {
            return currencies[currency] ?? currency
        },
        convertCoinsToReal: (cost, currency) => {
            const template = currencyTemplates[currency] || currencyTemplates['bgn']
            const exchangeRate = 40
            const real_price = t(template, Math.round(cost / exchangeRate).toFixed(2))
            return real_price
        }
    }
}

export function durationToText(t, duration) {
    return !duration
        ? ''
        : duration === 7
            ? t`week`
            : duration === 30
                ? t`month`
                : t`year`;
}

export function useCreatorRoles() {
    const t = useTranslation()
    return useMemo(() => [
        { id: 'author', label: t`Author` },
        { id: 'illustrator', label: t`Illustrator` },
        { id: 'translator', label: t`Translator` },
        { id: 'narrator', label: t`Narrator` },
    ], [t])
}

export function formatThousands(num) {
    if (num === undefined) return ''
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export function formatFileSize(bytes) {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
}
