import React from 'react'
import { useEffect } from 'react'

import PubSub from 'pubsub-js'
import { useRecoilValueLoadable } from 'recoil'
import {
  useAuth,
  userProfileSelector,
  useRefreshProfile,
} from '../../utils/platform_auth'

export default function MicrosoftTracking({ children }) {
  //get the user's profile
  const { contents: userProfile, state: accountState } = useRecoilValueLoadable(
    userProfileSelector,
  )
  //---------------------------------------------
  //track user's email
  //---------------------------------------------
  useEffect(() => {
    if (accountState === 'loading') return
    if (!userProfile || !userProfile.email) return
    window.uetq = window.uetq || []
    window.uetq.push('set', {
      pid: {
        em: userProfile.email,
      },
    })
  }, [userProfile, accountState])

  useEffect(() => {
    //---------------------------------------------
    // Send the page view detected by the PageViewTracker
    //---------------------------------------------
    PubSub.subscribe('Tracking_PageView', (_, url) => {})

    PubSub.subscribe('Tracking_NewAccountCreated', () => {})

    PubSub.subscribe('Tracking_Subscribed', (_, data) => {
      window.uetq = window.uetq || []
      window.uetq.push('event', 'subscribe', {
        event_label: data.pid,
        revenue_value: data.price,
        currency: data.currency,
      })
    })

    //data is:
    //price: example => 1.99
    //currency: example => USD, BGN
    //pid: example => aldoo_coin_pack_1
    PubSub.subscribe('Tracking_Purchase', (_, data) => {
      window.uetq = window.uetq || []
      window.uetq.push('event', 'purchase', {
        event_label: data.pid,
        revenue_value: data.price,
        currency: data.currency,
      })
    })
  }, [])

  return children || null
}
