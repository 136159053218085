import React, { useState } from 'react'
import { useRecoilValueLoadable } from 'recoil'
import { useTranslation } from '../../utils/localization'
import { subscriptionSelector } from '../../utils/platform_auth.jsx'
import Button from '../Button'
import Divider from '../Divider'
import PurchasePanel from '../PaymentController/PurchasePanel.jsx'
import { Spinner } from '../Spinner/index.jsx'
import Typography from '../Typography'
import CancelSubscription from './CancelSubscription.jsx'

const LabelColumn = ({ label1, label2 }) => {
  return (
    <div className="flex flex-col w-1/4 items-center">
      <div className="text-lg text-text font-semibold">{label1}</div>
      <div className="w-full h-px bg-gray-400 my-2"></div>
      <div className="text-lg text-text">{label2}</div>
    </div>
  )
}

function FeatureItem({ featureText }) {
  return (
    <li className="text-base md:text-lg mb-1">
      <Typography className="text-text">
        <span className="text-success">&#10003;</span> {featureText}
      </Typography>
    </li>
  )
}

const UserSubscriptionsPanel = () => {
  const t = useTranslation()

  const [subscriptionOpen, setSubscriptionOpen] = useState(false)
  const [confirmCancel, setConfirmCancel] = useState(false)
  const {
    contents: subscriptionProduct,
    state: subscriptionState,
  } = useRecoilValueLoadable(subscriptionSelector)

  if (subscriptionState === 'loading') return <Spinner />
  const { description, name, amount, subscription } = subscriptionProduct ?? {}
  const hasSubscription = subscription?.active
  const features = description?.split('\n')

  return (
    <div>
      <PurchasePanel
        type="subscription"
        isOpen={subscriptionOpen}
        onCloseRequested={() => setSubscriptionOpen(false)}
      />
      <CancelSubscription
        isOpen={confirmCancel}
        onCloseRequested={() => setConfirmCancel(false)}
      />
      <div className="flex flex-col items-center w-full">
        <Typography variant="h3" className="text-text">
          {t`Your Subscriptions`}
        </Typography>
        <Divider className="mt-6 mb-10 w-full" />
      </div>

      {!hasSubscription && (
        <div className="flex flex-col max-w-[600px] bg-paper p-6 gap-6 rounded-lg shadow-md mx-auto">
          <Typography variant="h3" className="mb-4">
            {t`No Active Subscription`}
          </Typography>

          <Typography className="text-base text-text_secondary mb-2">
            {t`Start your subscription to get access to all features and credits at a discount`}
          </Typography>

          <div className="flex justify-center">
            <Button
              variant="action"
              className="mt-4"
              onClick={() => setSubscriptionOpen(true)}
            >
              {t`Start Subscription`}
            </Button>
          </div>
        </div>
      )}

      {/* Active Subscription Card */}
      {hasSubscription && (
        <div className="max-w-[600px] bg-paper p-6 rounded-lg shadow-md ml-auto mr-auto mt-16">
          <Typography
            variant="h3"
            className="mb-4 text-text w-full text-center"
          >
            {name}
          </Typography>

          <ul className="list-none p-2 mb-12">
            {features.map((feature, index) => (
              <FeatureItem key={index} featureText={feature} />
            ))}
          </ul>

          <div className="flex flex-row justify-between items-center">
            <LabelColumn
              label1={t`Status`}
              label2={t(subscription.active ? `Active` : 'Inactive')}
            />
            <div>
              <Button
                onClick={() => setConfirmCancel(true)}
                variant="outlined"
                className="text-link border-primary hover:text-white hover:bg-primary"
              >
                {t`Unsubscribe`}
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default UserSubscriptionsPanel
