import { ChatBubbleLeftEllipsisIcon } from '@heroicons/react/24/outline'
import isMobile from 'is-mobile'
import PubSub from 'pubsub-js'
import React, { useEffect, useRef, useState } from 'react'
import { navigate } from 'vike/client/router'
import { SUPPORT_CHAT_API } from '../../deployment'
import { useAuth, userPreferencesSelector } from '../../utils/platform_auth.jsx'
import { SEARCH_QUERY_FLAGS } from '../../utils/routing.js'
import {
  useSearchParamMutation,
  useSearchParamValue,
} from '../../utils/searchParams.js'
import EVENTS from './aldoo-app-message-events' // Ensure events are defined
import ChatArea from './chat-area'
import ChatFooter from './chat-footer'
import ChatHeader from './chat-header.jsx'
import CustomerController from './customer-support-controller' // Ensure this controller is correctly imported
import SignInPanel from './signin-panel'
import { useRecoilState, useRecoilValueLoadable } from 'recoil'

function ChatButton({ setIsChatVisible, unreadMessages }) {
  const [isHovered, setIsHovered] = useState(false)
  return (
    <div className="fixed" style={{ zIndex: 1000 }}>
      <div
        className="fixed h-[64px] w-[64px] hover:w-[150px] bottom-5 right-5 bg-red-500 text-white rounded-full shadow-lg cursor-pointer flex items-center justify-center transition-all duration-300 ease-in-out overflow-hidden"
        style={{ outline: '2px solid white' }} // 3px white outline
        onClick={() => setIsChatVisible(true)}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        {/* Support chat icon */}
        <ChatBubbleLeftEllipsisIcon className="w-8 h-8 text-white" />

        {/* Text appears on hover */}
        <span
          className="pointer-events-none ml-2 whitespace-nowrap"
          style={{ display: isHovered ? 'block' : 'none' }}
        >
          Let's talk
        </span>
      </div>

      {unreadMessages > 0 && (
        <span
          onClick={() => setIsChatVisible(true)}
          className="p-2 fixed bottom-[100px] right-5 bg-red-500 text-white rounded-full shadow-lg cursor-pointer flex items-center justify-center transition-all duration-300 ease-in-out overflow-hidden"
        >
          {unreadMessages} new messages
        </span>
      )}
    </div>
  )
}
const Chat = () => {
  // State for messages, selected user, and more
  const [messages, setMessages] = useState([])
  const [users, setUsers] = useState([])
  const [newMessage, setNewMessage] = useState('')
  const [isChatVisible, setIsChatVisible] = useState(false)
  const controller = CustomerController() // Initialize your controller here
  const usersRef = useRef(users)
  const isChatVisibleRef = useRef(isChatVisible)
  const [supportUser, setSupportUser] = useState(null)
  const isTypingReset = useRef(null)
  const supportUserID = useRef(null)
  const [isMobileDevice, setIsMobileDevice] = useState(undefined)
  const isInitialized = useRef(false)
  const [localUser, setLocalUser] = useState(null)

  // const { contents: userpreferences } = userecoilvalueloadable(
  //   userPreferencesSelector,
  // )
  const auth = useAuth() //

  const {
    add: addSearchParam,
    remove: removeSearchParam,
  } = useSearchParamMutation()

  // useEffect(() => {
  //   console.log('preferences:', userPreferences)
  // }, [userPreferences])

  const openChat = useSearchParamValue(SEARCH_QUERY_FLAGS.CHAT)
  //open the chat if the url contains chat=true
  useEffect(() => {
    if (openChat) {
      setIsChatVisible(true)
    }
  }, [openChat])

  //reset the chat=true query param when the chat is closed
  useEffect(() => {
    if (isChatVisible) return
    navigate(removeSearchParam(SEARCH_QUERY_FLAGS.CHAT))
  }, [isChatVisible])

  const scheduleOpenChatOnLogin = () => {
    //write to local storage to open the chat once the user is logged in
    localStorage.setItem('open-chat-on-login', 'true')
  }

  //Open the chat if the user is logged in
  useEffect(() => {
    if (auth && localStorage.getItem('open-chat-on-login')) {
      setIsChatVisible(true)
      localStorage.removeItem('open-chat-on-login')
    }
  }, [auth])

  const clearIsTyping = () => {
    if (isTypingReset.current) clearTimeout(isTypingReset.current)
  }

  const sendTypingStatus = () => {
    controller.sendTypingStatus({
      to: supportUser._id,
      from: localUser._id,
      typing: true,
    })
  }

  const getSupportUser = async () => {
    if (!controller) return
    const user = await controller.getSupportUser()
    if (!user) return
    //set the support user id so when users is updated, it's found via the id
    supportUserID.current = user._id
    //add it to the contact list
    addToContactList(user)
  }

  //Initialize the controller and get the support user
  useEffect(() => {
    if (!localUser || !controller) return
    if (isInitialized.current) return
    isInitialized.current = true
    ~(async () => {
      await controller.init({ api: SUPPORT_CHAT_API, user: auth })
      await getSupportUser()
    })()
  }, [localUser])

  useEffect(() => {
    if (auth === undefined) return
    //Resolve the local user from the local storage
    //it's either the logged in user or a chat user for the annoymous account
    if (auth && auth._id) {
      setLocalUser(auth)
      return
    }
    //here we need to create a new user for the chat ( annoymous user )
    //local storage
    // console.log('Hey annoymous user...')
    //1.Ensure device id is available
    //2.
  }, [auth])

  //listen for external show requests
  useEffect(() => {
    const showChatToken = PubSub.subscribe('show-chat', () => {
      setIsChatVisible(true)
    })

    return () => {
      PubSub.unsubscribe(showChatToken)
    }
  }, [])

  useEffect(() => {
    setIsMobileDevice(isMobile())
  }, [])

  useEffect(() => {
    if (supportUser) {
      //announce over pubsub
      PubSub.publish('unread-messages', supportUser.unreadMessages)
    }
  }, [supportUser, messages, users])

  useEffect(() => {
    isChatVisibleRef.current = isChatVisible

    if (!isChatVisible) clearIsTyping()

    //get the support user state again
    if (isChatVisible && controller) {
      getSupportUser()
    }

    //get the messages immediately when the chat is visible
    if (isChatVisible && controller) {
      controller.fetchMessages()
    }
  }, [isChatVisible])

  useEffect(() => {
    usersRef.current = users
    //update the support user
    setSupportUser(users.find((u) => u._id === supportUserID.current))
  }, [users])

  const playNewMessageSound = () => {
    const audio = new Audio('/static/new_message.mp3')
    audio.play()
  }

  const setMessagesAsRead = (messages) => {
    let unreadMessages = messages.filter(
      (m) => ~m.status.indexOf('unread') && m.from !== localUser._id,
    )
    if (unreadMessages.length === 0) return messages

    unreadMessages.forEach((m) => (m.status = 'read'))
    unreadMessages = unreadMessages.map((m) => ({ _id: m._id }))

    //send to the server
    controller.setMessageStatus({
      from: supportUser._id,
      to: localUser._id,
      messages: unreadMessages,
      status: 'read',
    })

    return messages.map((m) => {
      const unreadMessage = unreadMessages.find((um) => um._id === m._id)
      if (unreadMessage) m.status = 'read'
      return m
    })
  }

  const setupEvents = () => {
    if (!controller.appMessageClient) return
    const { on } = controller.appMessageClient

    on({
      event: EVENTS.CONTACT_ADDED,
      action: (data) => addToContactList(data.contact),
    })

    on({
      event: EVENTS.NEW_MESSAGE,
      action: (data) => {
        const users = usersRef.current
        const user = users.find((u) => u._id === data.from)
        if (!user) return
        user.isTyping = false
        setUsers([...usersRef.current])

        if (isChatVisibleRef.current) {
          playNewMessageSound()
        }
      },
    })

    on({
      event: EVENTS.UPDATE_MESSAGE,
      action: (data) => {
        const users = usersRef.current
        const user = users.find((u) => u._id === data.from)
        if (!user) return
        //don't increment the unread messages if the message is a progress update
        if (data.data?.progress === undefined) {
          user.unreadMessages = user.unreadMessages
            ? user.unreadMessages + 1
            : 1
          setUsers([...usersRef.current])
        }
        setMessages((prevMessages) =>
          prevMessages.map((m) => {
            if (m._id === data._id) {
              //update the data and the message
              m.data = data.data
              m.message = data.message
            }
            return m
          }),
        )
      },
    })

    on({
      event: EVENTS.USER_ONLINE_STATUS,
      action: (data) => {
        const users = usersRef.current
        const user = users.find((u) => u._id === data.user)
        if (!user) return
        user.online = data.online
        if (!data.online) user.isTyping = false
        setUsers([...usersRef.current])
      },
    })

    //is typing event
    on({
      event: EVENTS.USER_IS_TYPING,
      action: (data) => {
        const users = usersRef.current
        const user = users.find((u) => u._id === data.from)
        if (!user) return

        //clear the previous timeout
        clearIsTyping()
        //reset the typing status after 4 seconds
        isTypingReset.current = setTimeout(() => {
          user.isTyping = false
          setUsers([...usersRef.current])
        }, 2700)

        user.isTyping = data.typing
        setUsers([...usersRef.current])
      },
    })
  }

  const processIncomingMessages = (newMessages, prevMessages) => {
    let result = prevMessages
      ? newMessages.filter(
          (message) => !prevMessages.find((m) => m._id === message._id),
        )
      : newMessages

    result = setMessagesAsRead(result)
    return result
  }

  const addToContactList = (user) => {
    const users = usersRef.current
    //if the user exists in the list, update it
    const existingUser = users.find((u) => u._id === user._id)
    if (existingUser) {
      //copy all the properties from the new user to the existing user
      Object.keys(user).forEach((key) => {
        existingUser[key] = user[key]
      })
    } else {
      users.push(user)
    }

    setUsers([...users])
  }

  const isOnline = controller?.isOnline()

  useEffect(() => {
    if (!localUser || !isOnline) return
    ~(async () => {
      const users = await controller.getContacts(localUser._id)
      //if the user doesn't exists in contacts, add it
      //other wise update the user in the list
      users.forEach((user) => addToContactList(user))
    })()
    setupEvents()
  }, [localUser, isOnline])

  useEffect(() => {
    if (!isOnline) {
      setUsers((prevUsers) =>
        prevUsers.map((user) => ({ ...user, online: false })),
      )
    }
  }, [isOnline])

  useEffect(() => {
    if (!supportUser || !isOnline) return
    setMessages([])
    let listener
    ~(async () => {
      listener = controller.listenForMessages({
        from: supportUser._id,
        to: localUser._id,
        bypassPolling: () => {
          //don't poll the actual messages if the chat is not visible
          return isChatVisibleRef.current === false
        },
        onMessages: (newMessages) => {
          if (newMessages.length === 0) return
          //reset the typing notification
          supportUser.isTyping = false
          setMessages((prevMessages) => [
            ...prevMessages,
            ...processIncomingMessages(newMessages, prevMessages),
          ])
        },
      })
    })()

    return () => {
      if (listener) listener.stopPolling()
    }
  }, [supportUser, isOnline])

  const handleSendMessage = async () => {
    if (newMessage.trim() === '') return

    const response = await controller.sendMessage({
      to: supportUser._id,
      from: localUser._id,
      payload: {
        message: newMessage,
      },
    })

    if (response.error) {
      showError(response.error)
      return
    }

    setMessages((prevMessages) => [
      ...prevMessages,
      {
        _id: response._id,
        message: newMessage,
        to: supportUser._id,
        from: localUser._id,
        time: response.time,
      },
    ])
    setNewMessage('')
  }

  const sendFile = async (file) => {
    //send the message over to the server
    const response = await controller.sendMessage({
      to: supportUser._id,
      from: localUser._id,
      payload: {
        message: file.name,
        data: { file: file.name, status: 'uploading', progress: 0 },
      },
    })

    if (response.error) {
      showError(response.error)
      return
    }

    const fileMessage = {
      _id: response._id,
      message: file.name,
      data: { file: file.name, status: 'uploading', progress: 0 },
      time: response.time,
    }

    setMessages((prevMessages) => [
      ...prevMessages.filter((m) => m._id !== response._id),
      fileMessage,
    ])

    //send the file to the server reporting the progress
    const fileResponse = await controller.sendFile({
      to: supportUser._id,
      from: localUser._id,
      messageID: response._id,
      file,
      onProgress: async (progress) => {
        //set the progress
        fileMessage.data.progress = progress
        //the file has been uploaded, don't announce it
        //or the file is already at 100%
        if (fileMessage.data.status === 'ready' || progress === 100) return
        //update the message
        controller.updateMessage(fileMessage)
        //update the fileMessage in the messages array
        setMessages((prevMessages) =>
          prevMessages.map((m) => {
            if (m._id === fileMessage._id) {
              m.data = fileMessage.data
            }
            return m
          }),
        )
      },
    })

    if (fileResponse.error) {
      showError(fileResponse.error)
      return
    }

    //the file has been uploaded successfully
    if (fileResponse.result) {
      fileMessage.data.status = 'ready'
      fileMessage.data.fileUrl = fileResponse.result
      controller.updateMessage(fileMessage)
      //announce the file has been uploaded to make sure the other party
      //doesn't miss the first one due to the progress updates
      setTimeout(() => controller?.updateMessage(fileMessage), 2000)
      //update the fileMessage in the messages array
      setMessages((prevMessages) =>
        prevMessages.map((m) => {
          if (m._id === fileMessage._id) {
            m.data = fileMessage.data
          }
          return m
        }),
      )
    }
  }

  const showError = (message) => {
    //send a local message to the user tht the file is too large
    setMessages((prevMessages) => [
      ...prevMessages,
      {
        _id: Math.random(),
        message,
        data: { error: true },
        from: localUser._id,
        to: supportUser._id,
        time: new Date().toISOString(),
      },
    ])
  }

  const handleFileUpload = async () => {
    const fileInput = document.createElement('input')
    fileInput.type = 'file'
    fileInput.accept =
      'application/zip, video/*, image/*,application/pdf,docx/*,doc/*,xls/*,xlsx/*,ppt/*,pptx/*,txt/*'
    fileInput.onchange = async (e) => {
      const file = e.target.files[0]
      //remove the file input
      if (!file) return fileInput.remove()

      //check if the file is larger than 50MB
      if (file.size > 50 * 1024 * 1024) {
        //send a local message to the user tht the file is too large
        setMessages((prevMessages) => [
          ...prevMessages,
          {
            _id: Math.random(),
            message:
              'This file is too large! The maximum allowed size is 50MB.',
            data: { error: true },
            from: localUser._id,
            to: supportUser._id,
            time: new Date().toISOString(),
          },
        ])
        //clean up
        fileInput.remove()
        return
      }

      await sendFile(file)

      //finally remove it
      fileInput.remove()
    }
    //remove the file input if the user cancels
    fileInput.oncancel = () => fileInput.remove()

    fileInput.click()
  }

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      handleSendMessage()
    }
  }

  const chatStyle = isMobileDevice
    ? 'fixed top-0 left-0 w-full'
    : 'fixed bottom-5 right-5 w-[360px]'

  if (isMobileDevice === undefined) return null

  return (
    <>
      {!isMobileDevice && !isChatVisible && (
        <ChatButton
          setIsChatVisible={setIsChatVisible}
          unreadMessages={supportUser?.unreadMessages}
        />
      )}

      {/* Chat Window */}
      {isChatVisible && (
        <div
          className={
            chatStyle +
            ' bg-white shadow-2xl rounded-lg overflow-hidden  outline outline-1 outline-red-500'
          }
          style={{ zIndex: 1000 }}
        >
          {/* Header */}
          <ChatHeader
            localUser={localUser}
            supportUser={supportUser}
            setIsChatVisible={setIsChatVisible}
          />

          {/* Chat Area */}
          {localUser && (
            <ChatArea
              selectedUser={supportUser}
              messages={messages}
              isChatVisible={isChatVisible}
            />
          )}
          {!localUser && (
            <SignInPanel
              onSignIn={() => {
                setIsChatVisible(false)
                //wait and redirect to the sign in page
                setTimeout(
                  () => navigate(addSearchParam(SEARCH_QUERY_FLAGS.SIGNIN)),
                  20,
                )
                //open the chat once the user is logged in
                scheduleOpenChatOnLogin()
              }}
            />
          )}

          {/* Footer */}
          {localUser && (
            <ChatFooter
              selectedUser={supportUser}
              newMessage={newMessage}
              setNewMessage={setNewMessage}
              sendTypingStatus={sendTypingStatus}
              handleSendMessage={handleSendMessage}
              handleFileUpload={handleFileUpload}
              handleKeyPress={handleKeyPress}
            />
          )}
        </div>
      )}
    </>
  )
}

export default Chat
