import React from 'react'
import Button from '../Button'
import Typography from '../Typography'
import { useTranslation } from '../../utils/localization'
import { navigate } from 'vike/client/router'
import { SEARCH_QUERY_FLAGS } from '../../utils/routing.js'
import { useSearchParamMutation } from '../../utils/searchParams.js'
import { formatThousands } from '../../utils/localization'
import { PlusCircleIcon } from '@heroicons/react/24/outline'

const AddCreditsMobile = ({ account, hasSubscription }) => {
  const t = useTranslation()
  const { add: addSearchParam } = useSearchParamMutation()

  return (
    <div className="flex flex-col justify-end items-end">
      <button
        className="flex h-[36px] items-center justify-between gap-2 bg-primary rounded-full px-4"
        onClick={() =>
          navigate(
            addSearchParam(
              hasSubscription
                ? SEARCH_QUERY_FLAGS.PACKAGES
                : SEARCH_QUERY_FLAGS.PACKAGES,
            ),
          )
        }
      >
        <PlusCircleIcon className="w-6 h-6 text-white" />
        <div className="flex items-baseline gap-2">
          <Typography className="text-xl  text-white font-semibold">
            {formatThousands(account?.coins)}
          </Typography>
        </div>
      </button>
    </div>
  )
}

export default AddCreditsMobile
