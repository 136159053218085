import React, { useRef } from 'react'
import { useRecoilValue } from 'recoil'
import { navigate } from 'vike/client/router'
import {
  useCurrencyTranslation,
  useTranslation,
} from '../../../utils/localization'
import { currencyAtom } from '../../../utils/platform_auth'
import PTRestAPI from '../../../utils/pt_rest_api'
import { SEARCH_QUERY_FLAGS } from '../../../utils/routing.js'
import { useSearchParamMutation } from '../../../utils/searchParams'
import { usePromiseWithAuth } from '../../../utils/usePromise'
import Button from '../../Button'
import { Spinner } from '../../Spinner/index.jsx'
import Typography from '../../Typography'

const CreditPack = ({ product, onSelected }) => {
  const t = useTranslation()
  const { localizedCost } = useCurrencyTranslation()
  const { price, amount } = product
  const planInfoRef = useRef()
  const currency = useRecoilValue(currencyAtom)

  return (
    <div
      ref={planInfoRef}
      className="flex flex-row w-full py-[12px] justify-between items-center gap-2 hover:bg-neutral_1"
    >
      <Typography variant="h4" className="flex-3 flex-grow text-text">
        {localizedCost(price?.amount, currency, 100)}
      </Typography>
      <Typography className=" text-sm sm:text-base flex-3 text-text mr-4">
        {t('%amount% pages', amount)}
      </Typography>
      <Button
        variant="action"
        className="min-w-[120px]"
        // className="flex-3 w-[100px] sm:w-[150px] text-sm sm:text-base text-text_secondary hover:text-white hover:bg-primary border-neutral_2 hover:border-primary_lt ml-auto"
        onClick={() => onSelected(product)}
      >
        {/* {amount} {t`Credits`} */}
        {t('%amount% credits', amount)}
      </Button>
    </div>
  )
}
const PlanOffer = ({ onCloseRequested }) => {
  const t = useTranslation()
  const {
    remove: removeSearchParam,
    replace: replaceSearchParam,
  } = useSearchParamMutation()
  return (
    <div className="flex flex-col justify-center items-center">
      <Button
        variant="outlined"
        className="mt-2"
        onClick={() => {
          if (onCloseRequested) onCloseRequested()
          navigate(
            replaceSearchParam(
              SEARCH_QUERY_FLAGS.PACKAGES,
              SEARCH_QUERY_FLAGS.PLANS,
            ),
          )
        }}
      >
        {t`See Plans`}
      </Button>
      <Typography className="mt-5 text-center text-text text-sm sm:text-base">
        {t`Subscribe and get more credits, discounts and more...`}
      </Typography>
    </div>
  )
}

export default function SelectPack({ onSelected, onCloseRequested }) {
  const t = useTranslation()

  const currency = useRecoilValue(currencyAtom)
  const { result: products } = usePromiseWithAuth(
    (auth) =>
      PTRestAPI.GetCreditPacks(
        auth,
        {
          fields:
            'amount real_price subscriptions name pid description discount_label discount_percentage',
        },
        currency,
      ),
    false,
    false,
  )
  return (
    <div className="w-full h-[100%] m-auto flex flex-col items-center justify-between text-text gap-4 md:px-10">
      <Typography variant="h3" className="text-text mb-4">
        {t`Buy Translation Credits`}
      </Typography>

      <div className="w-full">
        {(!products || products.length == 0) && (
          <div className="w-full border-b border-bordered flex justify-center items-center">
            <Spinner size="50" className="relative transform" />
          </div>
        )}
        {products &&
          products.length > 0 &&
          products?.map((product, idx) => {
            return (
              <div className="flex flex-col w-full" key={idx}>
                <CreditPack product={product} onSelected={onSelected} />
                {idx < products.length - 1 && (
                  <div className="w-full border-b border-bordered hover:border-link"></div>
                )}
              </div>
            )
          })}
      </div>

      <div className="mt-6 sm:mt-0">
        <PlanOffer onCloseRequested={onCloseRequested} />
      </div>
    </div>
  )
}
