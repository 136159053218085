import { useEffect, useRef, useState } from "react";

export function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

export function useStartStopWithDelay(delayStart, delayStop, func) {
    const startTimer = useRef(null);
    const stopTimer = useRef(null);
    const state = useRef(false);

    const start = () => {
        if (state.current) // already started
            return

        state.current = true

        clearTimeout(stopTimer.current)
        stopTimer.current = null

        startTimer.current = setTimeout(() => {
            startTimer.current = null
            func(true)
        }, delayStart)
    }

    const stop = () => {
        // already stopped
        if (!state.current) {
            //When stopping while in "same state", we still need to call the func
            //The issue that introduced this fix was: at random the number of stops
            //may not be the same as the number of stars
            func(false)
            return
        }



        state.current = false

        clearTimeout(startTimer.current)
        startTimer.current = null

        stopTimer.current = setTimeout(() => {
            stopTimer.current = null
            func(false)
        }, delayStop)
    }

    return { start, stop }
}

export const useStopwatch = (duration, startPaused = false) => {
    const [elapsedTime, setElapsedTime] = useState(0)
    const [unpauseTime, setUnpauseTime] = useState(!startPaused ? Date.now() : null)
    const [isDone, setIsDone] = useState(false)
    // console.trace({isDone, duration, unpauseTime, elapsedTime, now: Date.now(), startPaused})

    const elapsed = () => {
        if (!unpauseTime)
            return Math.min(elapsedTime, duration)

        return Math.min(elapsedTime + (Date.now() - unpauseTime) / 1000, duration)
    }

    useEffect(() => {
        if (isDone && elapsed() < duration) {
            setIsDone(false)
        }
    }, [isDone, unpauseTime, elapsedTime, duration]);

    useEffect(() => {
        if (unpauseTime && !isDone) {
            const timer = setTimeout(() => setIsDone(true), Math.max(0, (duration - elapsedTime) * 1000))
            // console.trace(`creating timer #${timer} after ${duration - elapsedTime}s @1`)
            return () => {
                // console.trace(`clearing timer #${timer} @1`)
                clearTimeout(timer)
            }
        }
    }, [isDone, unpauseTime, elapsedTime, duration])

    const reset = (time) => {
        // console.trace("reset")
        setElapsedTime(time || 0)
        if (unpauseTime !== null)
            setUnpauseTime(Date.now())
    };

    const pause = () => {
        // console.trace("pause")
        if (unpauseTime) {
            setElapsedTime(elapsedTime + (Date.now() - unpauseTime) / 1000)
            setUnpauseTime(null)
        }
    }

    const unpause = () => {
        // console.trace("unpause")
        if (!unpauseTime)
            setUnpauseTime(Date.now())
    }

    const setPaused = (isPaused) => {
        if (isPaused)
            pause()
        else
            unpause()
    }

    const isPaused = () => {
        return !isDone && !unpauseTime
    }

    return {
        isDone,
        reset,
        pause,
        unpause,
        setPaused,
        elapsed,
        isPaused
    }
}

export function useTimer(callback, ms, isPaused, resetDeps) {
    const startTime = useRef({})
    const [fired, setFired] = useState(false)

    useEffect(() => {
        setFired(false)
        startTime.current.passedTime = 0
    }, resetDeps)
    useEffect(() => {
        if (isPaused || fired)
            return
        startTime.current.startTime = Date.now()

        const timer = setTimeout(() => {
            if (callback) {
                callback()
            }
            setFired(true)
        }, Math.max(ms - startTime.current.passedTime, 0))
        return () => {
            clearTimeout(timer)
            startTime.current.passedTime += Date.now() - startTime.current.startTime
        }
    }, [isPaused, fired])
}

export function formatTime(t, seconds) {
    const minutes = Math.floor(seconds / 60)
    if (minutes < 5)
        return t`less than 5 minutes`
    if (minutes > 59) {
        const hours = Math.floor(minutes / 60)
        if (hours > 1)
            return t('%hours% hours', hours)
        else
            return t(`1 hour`)
    }
    return t('%minutes% minutes', minutes)
}
