import React, { useState, useEffect } from 'react'
import Button from '../Button'
import Typography from '../Typography'
import { XMarkIcon } from '@heroicons/react/24/solid'
import { useAuth } from '../../utils/platform_auth'
import PTRestAPI from '../../utils/pt_rest_api'
import FilePreview from '../FilePreview'
import downloadFile from '../../utils/useDownloadFile'
import { useTranslation } from '../../utils/localization'
import { useResolveLanguageName } from '../../utils/useResolveLanguageName'
import { formatTime } from '../../utils/time.js'
import ContactPanel from '../ContactPanel'

const formatFilename = (filename) => {
  if (!filename) return ''
  return filename.substring(filename.lastIndexOf('/') + 1)
}

const resolveLanguageCode = (code, languages) => {
  const language = languages.find((lang) => lang.languageCode === code)
  return language ? language.displayName : code
}

const OrderDetailsDialog = ({ orderDetails, isOpen, onClose }) => {
  const auth = useAuth()
  const [previewUrl, setPreviewUrl] = useState(null)
  const t = useTranslation()
  const resolveLanguageName = useResolveLanguageName()
  const [contactOpen, setContactOpen] = useState(false)

  let { files } =
    orderDetails && orderDetails.files ? orderDetails : { files: [] }
  const hasFiles = files && files.length > 0 && files[0] !== 'N/A'

  const viewFile = async (file) => {
    const { url } = await PTRestAPI.GetResultURL(auth, orderDetails.orderID)
    if (url) {
      setPreviewUrl(url)
    }
  }
  const handleDownload = async (file) => {
    const { url } = await PTRestAPI.GetResultURL(auth, orderDetails.orderID)
    if (url) {
      downloadFile(url, formatFilename(file))
    }
  }

  return (
    isOpen && (
      <div>
        <FilePreview
          isOpen={previewUrl !== null}
          file={previewUrl}
          onCloseRequested={() => {
            setPreviewUrl(null)
          }}
        />
        <ContactPanel
          isOpen={contactOpen}
          onCloseRequested={() => setContactOpen(false)}
        />

        <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50">
          <div className="bg-paper rounded-lg shadow-lg w-[90%] max-w-2xl relative">
            <Button
              variant="icon"
              className="absolute top-3 right-3 text-text_secondary hover:text-text_inv"
              onClick={onClose}
            >
              <XMarkIcon className="h-7 w-7 text-neutral_3 hover:text-text_inv" />
            </Button>

            <div className="p-6">
              <div className="flex flex-col items-center space-x-2 mb-4">
                <Typography variant="h3" className="text-2xl mb-4">
                  {t`Order Details`}
                </Typography>
                <span className="text-text_secondary text-xs">
                  (order number: {orderDetails.orderID})
                </span>
              </div>
              <div className="border-b border-bordered pb-4 mb-4">
                <Typography variant="h4" className="mb-2">
                  {t`Files`}
                </Typography>

                <ul>
                  {hasFiles ? (
                    files.map((file, index) => (
                      <li key={index} className="flex items-center mb-2">
                        <span className="flex-1 overflow-hidden text-ellipsis text-text pr-4">
                          {formatFilename(file)}
                        </span>
                        {orderDetails.secsBeforeExpires > 0 ? (
                          <div className="flex flex-col space-y-1">
                            <div className="flex space-x-2">
                              <Button
                                onClick={() => viewFile(file)}
                                variant="outlined"
                                className="text-link hover:text-primary_dk font-bold border border-link hover:border-primary_dk rounded-full"
                              >
                                {t`View`}
                              </Button>
                              <Button
                                onClick={() => handleDownload(file)}
                                variant="action"
                                className="rounded-full"
                              >
                                {t`Download`}
                              </Button>
                            </div>
                            <div className="text-text">
                              {t(
                                'Expires in %time%',
                                formatTime(t, orderDetails.secsBeforeExpires),
                              )}
                            </div>
                          </div>
                        ) : (
                          <div className="text-text">{t`Expired`}</div>
                        )}
                      </li>
                    ))
                  ) : (
                    <li className="text-text_secondary">{t`Not available`}</li>
                  )}
                </ul>
              </div>
              <div className="space-y-2">
                {Object.keys(orderDetails)
                  .sort()
                  .filter(
                    (key) =>
                      key !== 'files' &&
                      key !== 'orderID' &&
                      key !== 'secsBeforeExpires',
                  )
                  .map((key, index, array) => {
                    if (Array.isArray(orderDetails)) return null
                    let value = orderDetails[key]
                    if (key === 'cost') {
                      value = `${t('%credits% credits', value)}`
                    }
                    if (key === 'language_in' || key === 'language_out') {
                      value = resolveLanguageName(value)
                      value = value ?? t`Auto`
                    }

                    return (
                      <div key={key}>
                        <div className="flex justify-between">
                          <span className="font-medium text-text capitalize">
                            {key.replace(/_/g, ' ')}
                          </span>
                          <span className="text-text_secondary">
                            {value || t`N/A`}
                          </span>
                        </div>
                        {index !== array.length - 1 && (
                          <div className="border-b border-bordered my-2"></div>
                        )}
                      </div>
                    )
                  })}
              </div>
              <div className="w-full items-center justify-center flex flex-row sm:flex-row gap-4">
                <Button
                  variant="viewer_controls_small"
                  className="mt-5"
                  onClick={() => {
                    setContactOpen(true)
                  }}
                >
                  Report a problem
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  )
}

export default OrderDetailsDialog
