import { useState } from 'react'
import Button from '../Button'
import Tooltip from '../Tooltip'
import { useTranslation } from '../../utils/localization'

export default ({ controller }) => {
  const [showMinimap, setShowMinimap] = useState(controller.params.minimap)
  const t = useTranslation()

  const toggleMinimap = () => {
    setShowMinimap(!showMinimap)
    controller.toggleMinimap(!showMinimap)
  }

  return (
    <div className="flex items-center space-x-2">
      <Tooltip text={t`Toggle document outline`}>
        <Button variant="viewer_controls" onClick={toggleMinimap}>
          {showMinimap ? '< Outline' : 'Outline'}
        </Button>
      </Tooltip>
    </div>
  )
}
