import React from 'react'

const OrderStatusBubble = ({ status }) => {
  let colorClass = ''

  switch (status.toLowerCase()) {
    case 'estimated':
      colorClass = 'text-text'
      break
    case 'cancelled':
      colorClass = 'bg-error text-text_inv'
      break
    case 'error':
      colorClass = 'bg-warning text-white'
      break
    case 'completed':
      colorClass = 'bg-primary_dk text-white'
      break
    case 'failed':

    case 'pending':
      colorClass = 'text-error'
      break
    default:
      colorClass = 'bg-progress'
      break
  }

  return (
    <div
      className={`inline-block px-3 py-1 text-sm rounded-full ${colorClass}`}
    >
      {status}
    </div>
  )
}

export default OrderStatusBubble
