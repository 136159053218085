import { Spinner } from '../Spinner'
export default function PaymentSkeleton() {
  return (
    <div className="space-y-8 pt-4 flex flex-col justify-center items-center">
      <div className="bg-neutral_1 h-10 w-full rounded"></div>
      <div className="bg-neutral_1 h-10 w-full rounded"></div>
      <div className="bg-neutral_1 h-10 w-full rounded"></div>
      <div className="bg-neutral_1 h-10 w-full rounded"></div>
      <Spinner className="w-10 h-10" />
    </div>
  )
}
