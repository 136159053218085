import React from 'react'
import Typography from '../../Typography'
import { useCurrencyTranslation } from '../../../utils/localization'

export default ({ amount, discountedAmount, currency, product, className }) => {
  const { localizedCost } = useCurrencyTranslation()

  return (
    <div
      className={`relative flex h-36 border border-bordered rounded-lg shadow px-4 pb-2 ${className}`}
    >
      <div className="flex flex-col justify-between w-full text-text">
        <div className="flex-1 p-1">
          <Typography className="font-title text-text font-bold text-lg sm:text-xl lg:text-2xl">
            {product.name}
          </Typography>
          <Typography className="text-text font-normal text-base">
            {product.description}
          </Typography>
        </div>
        <div className="flex w-full justify-between items-end">
          <div className="flex flex-col">
            {discountedAmount && (
              <Typography className="text-lg text-text line-through m-0">
                {localizedCost(amount, currency, 100)}
              </Typography>
            )}
          </div>
          <div className="flex flex-col items-end">
            <Typography className="text-2xl text-text m-0">
              {localizedCost(discountedAmount ?? amount, currency, 100)}
            </Typography>
          </div>
        </div>
      </div>
    </div>
  )
}
