import React from 'react'
import Tooltip from '../Tooltip'
import { EnvelopeIcon } from '@heroicons/react/24/outline'
import FileBubble from './file-bubble'
import ErrorBubble from './error-bubble'

const MessageBubble = ({ message, avatar, isSupport }) => {
  //this is a file upload message
  if (message.data?.file) {
    return (
      <FileBubble avatar={avatar} message={message} isSupport={isSupport} />
    )
  }

  if (message.data?.error) {
    return <ErrorBubble message={message} />
  }

  const urlRegex = /(https?:\/\/[^\s]+)/g
  const emailRegex = /([a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})/g

  // Parses the message to detect and replace URLs and emails
  const parseMessage = (text) => {
    if (!text) return text // Early return for empty messages

    const combinedRegex = new RegExp(
      `${urlRegex.source}|${emailRegex.source}`,
      'g',
    )
    const parts = text.split(combinedRegex)

    return parts.map((part, index) => {
      if (urlRegex.test(part)) {
        const openTarget = part.includes(window.location.origin)
          ? '_self'
          : '_blank'
        return (
          <div key={`url-${index}`} className="flex justify-center my-2">
            <Tooltip key={`url-${index}`} text={part} position="top">
              <button
                className="bg-blue-500 text-white mx-2 px-3 py-1 rounded hover:bg-blue-600"
                onClick={() => window.open(part, openTarget)}
              >
                Open Link
              </button>
            </Tooltip>
          </div>
        )
      }

      if (emailRegex.test(part)) {
        return (
          <div key={`email-${index}`} className="flex justify-center my-2">
            <Tooltip text="Send Mail" position="top">
              <button
                className="bg-red-500 text-white mx-2 px-3 py-1 rounded hover:bg-red-600 flex items-center space-x-1"
                onClick={() => (window.location.href = `mailto:${part}`)}
              >
                <EnvelopeIcon className="w-4 h-4" />
                <span>{part}</span>
              </button>
            </Tooltip>
          </div>
        )
      }

      return (
        <span key={`text-${index}`} className="block">
          {part}
        </span>
      ) // Ensure the text after the button appears on a new line
    })
  }

  return (
    <div
      className={`flex ${isSupport ? 'items-start' : 'justify-end'} space-x-2`}
    >
      {isSupport && avatar && (
        <img src={avatar} alt="Support" className="w-8 h-8 rounded-full" />
      )}
      <div
        className={`${
          isSupport ? 'bg-red-100 text-black' : 'bg-blue-100 text-black'
        } p-3 rounded-lg text-sm max-w-xs break-words`}
      >
        {parseMessage(message.message)}
      </div>
    </div>
  )
}

export default MessageBubble
