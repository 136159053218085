import Button from '../Button'
import { useTranslation } from '../../utils/localization'
import { useCallback } from 'react'
import Tooltip from '../Tooltip'
import Navigation from '../../utils/navigation'
export default ({ controller }) => {
  const t = useTranslation()
  const { standalone } = controller.params

  //this will display a prompt prior to navigating to the order history page
  const openHistory = () => {
    Navigation.goTo('/user?section=order-history')
  }

  return (
    !standalone && (
      <div className={`flex items-center space-x-2`}>
        <Tooltip text={t(`See your files history`)}>
          <Button variant="viewer_controls" onClick={openHistory}>
            {t`History`}
          </Button>
        </Tooltip>
      </div>
    )
  )
}
