import React, { useRef, useState } from 'react'
import { pdfjs } from 'react-pdf'
import 'react-pdf/dist/esm/Page/AnnotationLayer.css'
import 'react-pdf/dist/esm/Page/TextLayer.css'
import { motion } from 'framer-motion'

import ToolBar from './toolbar'
import FileInfoPanel from '../FileInfoPanel'
import { formatFileSize } from '../../utils/localization'
import PDFViewer from '../PDFViewer'
import MSViewer from '../MSViewer'

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`

function getFileType(fileOrUrl) {
  let result = ''
  if (typeof fileOrUrl === 'string') {
    // Assume it's a URL and extract the file extension from the URL
    const url = new URL(fileOrUrl)
    const pathname = url.pathname
    const extension = pathname.substring(pathname.lastIndexOf('.') + 1)
    result = extension
  } else if (fileOrUrl instanceof File) {
    // Assume it's a File object and get the type property
    result = fileOrUrl.type
  } else {
    // throw new Error('Invalid input: expected a URL string or a File object.')
  }

  //if the file type is in format application/type get the type only
  const index = result.indexOf('/')
  if (index > 0) result = result.substring(index + 1)

  //xlsx
  if (
    result === 'vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
    result === 'application/vnd.ms-excel'
  )
    return 'xlsx'

  //convert to docx
  if (result === 'vnd.openxmlformats-officedocument.wordprocessingml.document')
    return 'docx'

  return result
}

function getFileName(fileOrUrl) {
  if (typeof fileOrUrl === 'string') {
    // Assume it's a URL and extract the file name from the URL
    //if the url is relative expand it to a full url

    //if the url is relative expand it to a full url
    if (!fileOrUrl.startsWith('http')) {
      fileOrUrl = window.location.origin + fileOrUrl
    }

    const url = new URL(fileOrUrl)
    const pathname = url.pathname
    const fileName = pathname.substring(pathname.lastIndexOf('/') + 1)
    return fileName
  } else if (fileOrUrl instanceof File) {
    // Assume it's a File object and get the name property
    return fileOrUrl.name
  } else {
    // throw new Error('Invalid input: expected a URL string or a File object.')
  }
}

const DefaultFileViewer = ({
  standalone,
  input,
  filename,
  password,
  closeAction,
  appController,
}) => {
  const fileType = getFileType(input)
  filename = filename ?? getFileName(input)

  //handle pdfs
  if (fileType === 'pdf') {
    return (
      <PDFViewer
        standalone={standalone}
        appController={appController}
        input={input}
        filename={filename}
        closeAction={closeAction}
        password={password}
      />
    )
  }

  if (fileType === 'docx')
    return (
      <MSViewer
        standalone={standalone}
        appController={appController}
        input={input}
        filename={filename}
        closeAction={closeAction}
      />
    )

  const viewer = useRef()

  //get the filename from the pdfURL as it may a blob or a file or url
  const fileSize = input?.size ? formatFileSize(input.size) : ''

  //if the input is a file convert it to url
  // if (input instanceof File) input = URL.createObjectURL(input)

  const [params, setParams] = useState({
    zoom: 1,
    rotate: 0,
    minimap: false,
    numPages: 0,
    currentPage: 1,
    closeAction,
    input,
    filename,
  })

  const controller = {
    params,
    setZoom: (zoom) => setParams({ ...params, zoom }),
  }

  return (
    <div className="w-full h-full relative">
      <div className="w-full h-full">
        <ToolBar controller={controller} />
        <FileInfoPanel
          controller={controller}
          filename={filename}
          fileSize={fileSize}
        />
      </div>

      <motion.div
        ref={viewer}
        className="w-full absolute top-0 scrollbar scrollbar-thin scrollbar-thumb-neutral_1 scrollbar-track-canvas overflow-y-scroll h-full flex justify-center m-0"
        animate={{ paddingLeft: params.minimap ? 200 : 0 }}
        transition={{ duration: 0.5 }} // Adjust duration as needed
      >
        <div
          className="flex flex-col  w-full h-full pt-[100px]"
          style={{ zoom: params.zoom }}
        >
          {/* Just show the file name as a default preview */}
          <div className="text-3xl font-bold text-neutral_1 text-center">
            {filename}
          </div>
        </div>
      </motion.div>
    </div>
  )
}

export default DefaultFileViewer
