import React, { useState, useEffect } from 'react'
import { useTranslation } from '../../utils/localization'
import useScrollLock from '../../utils/useScrollLock'
import DefaultFileViewer from '../DefaultFileViewer'
import isMobile from 'is-mobile'

//standalone - if the preview covers the whole screen
const FilePreview = ({ standalone, file, isOpen, onCloseRequested }) => {
  const { t } = useTranslation()

  const [isMobileDevice, setIsMobileDevice] = useState(undefined)
  useScrollLock(isOpen)

  useEffect(() => {
    setIsMobileDevice(isMobile())
  }, [])

  if (isMobileDevice === undefined) return null

  return (
    <div>
      {isOpen && (
        <div
          className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50"
          style={{
            zIndex: 300,
          }}
        >
          <div
            className={`w-full h-full bg-paper overflow-hidden ${
              isMobileDevice ? '' : 'min-w-[800px]'
            }`}
          >
            <DefaultFileViewer
              standalone={standalone}
              closeAction={onCloseRequested}
              input={file}
            />
          </div>
        </div>
      )}
    </div>
  )
}

export default FilePreview
