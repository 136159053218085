import { useState } from 'react'
import { useTranslation } from '../../utils/localization.js'
import Button from '../Button'
import { useAuth } from '../../utils/platform_auth.jsx'
import { getApiUrl } from '../../utils/routing.js'
import axios from 'axios'
import { Spinner } from '../Spinner/index.jsx'

export function Coupon({ payment, subscription, onCouponApplied }) {
  const [coupon, setCoupon] = useState('')
  const [error, setError] = useState()
  const auth = useAuth()
  const t = useTranslation()
  const [processing, setProcessing] = useState(false)

  const errorToLabel = (error) => {
    if (error === 'Invalid coupon code') return t`Invalid coupon code`
  }

  const applyCoupon = async () => {
    try {
      if (!coupon || coupon === '') {
        setError(t`Please enter a valid coupon code`)
        return
      }

      setError(undefined)
      setProcessing(true)
      // Make sure there are no spaces in the coupon code
      const sanitizedCoupon = coupon.replace(/\s/g, '')
      const apiUrl = getApiUrl()
      const response = await axios.post(
        `${apiUrl}/payment-gateway/apply-coupon`,
        {
          credentials: auth,
          payment,
          subscription,
          code: sanitizedCoupon,
        },
      )
      if (!response.data.error) onCouponApplied(response.data)
      else setError(errorToLabel(response.data.error))
    } finally {
      setProcessing(false)
    }
  }

  return (
    <div className="w-full mt-0 flex flex-col">
      <div className="w-full flex flex-row justify-center items-center space-x-2">
        <input
          className="form-input w-full h-[40px] p-2 border border-bordered rounded focus:outline-none focus:ring-2 focus:ring-primary"
          type="text"
          placeholder={t`Enter Code`}
          value={coupon}
          disabled={processing}
          onChange={(e) => {
            setCoupon(e.target.value.toUpperCase())
            setError(undefined)
          }}
        />
        <Button
          variant="outlined-primary"
          className={`min-w-[130px] flex flex-row items-center justify-center ${
            processing ? 'opacity-50 cursor-not-allowed' : 'hover:bg-primary_dk'
          }`}
          onClick={applyCoupon}
          disabled={processing}
        >
          {!processing && (
            <span className="whitespace-nowrap">{t`Apply Coupon`}</span>
          )}
          {processing && <Spinner size={30} />}
        </Button>
      </div>
      {error && <p className="text-error animate-shake mt-2">{error}</p>}
    </div>
  )
}
