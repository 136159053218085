import React from 'react';

import { atom, useRecoilState, useSetRecoilState } from "recoil";
import { useTranslation } from '../../utils/localization';
import Button from '../Button';
import Typography from '../Typography';

const messageAtom = atom({
  key: 'message',
  default: undefined,
})

const MessageDialog = (props) => {
  const t = useTranslation()
  const [messageConfig, setMessageConfig] = useRecoilState(messageAtom)
  const {title, message, onConfirm, btnLabel} = messageConfig ?? {}

  if (!messageConfig) return null

  return (
    <div className="fixed inset-0 bg-black bg-opacity-70 flex items-center justify-center z-50">
      <div className="bg-paper rounded-lg shadow-lg w-[340px] sm:w-[400px] p-6">
        <Typography variant="h3" className="mb-4">
          {title}
        </Typography>
        <hr className="border-t border-bordered mb-4" />
        <Typography className="mb-6 text-base text-text_secondary ">
          {message}
        </Typography>
        <div className="flex justify-end space-x-4">
          <Button
            variant="action"
            className="w-1/4 py-2 px-4 rounded-full"
            onClick={() => {
              setMessageConfig(undefined)
              onConfirm && onConfirm()
            }}
          >
            {btnLabel ?? t`Ok`}
          </Button>
        </div>
      </div>
    </div>
  )
}

const MessageDialogProvider = () => {
  return <MessageDialog/>
}

export const useOpenMessageDialog = () => {
  const setter = useSetRecoilState(messageAtom)
  return config => setter(config)
}

export default MessageDialogProvider
