//create a component called Toolbar that will be used to display the toolbar of the PDF viewer. The toolbar will contain the following buttons:
// - Open file button
// - Print button
// - Download button
// - Rotate left button
// - Rotate right button

import React from 'react'
import Button from '../Button'
//Tools
import Zoom from '../FileViewerTools/zoom'
// import Rotate from './tools/rotate'
// import MinimapToggle from './tools/minimap-toggle'
// import Preview from './tools/preview'
import Download from '../FileViewerTools/download'
// import PageNavigator from '../FileViewerTools/page-navigator'
export default ({ controller }) => {
  return (
    <div className="relative top-0 justify-center w-full z-10 flex flex-row h-[60px] space-x-2 bg-sidebar gap-5 px-5">
      {/* <MinimapToggle controller={controller} />
      <PageNavigator controller={controller} />
      <Rotate rotate={controller.setRotate} />*/}
      <Zoom controller={controller} />
      <Download controller={controller} />
      {/* <Preview controller={controller} /> */}
    </div>
  )
}
