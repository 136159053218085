import React, { useEffect, useState } from 'react'
import Button from '../Button'
import Typography from '../Typography'
import { useTranslation } from '../../utils/localization'
import Dropdown from '../Dropdown'
import isMobile from 'is-mobile'
import desktop from './desktop'
import mobile from './mobile'

const PaginationControls = ({
  rowsPerPage,
  onRowsPerPageChange,
  startIndex,
  endIndex,
  totalItems,
  onPageChange,
  page,
  totalPages,
}) => {
  const t = useTranslation()
  const [isMobileDevice, setIsMobileDevice] = useState(false)
  const args = {
    rowsPerPage,
    onRowsPerPageChange,
    startIndex,
    endIndex,
    totalItems,
    onPageChange,
    page,
    totalPages,
  }

  useEffect(() => {
    setIsMobileDevice(isMobile())
  }, [])

  if (isMobileDevice === undefined) return null

  return isMobileDevice ? mobile(args) : desktop(args)
}

export default PaginationControls
