// to run this you must create deployment.js
import * as deployment from './deployment.js'

export const APP_DOMAIN = deployment.APP_DOMAIN // leave it undefined to automatically determine the domain
export const API_URL = deployment.API_URL // leave it undefined to use the app domain
export const ANALYTICS_URL = deployment.ANALYTICS_URL || "https://cc-api-st.aldoo.com"
export const SUPPORT_CHAT_API = deployment.SUPPORT_CHAT_API || 'http://localhost:7031/v1/chat'

//talk with the platform users
export const PLATFORM_API_URL =
  deployment.PLATFORM_API_URL || 'https://pdf-api-st.aldoo.com'
export const PLATFORM_API_VERSION = deployment.PLATFORM_API_VERSION || 'v1'
export const STRIPE_API_KEY =
  deployment.STRIPE_API_KEY ||
  'pk_test_51K9Y5AE2k8zaBmhTXyhlWwzoSOF9aar1YfqKE27h9XuXpQKOEeuuVja20H3mQBewaDjBRw9Pr7d7qJ6jdnyEmzGQ0069k8akt9'

export const FACEBOOK_PIXEL = deployment.FACEBOOK_PIXEL || false
export const FACEBOOK_OPTIONS = deployment.FACEBOOK_OPTIONS
export const TRACKING_GDPR = deployment.TRACKING_GDPR || false

export const GOOGLE_ANALYTICS_ID = deployment.GOOGLE_ANALYTICS_ID || false

export const GTM_ID = deployment.GTM_ID || 'GTM-PD3BNRB'
export const gtmParams = deployment.gtmParams || {
  id: GTM_ID,
}

export const CLOSE_PAYMENT_TIME = 45 * 60 * 1000

export const REVIEWS_PER_LOAD = 10

export const COMMENTS_PER_LOAD = 10

export const SEARCH_THROTTLE_MS = 300

export const BOOK_COVER_ASPECT_RATIO = 450 / 600

export const SCENE_TAIL_TIME = 0.5 // in seconds

export const PLAYER_SAFE_AREA_PERCENT = 5
