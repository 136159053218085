import React, { useState, useEffect, useLayoutEffect, useRef } from 'react'
import Tooltip from '../Tooltip'
import { InformationCircleIcon } from '@heroicons/react/24/outline'
import PaginationControls from '../Pagination'
import Typography from '../Typography'
import { useTranslation } from '../../utils/localization'
import useScrollLock from '../../utils/useScrollLock'
import useDataController from './controller'

const extractValue = (object, path) => {
  const parts = path.split('.')
  let value = object
  for (let i = 0; i < parts.length; i++) {
    value = value[parts[i]]
    if (value === undefined) {
      break
    }
  }
  return value
}

const DataTable = ({
  data,
  apiMethod = '',
  apiDataField = 'data', // The field in the API response that contains the data
  renders,
  title,
  itemHeight = 'h-[60px]',
  noData,
  preferredHeight = '100%',
  searchField,
  refreshKey,
  sort,
}) => {
  const t = useTranslation()
  const [page, setPage] = useState(1)
  const [itemsPerPage, setItemsPerPage] = useState(10)
  const [isScrollLocked, setIsScrollLocked] = useState(false)
  const [totalPages, setTotalPages] = useState(0)
  const [totalItems, setTotalItems] = useState(0)
  const [dataSource, setDataSource] = useState(data)
  const [search, setSearch] = useState()

  const controller = useDataController({
    apiMethod,
    page,
    search,
    sort,
    refreshKey,
    itemsPerPage,
    onData: (payload) => {
      const data = payload[apiDataField]
      const total = payload.total || payload.totalItems || 0
      if (!data) return
      setTotalPages(Math.ceil(total / itemsPerPage))
      setTotalItems(total)
      setDataSource([...data])
    },
  })

  // useScrollLock(isScrollLocked);

  // Calculate the indexes for pagination
  const startIndex = (page - 1) * itemsPerPage
  const endIndex = Math.min(startIndex + itemsPerPage, totalItems || 0)
  const hasData = dataSource && dataSource.length > 0

  // Render the rows for the current page
  const renderItems = () => {
    if (!hasData) return null

    return dataSource.map((item, index) => (
      <tr
        key={index}
        className={`${itemHeight} text-text text-sm font-normal ${
          index % 2 === 0 ? 'bg-paper' : 'bg-paper bg-opacity-75'
        }`}
      >
        {renders.map((object, subIndex) => (
          <td key={subIndex} className={`${object.itemClassName} px-2 py-2`}>
            {object.render(extractValue(item, object.id), item)}
          </td>
        ))}
      </tr>
    ))
  }

  // Handle pagination change
  const handlePageChange = (newPage) => {
    setPage(newPage)
  }

  // Handle rows per page change
  const handleRowsPerPageChange = (value) => {
    setItemsPerPage(parseInt(value))
    setPage(1) // Reset to first page when changing rows per page
  }

  const handleSearch = (value) => {
    setSearch({ [searchField]: value })
  }

  return (
    <div className="flex flex-col h-full">
      {/* Header */}
      <div className="flex flex-row justify-left">
        <Typography variant="h3" className="text-text mb-5">
          {title}
        </Typography>
        {/* add search input here */}
        {searchField && (
          <input
            type="text"
            placeholder={t`Search`}
            className="text-text bg-canvas outline-none ml-10 flex-1 w-[200px] h-[30px] px-2 py-1 border border-neutral_2 rounded"
            onChange={(e) => handleSearch(e.target.value)}
          />
        )}
      </div>
      {hasData ? (
        <>
          {/* Table */}
          <div
            className="h-auto flex-col overflow-y-auto scrollbar scrollbar-thin scrollbar-thumb-neutral_1 scrollbar-track-canvas"
            style={{ height: preferredHeight }}
            onMouseEnter={() => setIsScrollLocked(true)}
            onMouseLeave={() => setIsScrollLocked(false)}
          >
            <table className="w-full table-fixed md:table-auto">
              <thead className="sticky top-0 z-10 bg-paper text-text">
                <tr className="text-left bg-neutral_1 text-text font-normal">
                  {renders.map((item, index) => (
                    // <th
                    //   key={index}
                    //   className={`${item.headerClassName} px-2 py-2`}
                    // >
                    //   {item.title}
                    // </th>
                    <th key={index} className={`${item.headerClassName} px-2 py-2`}>
                      {item.title}
                      {item.title === 'Status' && (
                        <>
                          <span
                            data-tip="This is the status of the item"
                            style={{ marginLeft: '5px', cursor: 'pointer' }}
                          >
                            <Tooltip
                              text={t(`Completed: The order processing was completed.\nCancelled: The user cancelled the order execution.`)}
                            >
                              <InformationCircleIcon className="h-5 w-5 text-neutral_3 inline" />
                            </Tooltip>
                          </span>
                        </>
                      )}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>{renderItems()}</tbody>
            </table>
          </div>

          {/* Footer */}
          <div className="mt-5">
            <PaginationControls
              page={page}
              totalPages={totalPages}
              totalItems={totalItems || 0}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleRowsPerPageChange}
              rowsPerPage={itemsPerPage}
              startIndex={startIndex}
              endIndex={endIndex}
            />
          </div>
        </>
      ) : (
        <div className="flex items-center justify-center w-full h-full">
          {noData ? (
            noData
          ) : (
            <Typography variant="h3" className="text-text">
              {t`No data available`}
            </Typography>
          )}
        </div>
      )}
    </div>
  )
}

export default DataTable
