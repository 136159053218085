import { ChevronLeftIcon } from '@heroicons/react/24/solid'
import { useState } from 'react'
import { useTranslation } from '../../../utils/localization.js'
import { Coupon } from '../../Coupon/index.jsx'
import { Link } from '../../Link/index.jsx'
import PaymentController from '../../PaymentController/index.jsx'
import Typography from '../../Typography/index.jsx'
import ProductCard from './ProductCard.jsx'

export default function PaymentDesktop({ pid, onCancel }) {
  const t = useTranslation()
  const [discountedPrice, setDiscountedPrice] = useState()
  //get the Checkout component and meaningful purchase data
  const { paymentInfo, productData, currency, Checkout } = PaymentController({
    pid,
    type: 'credits',
  })

  return (
    <div className="w-full max-w-[100%] h-full pt-6 mx-auto">
      {/* Back Button */}
      <button onClick={onCancel} className="absolute top-4 left-4">
        <ChevronLeftIcon className="w-7 h-7 text-text" />
      </button>
      <div className="flex flex-row items-start justify-between w-full gap-8">
        {/* Left column */}
        <div className="w-full max-w-[550px] h-[380px] flex flex-col justify-between gap-5">
          <Typography className="title text-4xl lg:text-5xl xl:text-6xl text-text font-bold text-left mt-5">
            <>
              {t`Get Your`}
              <br></br>
              <span className="text-link">{t`Credit Pack`}</span>
            </>
          </Typography>

          {productData && productData.length > 0 && (
            <ProductCard
              amount={paymentInfo?.cost}
              discountedAmount={discountedPrice}
              currency={currency}
              product={productData[0]}
              className="md:mt-5"
            />
          )}
          <Coupon
            payment={paymentInfo?.payment}
            onCouponApplied={({ discountedAmount }) =>
              setDiscountedPrice(discountedAmount)
            }
          />
          <div className="sm:hidden w-full border-b border-bordered"></div>
        </div>

        {/* Right column */}
        <div className="max-w-[550px] w-full flex flex-col items-start">
          {/* The product checkout */}
          <Checkout />
        </div>
      </div>
      {/* Privacy Policy notice */}
      <div className="flex w-full h-full items-center justify-center mt-8">
        <Typography className="text-neutral_2 text-sm text-center">
          {t`For more info of how we process your personal data, please read our`}
          <Link
            to="/privacy-policy"
            className="text-link hover:text-primary_lt"
          >
            {' ' + t`Privacy policy`}
          </Link>
          .
        </Typography>
      </div>
    </div>
  )
}
