// selectors.js
import { selector, useRecoilValue } from 'recoil'
import { supportedTranslationLanguagesAtom } from '../atoms/languages'

const languageCodeToNameSelector = selector({
  key: 'languageCodeToNameSelector1',
  get: ({ get }) => {
    const supportedLanguages = get(supportedTranslationLanguagesAtom)
    // supportedLanguages.languages is an array of objects with code and langauageName, build a LUT
    //using map
    const languageMap = supportedLanguages.languages?.reduce(
      (acc, { languageCode, displayName }) => {
        acc[languageCode] = displayName
        return acc
      },
      {},
    )
    return languageMap
  },
})

export const useResolveLanguageName = () => {
  const languageMap = useRecoilValue(languageCodeToNameSelector)
  return (code) => (languageMap ? languageMap[code] : code)
}
