import React, { useEffect, useState } from 'react'
import Button from '../Button'
import Typography from '../Typography'
import { useTranslation } from '../../utils/localization'
import Dropdown from '../Dropdown'
import { ChevronLeftIcon } from '@heroicons/react/24/solid'
import { ChevronRightIcon } from '@heroicons/react/16/solid'

const PaginationControlsMobile = ({
  rowsPerPage,
  onRowsPerPageChange,
  startIndex,
  endIndex,
  totalItems,
  onPageChange,
  page,
  totalPages,
}) => {
  const t = useTranslation()

  return (
    <div className="flex flex-col items-center mt-0">
      <div className="flex flex-row gap-12 justify-between items-center">
        <div className="flex flex-row w-[210px] items-center">
          <Typography className="flex-2 text-text">{t`Items per page:`}</Typography>
          <Dropdown
            menuPosition="top"
            className={'flex-1 mx-2'}
            value={rowsPerPage}
            options={[10, 20, 50]}
            onChange={onRowsPerPageChange}
          />
        </div>

        <div className="flex flex-row gap-2">
          {/* Previous page button */}
          <Button
            onClick={() => onPageChange(Math.max(page - 1, 1))}
            disabled={page === 1}
            variant="viewer_controls"
            className="mr-3 font-medium"
          >
            <ChevronLeftIcon className="w-5 h-5" />
          </Button>

          {/* Next page button */}
          <Button
            onClick={() => onPageChange(Math.min(page + 1, totalPages))}
            disabled={page === totalPages}
            variant="viewer_controls"
            className="font-medium"
          >
            <ChevronRightIcon className="w-5 h-5" />
          </Button>
        </div>
      </div>
      <Typography className="text-text mt-4">
        {startIndex + 1}–{endIndex} {t`of`} {totalItems}
      </Typography>
    </div>
  )
}

export default PaginationControlsMobile
