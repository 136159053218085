import React, { useEffect, useState } from 'react'
import isMobile from 'is-mobile'
import FooterNormal from './footerNormal'
import FooterAccordion from './footerAccordion'

export default function Footer() {
  const [isMobileDevice, setIsMobileDevice] = useState(undefined)

  useEffect(() => {
    setIsMobileDevice(isMobile())
  }, [])

  if (isMobileDevice === undefined) return null

  return <>{isMobileDevice ? <FooterAccordion /> : <FooterNormal />}</>
}
