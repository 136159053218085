import { usePageContext } from "vike-react/usePageContext";
import { SEARCH_QUERY_FLAGS } from "./routing.js";

export const updateQueryParams = (params) => {
  const url = new URL(window.location)
  Object.keys(params).forEach((key) => {
    if (params[key] !== null) {
      url.searchParams.set(key, params[key])
    } else {
      url.searchParams.delete(key)
    }
  })
  window.history.replaceState({}, '', url)

  return url.pathname + url.search
}

export function useSearchParamValue(key, defaultValue) {
  const context = usePageContext()
  return context.urlParsed.search[key] ?? defaultValue
}

export function searchParamsToString(searchParams) {
  const withValue = Object.keys(searchParams)
    .filter(key => searchParams[key] !== undefined)
  if (withValue.length === 0)
    return ""
  return '?' + withValue
    .map(key => searchParams[key] === "" ? `${key}` : `${key}=${encodeURIComponent(searchParams[key])}`)
    .join('&')

}

export function useSearchParamMutation() {
  const context = usePageContext()
  return {
    add(key, value = "") {
      return context.urlPathname + searchParamsToString({ ...context.urlParsed.search, [key]: value })
    },
    remove(...key) {
      return context.urlPathname + searchParamsToString({ ...context.urlParsed.search, ...key.reduce((acc, key) => ({ ...acc, [key]: undefined }), {}) })
      // return context.urlPathname + searchParamsToString({...context.urlParsed.search, [key]: undefined})
    },
    replace(remove, key, value = "") {
      return context.urlPathname + searchParamsToString({ ...context.urlParsed.search, [remove]: undefined, [key]: value })
    },
    replaceAndSetReturnTo(key, value = "") {
      return context.urlPathname + searchParamsToString({ [key]: value, [SEARCH_QUERY_FLAGS.RETURN_TO]: context.urlOriginal })
    }

  }
}
