import React, { useEffect, useState } from 'react'
import Typography from '../Typography'
import { useTranslation } from '../../utils/localization'
import Button from '../Button'
import { useAuth } from '../../utils/platform_auth'

const SocialLinksPanel = ({ className }) => {
  const t = useTranslation()
  const auth = useAuth()
  //list of connected services
  const [connectedServices, setConnectedServices] = useState([])

  useEffect(() => {
    //the profile is not loaded yet
    if (!auth || !auth._id) return

    if (auth.google_id) {
      setConnectedServices((prev) => [...prev, 'Google'])
    }
    if (auth.facebook_id) {
      setConnectedServices((prev) => [...prev, 'Facebook'])
    }
    if (auth.apple_id) {
      setConnectedServices((prev) => [...prev, 'Apple'])
    }
  }, [auth])

  const connectedServicesList =
    connectedServices.length == 0 ? (
      <label className="text-theme-lightgray">{t`No accounts connected`}</label>
    ) : (
      connectedServices.map((service, index) => (
        <li key={index} className="text-theme-lightgray">
          {service}
        </li>
      ))
    )

  return (
    <div className={className}>
      <div className="flex flex-col min-h-[260px] text-text p-4 bg-paper rounded shadow">
        {/* List of connected services */}
        <label className="text-text text-lg font-bold">
          {t`Connected Accounts`}
        </label>
        {/* Divider */}
        <div className="border-b border-bordered mb-4 mt-3"></div>
        <ul className="text-base text-text_secondary">
          {connectedServicesList}
        </ul>

        {/* <label className="text-text text-lg font-bold mb-4">
          {t`Connect With:`}
        </label> */}

        {/* Connect buttons for each network */}
        {false && (
          <div className="flex flex-row gap-4 text-sm">
            {/* <Button
            variant="social_connect"
            className="bg-[#3f4f93] hover:bg-[#415dd9] flex-1"
          >
            {t`Facebook`}
          </Button> */}
            <Button
              variant="social_connect"
              className="bg-[#d14631] hover:bg-[#eb442a] flex-1"
            >
              {t`Google`}
            </Button>
            <Button
              variant="social_connect"
              className="bg-[#000] hover:bg-[#222] flex-1"
            >
              {t`Apple`}
            </Button>
          </div>
        )}
      </div>
    </div>
  )
}

export default SocialLinksPanel
