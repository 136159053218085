import {
  useCurrencyTranslation,
  useTranslation,
} from '../../../utils/localization.js'
import React, { useEffect, useRef, useState } from 'react'
import { currencyAtom, useAuth } from '../../../utils/platform_auth.jsx'
import { useRecoilValue } from 'recoil'
import { usePromise } from '../../../utils/usePromise.js'
import PTRestAPI from '../../../utils/pt_rest_api.jsx'
import { Spinner } from '../../Spinner/index.jsx'
import Typography from '../../Typography/index.jsx'
import SubscriptionCard from './subscriptionCard.jsx'

const formatDiscount = (discount) => {
  //normalize discount to percentage
  discount = Math.ceil(discount * 100)
  return discount ? `-${discount}%` : ''
}

export default function SelectPlan({ onSelected }) {
  const t = useTranslation()
  const [selectedPlanId, setSelectedPlanId] = useState(false)
  //force render after clicking on the radio buttons
  //this was introduced so the UI changes on first click
  const [forceRender, setForceRender] = useState(false)
  const auth = useAuth()
  const currency = useRecoilValue(currencyAtom)
  const { localizedCost } = useCurrencyTranslation()

  let { result: subscriptions } = usePromise(
    () =>
      PTRestAPI.GetSubscriptions(
        auth,
        {
          fields: [
            'name',
            'type',
            'pid',
            'description',
            'amount',
            'real_price',
            'subscriptions',
          ],
        },
        currency,
      ),
    false,
    false,
  )

  //Get plan discount percentages
  const { result: discounts } = usePromise(
    () =>
      PTRestAPI.RunDiscountQuery(auth, {
        lut: 'products.translation.account_type',
      }),
    false,
    false,
  )

  const selectedPlan =
    subscriptions?.find((s) => s.pid === selectedPlanId) ?? subscriptions?.[0]

  useEffect(() => {
    setForceRender(!forceRender)
  }, [selectedPlanId])

  if (!subscriptions || !discounts) return <Spinner />
  //attach discounts to subscriptions
  for (const sub of subscriptions) {
    const discount = discounts[sub.pid]
    sub.discount = discount
  }

  //filter the premium-weekly, premium-monthly, and premium-annual plans only
  subscriptions = subscriptions.filter((sub) => {
    return (
      sub.pid === 'premium-weekly' ||
      sub.pid === 'premium-monthly' ||
      sub.pid === 'premium-annual'
    )
  })


  return (
    <div>
      <Typography
        variant="h3"
        className="text-center w-full text-lg font-bold text-text_primary"
      >
        {t`Select your subscription plan`}
      </Typography>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-5 pt-3 lg:pt-6 overflow-x-hidden">
        {subscriptions.map((sub, index) => {
          return (
            sub.price && (
              <SubscriptionCard
                discount={formatDiscount(sub.discount)}
                key={index}
                title={sub.name}
                price={localizedCost(sub.price.amount, currency, 100)}
                features={sub.description}
                isBestValue={sub.subscriptions[0].duration === 365}
                onSelected={() => onSelected(sub)}
              />
            )
          )
        })}
      </div>

      <div className="flex width-full h-[50px] items-center justify-center">
        <Typography className="text-sm text-center font-normal text-text_secondary px-5 pt-[20px] mx-auto">
          {t`You can cancel your subscription at any time to avoid being charged in the future.`}
        </Typography>
      </div>
    </div>
  )
}
