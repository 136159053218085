import React, { useState, useRef, useEffect, useCallback } from 'react';
import ReactDOM from 'react-dom';

const Dropdown = ({
  className = '',
  options,
  value,
  onChange,
  menuPosition = 'bottom',
  onItemRender,
  markSelected = true,
}) => {
  const [selectedOption, setSelectedOption] = useState(value || options[0]);
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  const buttonRef = useRef(null);
  const [menuStyles, setMenuStyles] = useState({});

  useEffect(() => {
    if (value) {
      setSelectedOption(value);
    }
  }, [value]);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
    if (onChange) {
      onChange(option);
    }
  };

  const getMenuPositionStyles = useCallback(() => {
    if (!buttonRef.current) return {};
    const buttonRect = buttonRef.current.getBoundingClientRect();
    const scrollX = window.scrollX || window.pageXOffset;
    const scrollY = window.scrollY || window.pageYOffset;
    const styles = {
      width: `${buttonRect.width}px`,
    };

    switch (menuPosition) {
      case 'left':
        return {
          ...styles,
          top: `${buttonRect.top + scrollY}px`,
          left: `${buttonRect.left + scrollX - buttonRect.width}px`,
        };
      case 'right':
        return {
          ...styles,
          top: `${buttonRect.top + scrollY}px`,
          left: `${buttonRect.right + scrollX}px`,
        };
      case 'top':
        return {
          ...styles,
          top: `${buttonRect.top + scrollY - buttonRect.height * options.length}px`,
          left: `${buttonRect.left + scrollX}px`,
        };
      case 'bottom':
      default:
        return {
          ...styles,
          top: `${buttonRect.bottom + scrollY}px`,
          left: `${buttonRect.left + scrollX}px`,
        };
    }
  }, [menuPosition, options.length]);

  // Recalculate the position whenever the dropdown is opened
  useEffect(() => {
    if (isOpen) {
      setMenuStyles(getMenuPositionStyles());
    }
  }, [isOpen, getMenuPositionStyles]);

  // Close the dropdown if clicked outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        !buttonRef.current.contains(event.target)
      ) {
        setIsOpen(false);
      }
    };

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);

  const dropdownPanel = (
    <div
      ref={dropdownRef}
      className="border border-bordered origin-top-right absolute rounded-md shadow-lg bg-paper ring-1 ring-black ring-opacity-5 focus:outline-none"
      role="menu"
      aria-orientation="vertical"
      aria-labelledby="options-menu"
      style={{ ...menuStyles, zIndex: 1100 }}
    >
      <div className="rounded-lg overflow-hidden">
        {options.map((option) => (
          <div
            key={option}
            className={`block px-4 py-2 text-sm cursor-pointer ${
              option === selectedOption
                ? 'bg-paper text-text'
                : 'bg-neutral text-text_secondary'
            } hover:bg-neutral_1 hover:text-text flex justify-between items-center`}
            role="menuitem"
            onClick={() => handleOptionClick(option)}
          >
            {onItemRender ? onItemRender(option) : option}
            {option === selectedOption && markSelected && (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                className="ml-2 h-5 w-5 text-primary"
                fill="currentColor"
                width="24px"
                height="24px"
              >
                <path d="M0 0h24v24H0z" fill="none" />
                <path d="M9 16.2l-3.5-3.5L4 14l5 5L20 8.5l-1.5-1.5z" />
              </svg>
            )}
          </div>
        ))}
      </div>
    </div>
  );

  return (
    <div className={'relative inline-block text-left w-full ' + className}>
      <div>
        <button
          ref={buttonRef}
          type="button"
          className="inline-flex justify-between w-full rounded-md border border-bordered shadow-sm px-4 py-2 bg-paper text-sm font-medium text-text focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary_lt"
          id="options-menu"
          aria-expanded="true"
          aria-haspopup="true"
          onClick={toggleDropdown}
        >
          {onItemRender ? onItemRender(selectedOption) : selectedOption}
          <svg
            className="-mr-1 ml-2 h-5 w-5"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              fillRule="evenodd"
              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 011.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
              clipRule="evenodd"
            />
          </svg>
        </button>
      </div>

      {isOpen && ReactDOM.createPortal(dropdownPanel, document.body)}
    </div>
  );
};

export default Dropdown;
