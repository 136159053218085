import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';

const ErrorBubble = ({ message }) => {
  return (
    <div className="flex w-full items-center space-x-2 p-4 bg-orange-100 text-orange-800 rounded-lg shadow-md">
      {/* Warning Icon */}
      <div className="flex-shrink-0">
        <div className="bg-orange-200 p-2 rounded-full">
          <ExclamationTriangleIcon className="w-6 h-6 text-black" />
        </div>
      </div>

      {/* Error Message */}
      <div className="flex-1 text-sm">
        {message.message}
      </div>
    </div>
  );
};

export default ErrorBubble;
