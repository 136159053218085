import { useTranslation } from '../../utils/localization'
import Modal from '../Modal/index.jsx'
import TextField from '../TextField/index.jsx'
import Button from '../Button/index.jsx'
import { useEffect, useRef, useState } from 'react'
import Typography from '../Typography/index.jsx'

export default function ({
  isOpen,
  documentName,
  passwordIncorrect,
  onPasswordSubmit,
  onCloseRequested,
}) {
  const [textChanged, setTextChanged] = useState(false)
  const t = useTranslation()
  const inputRef = useRef()

  useEffect(() => {
    setTextChanged(true)
    inputRef.current.value = ''
  }, [isOpen])
  return (
    <Modal
      isOpen={isOpen}
      title={t`This file is password protected`}
      onCloseRequested={onCloseRequested}
      className="relative text-ellipsis w-full h-full sm:h-auto sm:max-w-[500px] overflow-y-hidden"
    >
      <TextField
        isFocused={true}
        onChange={() => setTextChanged(true)}
        inputRef={inputRef}
        autoComplete="off"
        label={t('Please enter the password for %documentName%', documentName)}
        type="password"
      />
      {passwordIncorrect && !textChanged && (
        <Typography className="text-error">{t`The password is incorrect`}</Typography>
      )}
      <div className="flex justify-end">
        <Button
          variant="action"
          onClick={() => {
            onPasswordSubmit(inputRef.current.value)
            setTextChanged(false)
            inputRef.current.select()
          }}
          className="rounded-full w-[120px] mt-[20px]"
        >
          {t`Submit`}
        </Button>
      </div>
    </Modal>
  )
}
