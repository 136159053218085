import React from 'react'
import Button from '../Button'
import { useTranslation } from '../../utils/localization'
import { useRecoilValueLoadable } from 'recoil'
import {subscriptionSelector} from '../../utils/platform_auth'
import {navigate} from "vike/client/router";
import {useSearchParamMutation} from "../../utils/searchParams.js";
import {SEARCH_QUERY_FLAGS} from "../../utils/routing.js";

const PricingButton = () => {
  const t = useTranslation()
  const {add: addSearchParam, remove: removeSearchParam} = useSearchParamMutation()
  const {contents: hasSubscription, state} = useRecoilValueLoadable(subscriptionSelector)

  return (
    <div>
      <Button
        onClick={() => {
          navigate(addSearchParam(!hasSubscription ? SEARCH_QUERY_FLAGS.PACKAGES : SEARCH_QUERY_FLAGS.PACKAGES))
        }}
        className="flex h-[64px] items-center justify-center hover:bg-canvas text-text py-2 px-4 rounded transition duration-200 ease-in-out"
      >{t`Pricing`}</Button>
    </div>
  )
}

export default PricingButton
