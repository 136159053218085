import { CheckIcon } from '@heroicons/react/24/outline'
import { ChevronLeftIcon } from '@heroicons/react/24/solid'
import {
  useTranslation,
  useCurrencyTranslation,
} from '../../../utils/localization.js'
import { Coupon } from '../../Coupon/index.jsx'
import { Link } from '../../Link/index.jsx'
import Typography from '../../Typography/index.jsx'
import PaymentController from '../../PaymentController/index.jsx'
import { useState } from 'react'

//TODO: Add the real bullets (current code is only for representation purposes)
export function Bullet({ text }) {
  return (
    <div className="flex items-start justify-start items-center mb-3">
      <CheckIcon className="mr-2 pb-1 w-6 text-text_secondary" />
      <Typography className="text-base text-text">{text}</Typography>
    </div>
  )
}

export default function PaymentMobile({ pid, onCancel }) {
  const t = useTranslation()
  const { localizedCost } = useCurrencyTranslation()
  const [originalPrice, setOriginalPrice] = useState()

  //get the Checkout component and meaningful purchase data
  const {
    paymentInfo,
    setPaymentInfo,
    productData,
    currency,
    Checkout,
  } = PaymentController({
    pid,
    type: 'subscription',
  })

  //----------------------------------------------------------
  //Produce Details
  //----------------------------------------------------------
  const product = productData && productData[0]
  const titleText = t`Activate Your Plan`

  return (
    <div className="w-full max-w-[400px] sm:max-w-[100%] h-full pt-8 mx-auto">
      {/* -----Top section----- */}
      <div className="bg-canvas w-full h-[60px] absolute top-0 left-0 pt-[8px] flex flex-col justify-between border-b border-bordered">
        {/* -----Back btn----- */}
        <div onClick={onCancel} className="z-50 ml-3 mt-2 w-40 h-40">
          <ChevronLeftIcon className="w-7 h-7 text-text" />
        </div>
      </div>
      {/* -----Dialog title----- */}
      <Typography className="absolute w-full top-4 left-0 font-title text-[20px] sm:text-[24px] text-text font-bold text-center">
        {titleText}
      </Typography>

      <div className="flex flex-col items-center justify-between w-full mx-auto">
        {/* ----- Upper section -----*/}
        <div className="w-full max-w-[550px] flex flex-col justify-center items-center gap-2 sm:gap-2 pt-6">
          {paymentInfo && (
            <div className="flex flex-col w-full">
              <div className="flex flex-row items-center justify-between w-full">
                <Typography className="text-2xl text-text m-0">
                  {product?.name}
                </Typography>
                <div>
                  <Typography className="text-2xl text-text m-0">
                    {localizedCost(paymentInfo?.cost, currency, 100)}
                  </Typography>

                  {originalPrice && (
                    <Typography className="text-lg text-text line-through m-0">
                      {localizedCost(originalPrice, currency, 100)}
                    </Typography>
                  )}
                </div>
              </div>
            </div>
          )}
          {/* -----Divider----- */}
          <div className="w-full sm:w-90 my-6 border-t border-bordered" />
          {/* -----Bullets----- */}
          <div className="pl-2 lg:pl-4">
            {product?.description?.split('\n')?.map((text, i) => (
              <Bullet key={i} text={text} />
            ))}
          </div>
          <Coupon
            subscription={paymentInfo?.subscription}
            onCouponApplied={(updatedPaymentInfo) => {
              //store the original price
              setOriginalPrice(paymentInfo.cost)
              //set the updated payment info
              setPaymentInfo(updatedPaymentInfo)
            }}
          />
          {/* -----Divider----- */}
          <div className="w-full border-b border-bordered mt-2" />
        </div>

        {/* ----- Payment section -----*/}
        <div className="max-w-[550px] w-full flex flex-col items-center pt-6">
          <Checkout />
        </div>
      </div>
      {/* Privacy Policy notice */}
      <div className="flex items-center justify-center w-full h-full py-4">
        <Typography className="text-neutral_2 text-sm text-center">
          {t`For more info of how we process your personal data, please read our`}
          <Link
            to="/privacy-policy"
            className="text-link hover:text-primary_lt"
          >
            {' ' + t`Privacy policy`}
          </Link>
          .
        </Typography>
      </div>
    </div>
  )
}
