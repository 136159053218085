import Button from '../Button'
import { useState, useEffect } from 'react'
import { useTranslation } from '../../utils/localization'
import isMobile from 'is-mobile'
export default ({ controller }) => {
  const t = useTranslation()
  const { standalone } = controller.params
  const [isMobileDevice, setIsMobileDevice] = useState(undefined)

  useEffect(() => {
    setIsMobileDevice(isMobile())
  }, [])

  if (isMobileDevice === undefined) return null

  return (
    controller.params.closeAction && (
      <div className={`flex items-center space-x-2`}>
        <Button variant="action" onClick={controller.params.closeAction}>
          {t`Close`}
        </Button>
      </div>
    )
  )
}
