import React, { useRef, useEffect, useState } from 'react'
import MessageBubble from './message-bubble'

export default function ChatArea({ selectedUser, messages, isChatVisible }) {
  const chatContainerRef = useRef(null)
  const [allowChatAutoScroll, setAllowChatAutoScroll] = useState(true)
  // Scroll to the bottom when messages are added
  useEffect(() => {
    if (chatContainerRef.current && allowChatAutoScroll) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight
    }
  }, [messages, allowChatAutoScroll])

  useEffect(() => {
    if (isChatVisible) {
      // Reset the unread messages count when the chat is visible
      if (selectedUser) selectedUser.unreadMessages = 0
      if (!allowChatAutoScroll || !chatContainerRef.current) return
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight
    }
  }, [messages, isChatVisible])

  useEffect(() => {
    //listen for use scroll events in the chat area
    const handleScroll = () => {
      if (chatContainerRef.current) {
        const {
          scrollTop,
          scrollHeight,
          clientHeight,
        } = chatContainerRef.current

        setAllowChatAutoScroll(
          Math.abs(scrollTop + clientHeight - scrollHeight) <= 1,
        )
      }
    }
    chatContainerRef.current?.addEventListener('scroll', handleScroll)
    return () => {
      chatContainerRef.current?.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return (
    <div
      ref={chatContainerRef}
      className="p-4 space-y-3 h-[40vh] max-h-[600px] overflow-y-auto"
    >
      {/* Welcome intro */}
      <div className="text-center text-gray-500">
        {selectedUser ? (
          <>
            <h1 className="text-lg font-bold">Welcome to Aldoo Support</h1>
            <p className="text-sm">
              Ask us anything and we'll get back to you as soon as possible.
              Open this chat anytime to continue the conversation.
            </p>
            <div className="text-sm">
              You are chatting with
              <br />
              <img
                src={selectedUser.avatar}
                alt="Support"
                className="mt-5 w-20 h-20 rounded-full inline-block ml-2"
              />
              <p className="font-bold">{selectedUser.name}</p>
            </div>
          </>
        ) : (
          <h1 className="text-lg font-bold">No users available</h1>
        )}
      </div>

      {/* Messages */}
      {messages.map((message) => (
        <MessageBubble
          key={message._id}
          message={message}
          isSupport={message.from === selectedUser._id}
          avatar={selectedUser?.avatar}
        />
      ))}
    </div>
  )
}
