import { useEffect, useRef } from 'react'

const localTabId = Math.random().toString(36).substr(2, 9) // Generate a random tab ID

export default function EnsureStableLogin() {
  const inMemoryID = useRef(null)

  useEffect(() => {
    //keep the same tab active when the user interacts with it
    const activeTab = sessionStorage.getItem('activeTab') || localTabId
    if (!sessionStorage.getItem('activeTab')) {
      sessionStorage.setItem('activeTab', activeTab)
    }

    const markTabAsActive = () => {
      localStorage.setItem('activeTab', activeTab)
    }

    // Mark the tab as active when the component mounts
    // only if there is no active tab in the session storage
    if (!sessionStorage.getItem('activeTab')) {
      markTabAsActive()
    }

    // Mark the tab as active whenever the user interacts with it
    window.addEventListener('focus', markTabAsActive)

    return () => {
      window.removeEventListener('focus', markTabAsActive)
    }
  }, [])

  useEffect(() => {
    //get the local storage credentials
    const auth = JSON.parse(localStorage.getItem('pt-dck'))
    inMemoryID.current = auth?._id

    const checker = setInterval(() => {
      //get the local storage credentials
      const auth = JSON.parse(localStorage.getItem('pt-dck'))
      const activeTab = localStorage.getItem('activeTab')
      const localTab = sessionStorage.getItem('activeTab')

      //the user is performing the action on the current tab
      //so no need to check
      if (activeTab === localTab) return
      //the user has logged out or logged in with a different account
      if (inMemoryID.current && inMemoryID.current != auth?._id) {
        window.location.reload()
      }

      //the user has logged in
      if (!inMemoryID.current && auth?._id) window.location.reload()
    }, 1000)

    return () => clearInterval(checker)
  }, [])
}
