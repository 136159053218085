import React, { useEffect, useState } from 'react'
import Button from '../Button'
import {
  ArrowUturnLeftIcon,
  ArrowUturnRightIcon,
} from '@heroicons/react/24/solid'
import Tooltip from '../Tooltip'
import { useTranslation } from '../../utils/localization'

const Rotate = ({ rotate }) => {
  const [rotationAngle, setRotationAngle] = useState(0)
  const rotationOptions = [0, 90, 180, 270]
  const t = useTranslation()

  useEffect(() => {
    rotate(rotationAngle)
  }, [rotationAngle])

  const handleRotateLeft = () => {
    const currentIndex = rotationOptions.indexOf(rotationAngle)
    const newIndex =
      (currentIndex - 1 + rotationOptions.length) % rotationOptions.length
    setRotationAngle(rotationOptions[newIndex])
  }

  const handleRotateRight = () => {
    const currentIndex = rotationOptions.indexOf(rotationAngle)
    const newIndex = (currentIndex + 1) % rotationOptions.length
    setRotationAngle(rotationOptions[newIndex])
  }

  return (
    <div className="flex items-center space-x-2">
      <Tooltip text={t(`Rotate left`)}>
        <Button variant="viewer_controls_small" onClick={handleRotateLeft}>
          <ArrowUturnLeftIcon className="h-4 w-4" />
        </Button>
      </Tooltip>
      <Tooltip text={t(`Rotate right`)}>
        <Button variant="viewer_controls_small" onClick={handleRotateRight}>
          <ArrowUturnRightIcon className="h-4 w-4" />
        </Button>
      </Tooltip>
    </div>
  )
}

export default Rotate
