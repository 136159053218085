import React, { useRef, useEffect } from 'react'
import Button from '../Button'
import { motion } from 'framer-motion'
import { XMarkIcon } from '@heroicons/react/24/solid'
import Typography from '../Typography'
import { useTranslation } from '../../utils/localization'
import OnKey from '../OnKey'

const Modal = ({
  isOpen,
  onCloseRequested,
  onCloseBtnClick,
  title,
  children,
  closeOnClickOutside = false,
  allowCloseBtn = true,
  className = '',
  zIndex = 200,
  closeOnEscape = false,
  headerContent = null,
}) => {
  const modalRef = useRef()

  OnKey('Escape', () => {
    if (closeOnEscape) onCloseRequested()
  })

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        closeOnClickOutside &&
        modalRef.current &&
        !modalRef.current.contains(event.target)
      ) {
        onCloseRequested()
      }
    }

    if (isOpen) document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [isOpen, onCloseRequested, closeOnClickOutside])

  return (
    <div
      className={`fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-70`}
      style={{
        zIndex,
        display: isOpen ? 'flex' : 'none',
      }}
    >
      <motion.div
        ref={modalRef}
        className={
          'w-full md:w-[900px] max-h-full md:max-h-[calc(100vh-2rem)] overflow-y-auto bg-paper md:rounded-lg border border-bordered shadow-lg overflow-hidden ' +
          (className ?? '')
        }
        initial={{ scale: 0.5 }}
        animate={{ scale: 1 }}
        exit={{ scale: 0.5 }}
      >
        <div className="relative flex justify-between items-center p-4">
          {allowCloseBtn && (
            <button
              className="absolute right-4 top-4 hover:text-white z-10"
              onClick={() => {
                onCloseBtnClick && onCloseBtnClick()
                onCloseRequested()
              }}
            >
              <XMarkIcon className="h-7 w-7 text-text_secondary hover:text-text" />
            </button>
          )}
          <Typography
            variant="h4"
            className="flex-1 text-lg font-bold text-text mt-[2px]"
          >
            {title}
          </Typography>
          {headerContent && (
            <div className="flex items-center">{headerContent}</div>
          )}
        </div>
        <div
          // className="md:max-h-[calc(100vh-225px)] overflow-y-auto scrollbar-thin scrollbar-thumb-neutral_1 scrollbar-track-canvas"
          className="overflow-y-auto scrollbar scrollbar-thin scrollbar-thumb-neutral_1 scrollbar-track-paper"
        >
          <div className="px-4 pb-4 sm:px-5 sm:pb-5">{children}</div>
        </div>
      </motion.div>
    </div>
  )
}

export default Modal
