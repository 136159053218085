import { useState, useEffect, useRef } from 'react';
import axios from 'axios';

export default function AldooAppMessageClient() {
  const [uuid, setUuid] = useState(null);
  const timer = useRef(null);
  const listenersRef = useRef(new Map());
  const [reconnect, setReconnect] = useState(false);
  const [connecting, setConnecting] = useState(false);
  const localUserRef = useRef(null);
  const apiRef = useRef(null);
  const state = useRef({
    isConnected: false
  });

  const POLLING_INTERVAL = 2000;

  const connect = async ({ localUser, api }) => {
    if (!localUser || !api) return;
    apiRef.current = api;
    localUserRef.current = localUser
  };

  // Process messages received from the server and dispatch them to appropriate listeners
  const processMessages = (messages) => {
    messages.sort((a, b) => a.time - b.time);
    messages.forEach(({ data }) => {
      const { event, data: eventData } = data;
      const listeners = listenersRef.current;

      if (listeners instanceof Map && listeners.has(event)) {
        const eventListeners = listeners.get(event);
        eventListeners.forEach((callback) => {
          callback(eventData);
        });
      }
    });
  };

  // Add an event listener for the specified event
  const on = ({ event, action }) => {
    const listeners = listenersRef.current;
    if (!listeners.has(event)) {
      listeners.set(event, []);
    }
    listeners.get(event).push(action);
  };

  const off = ({ event, action }) => {
    const listeners = listenersRef.current;

    if (listeners.has(event)) {
      if (!action) {
        // No specific action provided, remove all listeners for this event
        listeners.delete(event);
      } else {
        // Remove only the specific action (callback)
        const updatedListeners = listeners
          .get(event)
          .filter((cb) => cb !== action);

        if (updatedListeners.length > 0) {
          listeners.set(event, updatedListeners);
        } else {
          listeners.delete(event);
        }
      }
    }
  };

  // Emit an event to the server
  const emit = async ({ to, event, data }) => {
    const deviceID = localUserRef.current.deviceID;
    const email = localUserRef.current.email;

    try {
      const res = await axios.post(apiRef.current, {
        deviceID,
        emitAppMessage: {
          uuid,
          event,
          to,
          data,
          deviceID,
          email
        }
      });

      if (res.data.error) {
        console.log(res.data.error);
      }
    } catch (error) {
      console.error('Emit failed:', error);
    }
  };

  // Start polling the server for new messages
  const startPolling = () => {
    const deviceID = localUserRef.current.deviceID;
    const email = localUserRef.current.email;
    const owner = localUserRef.current._id;

    const pollMessages = async () => {
      try {
        const res = await axios.post(apiRef.current, {
          deviceID,
          pollAppMessages: {
            owner,
            uuid,
            deviceID,
            email
          }
        });

        if (!res.data.error) {
          processMessages(res.data);
        } else {
          console.log(res.data.error);
        }

        // Schedule the next poll after a delay
        timer.current = setTimeout(pollMessages, POLLING_INTERVAL);
      } catch (error) {
        stopPolling();
        // Reconnect on error
        setReconnect(true);
      }
    };

    // Start the first poll
    pollMessages();
  };

  const stopPolling = () => {
    if (!timer.current) return;
    clearInterval(timer.current);
  };

  // Start the heartbeat mechanism
  const startHeartbeat = () => {
    stopHeartbeat(); // Stop any existing heartbeat
    on({
      event: 'heartbeat_out',
      action: (data) => {
        const { timestamp } = data;
        emit({
          to: localUserRef.current._id,
          event: 'heartbeat_in',
          data: { timestamp }
        });
      }
    });
  };

  const stopHeartbeat = () => {
    off({ event: 'heartbeat_out' });
  };

  const close = () => {
    stopPolling();
    stopHeartbeat();
    if (listenersRef.current) listenersRef.current.clear();
  };

  const start = () => {
    startPolling();
    startHeartbeat();
  };

  useEffect(() => {
    if (!uuid) return;
    start();
  }, [uuid]);

  useEffect(() => {
    if (!localUserRef.current) return;
    if (reconnect && !connecting) {
      //reset uuid
      setUuid(null);
      doConnect();
    }
  }, [localUserRef.current, reconnect, connecting]);

  const doConnect = async () => {
    if (connecting) return;
    //mark as connecting
    setConnecting(true);

    if (reconnect) {
      console.log('Reconnecting...');
      //wait for 5 seconds before reconnecting
      await new Promise((resolve) => setTimeout(resolve, 3000));
      //reset
      setReconnect(false);
    }

    try {
      const deviceID = localUserRef.current.deviceID;
      const email = localUserRef.current.email;
      const owner = localUserRef.current._id;

      const res = await axios.post(apiRef.current, {
        deviceID,
        registerListener: {
          owner,
          deviceID,
          email
        }
      });

      if (!res.data.error) {
        setUuid(res.data);
        setConnecting(false);
        console.log('Connected');
      } else {
        console.log(res.data.error);
      }
    } catch (error) {
      console.log(error);
      //reconnect on error
      setReconnect(true);
    } finally {
      setConnecting(false);
    }
  };

  useEffect(() => {
    state.current.isConnected = !!uuid;
  }, [uuid]);

  const isConnected = () => state.current.isConnected;

  useEffect(() => {
    if (!localUserRef.current) return;
    doConnect();

    return () => {
      close();
    };
  }, [localUserRef.current]);

  return {
    on,
    off,
    emit,
    isConnected,
    connect
  };
}
