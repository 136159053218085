import PTRestAPI from './pt_rest_api'
import { useEffect, useState } from 'react'
import {
  atom,
  selector,
  useRecoilState,
  useRecoilValue,
  useRecoilValueLoadable,
  useSetRecoilState,
} from 'recoil'
import { sleep } from './time'
import { currentLanguageAtom } from './localization.js'
import { recoilPersist } from 'recoil-persist'
import emailValidator from 'email-validator'

const { persistAtom } = recoilPersist()
export const getErrorMessages = (error) => {
  switch (error) {
    case 'invalid_user':
      return "This account doesn't exist"
    case 'free_user':
      return "This account doesn't exist"
    case 'invalid_device':
      return 'There was a problem registering. Please try reinstalling the application'
    case 'magic_link_generate_error':
      return 'There was a problem verifying your email.\nThe support was notified and will resolve it shortly.'
    case 'invalid_credentials':
      return 'Invalid credentials'
    case 'user_exists':
      return 'Email is already registered'
  }
  return error
}

const CREDENTIALS_KEY = 'pt-dck'

const readAuth = () => {
  try {
    const data = localStorage.getItem(CREDENTIALS_KEY)
    return data ? JSON.parse(data) : {}
  } catch {
    return {}
  }
}

export const authAtom = atom({
  key: 'auth',
  default: readAuth(),
  effects: [
    ({ onSet }) =>
      onSet((newValue, _, isReset) => {
        if (!isReset) {
          localStorage.setItem(CREDENTIALS_KEY, JSON.stringify(newValue))
        }
      }),
  ],
})

export const isAuthenticatedSelector = selector({
  key: 'isAuthenticated',
  get: async ({ get }) => {
    const auth = get(authAtom)
    const isLogged = auth.deviceID && (await PTRestAPI.IsDeviceLogged(auth))
    return isLogged
  },
  set: () => {}, // this is here so this become writable
})

export function useAuth() {
  if (import.meta.env.SSR) return undefined

  const auth = useRecoilValue(authAtom)
  const { state, contents } = useRecoilValueLoadable(isAuthenticatedSelector)
  return state === 'hasValue' ? (contents ? auth : false) : undefined
}

const profileVersionAtom = atom({
  key: 'profileVersionAtom',
  default: 0,
})

export function useRefreshProfile() {
  const [version, setVersion] = useRecoilState(profileVersionAtom)
  return async () => setVersion(version + 1)
}

let cachedValue
let latestVersion

export const userProfileSelector = selector({
  key: 'userProfile',
  get: async ({ get }) => {
    const currentVersion = get(profileVersionAtom)
    if (latestVersion === currentVersion && cachedValue) return cachedValue

    latestVersion = currentVersion

    if (get(isAuthenticatedSelector)) {
      const auth = get(authAtom)
      //iek is inflight encryption key used to decode passwords and texts
      const { result } = await PTRestAPI.GetUserProfile(auth, {
        fields: 'name avatar email devices iek',
      })

      return result
    }
  },
})

export const userPreferencesSelector = selector({
  key: 'userPreferences',
  set: ({ get }, newValue) => {
    const auth = get(authAtom)
    if (!auth) return
    PTRestAPI.UserPreferences(auth, newValue)
  },
  get: async ({ get }) => {
    const auth = get(authAtom)
    if (!auth) return
    const result = await PTRestAPI.UserPreferences(auth, '*')
    return result
  },
})

async function fetchAccount(auth) {
  return await PTRestAPI.GetStoreAccount(auth)
}

// atom to refresh the account
const accountVersionAtom = atom({
  key: 'accountVersionAtom',
  default: 0,
})

export const accountSelector = selector({
  key: 'account',
  get: async ({ get }) => {
    get(accountVersionAtom)
    const auth = get(authAtom) // do not remove this; this makes selector refreshes when auth is changed
    const isAuthenticated = get(isAuthenticatedSelector)
    if (isAuthenticated) return await fetchAccount(auth)
  },
})

export const subscriptionSelector = selector({
  key: 'subscription',
  get: async ({ get }) => {
    get(accountVersionAtom)
    const auth = get(authAtom) // do not remove this; this makes selector refreshes when auth is changed
    const isAuthenticated = get(isAuthenticatedSelector)
    if (isAuthenticated) {
      const products = await PTRestAPI.GetUserSubscriptions(auth, {
        fields: ['subscription', 'description', 'name', 'amount', 'status'],
      })
      return products[0] ?? false
    }
  },
})

export function useRefreshAccount() {
  const [version, setVersion] = useRecoilState(accountVersionAtom)
  return async () => setVersion(version + 1)
}

export function useLogout() {
  const setAuth = useSetRecoilState(authAtom)
  const setIsUserLogged = useSetRecoilState(isAuthenticatedSelector)
  return function () {
    setAuth({})
    setIsUserLogged(false)
  }
}

//check for a logged device every X seconds
async function awaitAuth(auth) {
  //reset login break, external systems may break it
  PTRestAPI.BreakAwaitAuth = false

  while (true) {
    if (PTRestAPI.BreakAwaitAuth) break
    await sleep(1000)
    //Return tru on successful device login
    if (await PTRestAPI.IsDeviceLogged(auth)) return true
  }
}

const emailNeedsChecking = (credentials) => {
  //if there is an email field in the auth object and no 3rd party credentials like facebook_id, apple_id, google_id
  //we can validate the email
  const { email, google_id, apple_id, facebook_id } = credentials
  //we can continue since there is at least one other credential
  if (google_id || apple_id || facebook_id) return
  //empty email was passed
  if (!email) return 'email_required'
  //the email was invalid
  if (!emailValidator.validate(email)) return 'invalid_email'
}

export function useLoginWithCredentials() {
  const [state, setState] = useState()
  const [error, setError] = useState(false)
  const setAuth = useSetRecoilState(authAtom)

  const startLogin = async (credentials) => {
    setState('pending')
    setError(undefined)

    const response = await PTRestAPI.Login(credentials, {
      fetch_user_fields: ['_id', 'name', 'avatar', 'email'],
    })

    // The login was successful
    if (response.user) {
      // console.log('Platform Login Success')
      setAuth({ ...credentials, _id: response.user._id })
      setState('success')
      return
    }

    //The device is not yet logged in
    if (
      response.error === 'device_not_logged' ||
      response.error === 'unverified_user'
    ) {
      setState('waiting-auth')

      const deviceLogged = await awaitAuth(credentials)

      if (!deviceLogged) {
        setState(undefined)
        return
      }

      //if there is no userID, get it from the server
      if (!credentials._id) {
        const { _id } = await PTRestAPI.VerifyCredentials(credentials)
        credentials._id = _id
      }
      setAuth(credentials)
      setState('success')

      return
    }

    //handle other errors
    if (response.error) {
      setError(getErrorMessages(response.error))
      setState(undefined)
      return
    }
  }

  return { state, error, startLogin }
}

// Make sure that a device exists
export async function ensureDevice(auth) {
  let deviceID = null

  if (auth?.deviceID && auth.deviceID !== '') {
    deviceID = auth.deviceID
  }
  //valid deviceID found in auth
  if (deviceID) {
    localStorage.setItem('deviceID', deviceID)
    return deviceID
  }

  //check if we have a deviceID in the local storage
  deviceID = localStorage.getItem('deviceID')
  if (deviceID) return deviceID

  //infinite retry, obtain new device
  while (true) {
    const response = await PTRestAPI.CreateDevice()
    if (response) {
      deviceID = response.deviceID
      localStorage.setItem('deviceID', deviceID)
      break
    }
    await sleep(500)
  }

  return deviceID
}

export function useCreateInternalUser() {
  const [state, setState] = useState()
  const [error, setError] = useState()
  const setAuth = useSetRecoilState(authAtom)

  const createInternalUser = async function () {
    setState('pending')
    const auth = { deviceID: await ensureDevice() }

    auth.internal_id = await PTRestAPI.RequestInternalID(auth)
    const response = await PTRestAPI.CreateUser(auth)
    auth._id = response._id

    if (response.error) {
      setError(response.error)
      return
    }

    setState('waiting-auth')
    const deviceLogged = await awaitAuth(auth)

    if (!deviceLogged) {
      setState(undefined)
      return
    }

    setState('success')

    setAuth(auth)
  }

  return { state, error, createInternalUser }
}

// returns {error, state, createUser}
export function useCreateUserWithCredentials() {
  const [state, setState] = useState()
  const [error, setError] = useState()
  const setAuth = useSetRecoilState(authAtom)
  const auth = useAuth()

  const createUser = async function (credentials) {
    setState(undefined)
    setState('pending')

    const response = await PTRestAPI.CreateUser(credentials)

    if (response.error) {
      setState(undefined)
      setError(response.error)
      return
    }

    setState('waiting-auth')
    const deviceLogged = await awaitAuth(credentials)

    if (!deviceLogged) {
      setState(undefined)
      return
    }

    //if there is no userID, get it from the server
    if (!credentials._id) {
      const { _id } = await PTRestAPI.VerifyCredentials(credentials)
      credentials._id = _id
    }
    //set the local authorization object
    setAuth(credentials)
    setState('success')
  }

  return { state, error, createUser }
}

export function useSigninUserWithCredentials() {
  const [state, setState] = useState()
  const [error, setError] = useState()
  //login
  const {
    startLogin,
    error: loginError,
    state: loginState,
  } = useLoginWithCredentials()
  //create user
  const {
    createUser,
    error: createError,
    state: createState,
  } = useCreateUserWithCredentials()

  //merge the login and create error/state to the signin error/state
  useEffect(() => {
    if (loginError) {
      setError(loginError)
      setState(undefined)
    }
    if (createError) {
      setError(createError)
      setState(undefined)
    }
    if (loginState) {
      setState(loginState)
      setError(undefined)
    }
    if (createState) {
      setState(createState)
      setError(undefined)
    }
  }, [loginError, createError, loginState, createState])

  const signinUser = async function (credentials) {
    setState(undefined)
    setState('pending')

    //see if the user input is valid
    const emailCheck = emailNeedsChecking(credentials)
    if (emailCheck) {
      setError(emailCheck)
      setState(undefined)
      return
    }

    //make sure we have deviceID
    credentials = {
      ...credentials,
      deviceID: await ensureDevice(credentials),
    }

    const { result, error } = await PTRestAPI.VerifyCredentials(credentials)
    //if there is a result those are valid credentials and we need to login the user
    if (result) {
      await startLogin(credentials)
      return
    }
    //Create the user if the credentials
    if (error == 'invalid_user') await createUser(credentials)
  }

  return { state, error, signinUser }
}

export const currencyAtom = atom({
  key: 'currency',
  default: 'usd',
})
