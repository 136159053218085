import React from 'react'
import { XMarkIcon } from '@heroicons/react/24/outline'

export default function ChatHeader({
  localUser,
  supportUser,
  setIsChatVisible,
}) {
  if (!localUser) {
    return (
      // add the logo of the company and Aldoo Support as the name of the company
      <div className="bg-red-500 p-3 flex justify-between items-center relative">
        <div className="flex items-center relative">
          <div className="relative">
            <img
              src="static/images/logos/pdf-logo-white.webp"
              alt="Support"
              className="w-10 h-10"
            />
          </div>
          <div className="ml-2 flex flex-col">
            <span className="ml-2 text-white">Aldoo Support</span>
          </div>
        </div>
        <XMarkIcon
          className="w-6 h-6 text-white cursor-pointer"
          onClick={() => setIsChatVisible(false)} // Close chat window and show the button
        />
      </div>
    )
  }

  return (
    <div className="bg-red-500 p-3 flex justify-between items-center relative">
      <div className="flex items-center relative">
        <div className="relative">
          <img
            src={supportUser?.avatar}
            alt="Support"
            className="w-10 h-10 rounded-full"
          />
          {supportUser?.online && (
            <span className="absolute bottom-0 right-0 h-3 w-3 bg-green-500 rounded-full border-2 border-white"></span>
          )}
        </div>
        <div className="ml-2 flex flex-col">
          <span className="ml-2 text-white">
            {supportUser ? supportUser.name : 'No user selected'}
          </span>
          <span className="ml-2 text-[0.7em] text-white">
            {supportUser?.online ? 'Online' : 'Away - notified over email'}
          </span>
        </div>
      </div>
      <XMarkIcon
        className="w-6 h-6 text-white cursor-pointer"
        onClick={() => setIsChatVisible(false)} // Close chat window and show the button
      />
    </div>
  )
}
