import React, { useState, useLayoutEffect, useEffect } from 'react'
import { motion } from 'framer-motion'
import { Document, Page, pdfjs } from 'react-pdf'
import 'react-pdf/dist/esm/Page/AnnotationLayer.css'
import 'react-pdf/dist/esm/Page/TextLayer.css'
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`
import isMobile from 'is-mobile'

const MiniMap = ({ file, controller }) => {
  const [isMobileDevice, setIsMobileDevice] = useState(undefined)
  const [numPages, setNumPages] = useState(null)
  const [defaultZoom, setDefaultZoom] = useState(0.8)
  useLayoutEffect(() => {
    const change = (e) => {
      if (document.hidden) {
      } else {
        setDefaultZoom(defaultZoom + 0.01)
        setTimeout(() => {
          setDefaultZoom(defaultZoom)
        }, 100)
      }
    }

    document.addEventListener('visibilitychange', change)
    return () => {
      document?.removeEventListener('visibilitychange', change)
    }
  }, [])

  useEffect(() => {
    setIsMobileDevice(isMobile())
  }, [])

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages)
  }

  if (isMobileDevice === undefined) return null

  const desktopStyle = {
    top: '0px',
    left: 0,
    width: '200px',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
    zIndex: 19,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  }
  const desktopClassName =
    'bg-neutral_3 relative h-[calc(100vh-125px)] overflow-hidden'

  const mobileStyle = {
    top: '100px',
    left: 0,
    width: '200px',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
    zIndex: 19,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  }
  const mobileClassName =
    'bg-neutral_3 absolute h-[calc(100%-160px)] overflow-hidden'

  return (
    controller.params.minimap && (
      <motion.div
        initial={{ opacity: 0, x: -200 }}
        animate={{
          opacity: controller.params.minimap ? 1 : 0,
          x: controller.params.minimap ? 0 : -200,
        }}
        transition={{ duration: 0.5 }}
        className={isMobileDevice ? mobileClassName : desktopClassName}
        style={isMobileDevice ? mobileStyle : desktopStyle}
      >
        <div className="h-full py-10 flex flex-col w-full overflow-y-auto hide-scrollbar">
          <Document file={file} onLoadSuccess={onDocumentLoadSuccess}>
            <div className="flex flex-col px-4">
              {Array.from(new Array(numPages), (el, index) => (
                <div
                  key={`page_${index + 1}`}
                  className="flex flex-col"
                  onClick={() => {
                    controller.gotoPage(index + 1)
                  }}
                >
                  <Page
                    scale={defaultZoom}
                    rotate={0}
                    key={`page_${index + 1}`}
                    pageNumber={index + 1}
                    height={270}
                  />
                  <div className="text-sm text-text py-2 text-white text-center">
                    {index + 1}
                  </div>
                </div>
              ))}
            </div>
          </Document>
        </div>
      </motion.div>
    )
  )
}

export default MiniMap
