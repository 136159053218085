import React, { useRef, useState, useEffect } from 'react'
import { motion } from 'framer-motion'

import ToolBar from './toolbar'
import FileInfoPanel from '../FileInfoPanel'
import { formatFileSize } from '../../utils/localization'
import { renderAsync, parseAsync } from 'docx-preview'
import MiniMap from './minimap'
import { Spinner } from '../Spinner'
import isMobile from 'is-mobile'

function getFileType(fileOrUrl) {
  let result = ''
  if (typeof fileOrUrl === 'string') {
    // Assume it's a URL and extract the file extension from the URL
    const url = new URL(fileOrUrl)
    const pathname = url.pathname
    const extension = pathname.substring(pathname.lastIndexOf('.') + 1)
    result = extension
  } else if (fileOrUrl instanceof File) {
    // Assume it's a File object and get the type property
    result = fileOrUrl.type
  } else {
    // throw new Error('Invalid input: expected a URL string or a File object.')
  }

  //if the file type is in format application/type get the type only
  const index = result.indexOf('/')
  if (index > 0) result = result.substring(index + 1)

  //xlsx
  if (
    result === 'vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
    result === 'application/vnd.ms-excel'
  )
    return 'xlsx'

  //convert to docx
  if (result === 'vnd.openxmlformats-officedocument.wordprocessingml.document')
    return 'docx'

  return result
}

function getFileName(fileOrUrl) {
  if (typeof fileOrUrl === 'string') {
    // Assume it's a URL and extract the file name from the URL
    //if the url is relative expand it to a full url

    //if the url is relative expand it to a full url
    if (!fileOrUrl.startsWith('http')) {
      fileOrUrl = window.location.origin + fileOrUrl
    }

    const url = new URL(fileOrUrl)
    const pathname = url.pathname
    const fileName = pathname.substring(pathname.lastIndexOf('/') + 1)
    return fileName
  } else if (fileOrUrl instanceof File) {
    // Assume it's a File object and get the name property
    return fileOrUrl.name
  } else {
    // throw new Error('Invalid input: expected a URL string or a File object.')
  }
}

const MSViewer = ({
  appController,
  standalone,
  input,
  filename,
  closeAction,
}) => {
  const [isLoading, setIsLoading] = useState(true)
  const viewer = useRef()
  const [isMobileDevice, setIsMobileDevice] = useState(undefined)

  //get the filename from the pdfURL as it may a blob or a file or url
  const fileSize = input?.size ? formatFileSize(input.size) : ''
  const renderInstance = useRef()

  const [params, setParams] = useState({
    zoom: 1,
    rotate: 0,
    minimap: false,
    numPages: 0,
    currentPage: 1,
    closeAction,
    input,
    filename,
    standalone,
  })

  const controller = {
    params,
    appController,
    setZoom: (zoom) => setParams({ ...params, zoom }),
    toggleMinimap: (visible) => {
      setParams((params) => ({ ...params, minimap: visible }))
    },
    gotoPage: (page) => {
      if (!renderInstance.current) return
      setParams((params) => ({ ...params, currentPage: page }))
      const pageEl = renderInstance.current.querySelector(
        `.docx-wrapper > section.docx:nth-child(${page})`,
      )
      pageEl.scrollIntoViewIfNeeded({
        behavior: 'smooth',
        block: 'start',
      })
    },
  }

  useEffect(() => {
    controller.setZoom(isMobileDevice ? 0.5 : 1.25)
  }, [isMobileDevice])

  useEffect(() => {
    setIsMobileDevice(isMobile())
  }, [])

  useEffect(() => {
    ~(async () => {
      if (!renderInstance.current) return
      //if the input is url fetch the file
      if (typeof input === 'string') {
        const response = await fetch(input)
        input = await response.blob()
      }
      await renderAsync(input, renderInstance.current)
      const pages = renderInstance.current.querySelectorAll(
        '.docx-wrapper>section.docx',
      )
      setParams((params) => ({ ...params, numPages: pages.length }))
      setIsLoading(false)
    })()
  }, [input, renderInstance])

  if (isMobileDevice === undefined) {
    setIsMobileDevice(isMobile())
    return
  }

  return (
    <div className="w-full h-full relative">
      <div className="w-full h-full">
        {isMobileDevice ? (
          <div>
            <ToolBar controller={controller} />
            <MiniMap controller={controller} file={input} />
            <FileInfoPanel
              controller={controller}
              filename={filename}
              fileSize={fileSize}
            />
          </div>
        ) : (
          <div>
            <ToolBar controller={controller} />
            <MiniMap controller={controller} file={input} />
            <FileInfoPanel
              controller={controller}
              filename={filename}
              fileSize={fileSize}
            />
          </div>
        )}
      </div>

      <motion.div
        ref={viewer}
        className="w-full absolute top-0 scrollbar scrollbar-thin scrollbar-thumb-neutral_1 scrollbar-track-canvas overflow-y-scroll h-full flex justify-center m-0"
        // style={{zIndex: 10}}
        animate={{ paddingLeft: params.minimap ? 200 : 0 }}
        transition={{ duration: 0.5 }} // Adjust duration as needed
      >
        <div
          className="flex flex-col  w-full h-full pt-[100px]"
          style={{ zoom: params.zoom }}
        >
          {isLoading && (
            <Spinner size="20" className="fixed top-1/2 left-1/2" />
          )}
          <div
            ref={renderInstance}
            id="msdoc-renderer"
            style={{ display: isLoading ? 'none' : 'block' }}
          ></div>
        </div>
      </motion.div>
    </div>
  )
}

export default MSViewer
