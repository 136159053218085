import { useEffect } from 'react'
const useScrollLock = (isLocked) => {
  useEffect(() => {
    if (isLocked) {
      // Lock scroll
      document.body.style.overflow = 'hidden'
    } else {
      // Unlock scroll
      document.body.style.overflow = ''
    }

    // Cleanup function to reset the overflow style when the component unmounts
    return () => {
      document.body.style.overflow = ''
    }
  }, [isLocked])
}

export default useScrollLock
