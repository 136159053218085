import React, { useState } from 'react'
import { useTranslation } from '../../utils/localization'
import ChevronDownIcon from '@heroicons/react/24/outline/ChevronDownIcon'
import ChevronUpIcon from '@heroicons/react/24/outline/ChevronUpIcon'
import settingsMenuItems from '../../utils/settingsMenuItems.jsx'
import Button from '../Button'
import { navigate } from 'vike/client/router'
const SettingsMenu = ({ handleCloseMenu }) => {
  const t = useTranslation()
  const [isOpen, setIsOpen] = useState(false)

  const toggleMenu = () => {
    setIsOpen(!isOpen)
  }

  const handleItemClick = (index) => {
    const section = settingsMenuItems[index].id
    navigate(`/user?section=${section}`)
    handleCloseMenu()
  }

  return (
    <div>
      <button
        onClick={toggleMenu}
        className="flex items-center w-full text-left"
      >
        <span className="flex-1">{t`Settings`}</span>
        {isOpen ? (
          <ChevronUpIcon className="h-5 w-5 text-text" />
        ) : (
          <ChevronDownIcon className="h-5 w-5 text-text" />
        )}
      </button>
      {isOpen && (
        <div className="ml-5 mt-5">
          {settingsMenuItems.map((item, index) => {
            if (item === 'divider') {
              return <div className="w-full h-[1px] bg-bordered" key={index} />
            }
            return (
              <li
                key={index}
                className={`cursor-pointer mb-2 h-[50px] flex items-center`}
              >
                <Button
                  variant="user_settings"
                  onClick={() => handleItemClick(index)}
                >
                  <div
                    className={`flex flex-row text-sm md:text-base font-normal hover:font-medium `}
                  >
                    {item.icon && item.icon}
                    {item.title}
                  </div>
                </Button>
              </li>
            )
          })}
        </div>
      )}
    </div>
  )
}

export default SettingsMenu
