import { atom } from "recoil";
import { recoilPersist } from "recoil-persist";

const { persistAtom } = recoilPersist()

export const languageInAtom = atom({
  key: 'language_in',
  default: 'auto',
  effects: [persistAtom]
})

export const languageOutAtom = atom({
  key: 'language_out',
  default: 'en',
  effects: [persistAtom]
})

export const supportedTranslationLanguagesAtom = atom({
  key: 'supported_translation_languages',
  default: [],
  effects: [persistAtom]
})

