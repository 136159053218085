import React from 'react';
import './style.css';

const CircularProgress = ({ progress }) => {
  const radius = 18; // Radius of the circle
  const circumference = 2 * Math.PI * radius; // Circumference of the circle
  const strokeDashoffset = circumference - (progress / 100) * circumference;

  return (
    <svg className="progress-circle" width="48" height="48">
      <g transform="rotate(-90 24 24)"> {/* Rotate only the group containing the circles */}
        <circle
          className="progress-circle-background"
          strokeDasharray={circumference}
          cx="24"
          cy="24"
          r={radius}
        />
        <circle
          className="progress-circle-foreground"
          strokeDasharray={circumference}
          strokeDashoffset={strokeDashoffset}
          cx="24"
          cy="24"
          r={radius}
        />
      </g>
      {/* Percentage Text */}
      <text
        x="50%"
        y="50%"
        textAnchor="middle"
        dy=".3em"
        className="progress-circle-text"
      >
        {progress}%
      </text>
    </svg>
  );
};

export default CircularProgress;
