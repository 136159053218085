import React from 'react'
import Button from '../../Button'
import Typography from '../../Typography'
import { useTranslation } from '../../../utils/localization'

const SubscriptionCard = ({
  title,
  price,
  features,
  isBestValue,
  onSelected,
  discount,
}) => {
  features = features.split('\n')
  const t = useTranslation()

  return (
    <div 
      className="relative w-full max-w-[400px] md:max-w-md md:w-full mx-auto border border-bordered rounded-lg shadow-lg p-6 bg-neutral_1 overflow-hidden"
    >
      {isBestValue && (
        <div className="absolute top-0 left-0 right-0 w-full h-[30px] px-2 flex items-center bg-[#ffb900] mb-2">
          <Typography className="text-sm font-extrabold text-[#252525] text-center w-full">
            {t`Best Value`.toUpperCase()}
          </Typography>
        </div>
      )}

      <Typography variant="h3" className="mt-6 mb-2">
        {title}
      </Typography>

      <Typography variant="h3" className="mb-4">
        {price}
      </Typography>

      <ul className="list-disc list-inside min-w-[360px] min-h-[160px] pl-[20px] md:pl-0 lg:pl-[20px] mb-1">
        {features.map((feature, index) => (
          <li
            key={index}
            className="text-text font-medium text-[13px] text-indent-neg5"
          >
            {/*<CheckIcon className="h-4 w-4 mr-2 text-success" />*/}
            {feature}
          </li>
        ))}
      </ul>

      {/* Discount Section */}
      {discount && (
        <div className="flex flex-row justify-center items-center mb-5">
          <div className="flex flex-col justify-center text-center">
            <div className="text-text font-bold">
              Lifetime Translation Discount
            </div>
            {/* Add line divider here */}
            <div className="w-full h-[1px] bg-text mt-2 mb-2 mx-auto"></div>
            <div className="text-text text-xl font-bold">{discount}</div>
            <div className="text-text text-sm">{'cheaper translations'}</div>
          </div>
        </div>
      )}
      {/* The most important button */}
      <Button className="w-full max-w-[350px] h-[70px]" variant="action" onClick={onSelected}>
        {t`Buy Now`}
      </Button>
    </div>
  )
}

export default SubscriptionCard
