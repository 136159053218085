import { getApiUrl } from "./routing.js";
import { io } from "socket.io-client";

function startPopupCheck(popup, onCompleted) {
  const check = setInterval(() => {
    if (popup?.closed || popup?.closed === undefined) {
      clearInterval(check)
      onCompleted(false)
    }
  }, 100)

  return () => clearInterval(check)
}

function openPopup() {
  const width = 600
  const height = 700
  const left = window.innerWidth / 2 - width / 2
  const top = window.innerHeight / 2 - height / 2

  return window.open(
    "",
    '',
    `toolbar=no, location=no, directories=no, status=no, menubar=no,
      scrollbars=no, resizable=no, copyhistory=no, width=${width},
      height=${height}, top=${top}, left=${left}`,
  )
}


export default function startAuth(provider, onCompleted) {
  const apiUrl = getApiUrl()
  const socket = io(apiUrl)
  let popup = openPopup()
  let stopCheck;

  socket.on(`${provider}`, (user) => {
    stopCheck()
    popup?.close()
    onCompleted(user)
    socket.disconnect()
  })

  socket.on('connect', () => {
    popup.location.href = `${apiUrl}/${provider}?socketId=${socket.id}`
    stopCheck = startPopupCheck(popup, () => {
      onCompleted()
      socket.disconnect()
    });
  })
}
