import { useEffect } from 'react'
import { usePromiseWithAuth } from '../../utils/usePromise'

export default ({
  apiMethod,
  page,
  itemsPerPage,
  search,
  refreshKey,
  sort,
  onData,
}) => {
  const { result, execute } = usePromiseWithAuth(
    (auth) => {
      return apiMethod(auth, {
        page,
        itemsPerPage,
        search,
        sort,
        // fields:
        //   'pages thumb square name author author_display_name contentDuration pid bookSettings',
      })
    },
    false,
    false,
  )

  useEffect(() => {
    if (!search) return
    //skip empty search
    const keys = Object.keys(search)
    if (!keys.length) return

    if (execute) execute()
  }, [search])

  useEffect(() => {
    if (execute) execute()
  }, [page, itemsPerPage, refreshKey])

  useEffect(() => {
    if (result && onData) {
      onData(result)
    }
  }, [result])
}
