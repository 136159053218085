import React, { useEffect, useState } from 'react'
import Button from '../Button'
import { ArrowLeftIcon, ArrowRightIcon } from '@heroicons/react/24/solid'
import Tooltip from '../Tooltip'
import { useTranslation } from '../../utils/localization'

const PageNavigator = ({ controller }) => {
  const [inputPage, setInputPage] = useState(1)
  const t = useTranslation()

  const { numPages } = controller.params

  useEffect(() => {
    setInputPage(controller.params.currentPage)
  }, [controller.params.currentPage])

  const handleInputChange = (e) => {
    const value = parseInt(e.target.value)
    if (!isNaN(value)) {
      if (value < 1) {
        setInputPage(1)
      } else if (value > numPages) {
        setInputPage(numPages)
      } else {
        setInputPage(value)
      }
    } else {
      setInputPage('')
    }
  }

  const handlePageChange = () => {
    let gotoPage = parseInt(inputPage)
    if (gotoPage < 1) {
      setInputPage(1)
      gotoPage = 1
    } else if (inputPage > numPages) {
      gotoPage = numPages
      setInputPage(numPages)
    }

    controller.gotoPage(gotoPage)
  }

  const handlePrevPage = () => {
    if (inputPage > 1) {
      controller.gotoPage(inputPage - 1)
    }
  }

  const handleNextPage = () => {
    if (inputPage < numPages) {
      controller.gotoPage(inputPage + 1)
    }
  }

  return (
    <div className=" flex flex-row items-center gap-4">
      <Tooltip text={t(`Previous page`)}>
        <Button variant="viewer_controls_small" onClick={handlePrevPage}>
          <ArrowLeftIcon className="w-4 h-4" />
        </Button>
      </Tooltip>
      <input
        className="outline-none w-16 px-2 py-1 border border-bordered bg-neutral rounded text-text text-center text-sm"
        value={inputPage}
        onChange={handleInputChange}
        onBlur={handlePageChange}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            handlePageChange()
          }
        }}
        min={1}
        max={numPages}
      />
      <span className="text-center w-1 text-text text-sm"> / </span>
      <span className="text-center w-8 text-text text-sm"> {numPages}</span>
      <Tooltip text={t(`Next page`)}>
        <Button variant="viewer_controls_small" onClick={handleNextPage}>
          <ArrowRightIcon className="w-4 h-4" />
        </Button>
      </Tooltip>
    </div>
  )
}

export default PageNavigator
