import { ChevronLeftIcon } from '@heroicons/react/24/solid'
import { useState } from 'react'
import { Coupon } from '../../Coupon/index.jsx'
import { Link } from '../../Link/index.jsx'
import PaymentController from '../../PaymentController/index.jsx'
import Typography from '../../Typography/index.jsx'
import ProductCard from './ProductCard.jsx'
import { useTranslation } from '../../../utils/localization.js'

export default function PaymentMobile({ pid, onCancel }) {
  const t = useTranslation()
  //get the Checkout component and meaningful purchase data
  const { paymentInfo, productData, currency, Checkout } = PaymentController({
    pid,
    type: 'credits',
  })
  const [discountedPrice, setDiscountedPrice] = useState()
  const titleText = t`Get Credits`

  return (
    <div className="w-full max-w-[400px] sm:max-w-[100%] h-full pt-8 mx-auto">
      {/* -----Top section----- */}
      <div className="bg-canvas w-full h-[60px] absolute top-0 left-0 pt-[8px] flex flex-col justify-between border-b border-bordered">
        {/* -----Back btn----- */}
        <div onClick={onCancel} className="z-50 ml-3 mt-2 w-40 h-40">
          <ChevronLeftIcon className="w-7 h-7 text-text" />
        </div>
      </div>
      {/* -----Dialog title----- */}
      <Typography className="absolute w-full top-4 left-0 font-title text-[20px] sm:text-[24px] text-text font-bold text-center">
        {titleText}
      </Typography>

      <div className="flex flex-col items-center justify-between w-full mx-auto">
        {/* ----- Upper section -----*/}
        <div className="w-full max-w-[550px] flex flex-col justify-between gap-4 sm:gap-2 pt-6">
          {productData && productData.length > 0 && (
            <ProductCard
              amount={paymentInfo?.cost}
              discountedAmount={discountedPrice}
              currency={currency}
              product={productData[0]}
              className="mb-6"
            />
          )}
          <Coupon
            payment={paymentInfo?.payment}
            onCouponApplied={({ discountedAmount }) =>
              setDiscountedPrice(discountedAmount)
            }
          />
          {/* -----Divider----- */}
          <div className="w-full border-b border-bordered mt-6"></div>
        </div>

        {/* ----- Payment section -----*/}
        <div className="max-w-[550px] w-full flex flex-col items-center pt-6">
          {/* The product checkout */}
          <Checkout />
        </div>
      </div>

      {/* Privacy Policy notice */}
      <div className="flex items-center justify-center w-full h-full py-4">
        <Typography className="text-neutral_2 text-sm text-center">
          {t`For more info of how we process your personal data, please read our`}
          <Link
            to="/privacy-policy"
            className="text-link hover:text-primary_lt"
          >
            {' ' + t`Privacy policy`}
          </Link>
          .
        </Typography>
      </div>
    </div>
  )
}
