import { ArrowPathIcon, CheckCircleIcon } from '@heroicons/react/24/solid'
import { useEffect } from 'react'

export default function TextField({
  id,
  value,
  type,
  label,
  autoComplete,
  isFocused,
  variant,
  inputRef,
  placeholderText,
  required,
  helpText,
  error,
  onChange,
  showProgress = false,
  showCompleted = false,
  readOnly = false, // Add readOnly prop with a default value of false
}) {
  useEffect(() => {
    if (isFocused) {
      inputRef?.current.focus()
    }
  }, [isFocused, inputRef])

  return (
    <div className="relative">
      <div className="absolute right-[10px] top-[36px]">
        {showProgress && (
          <ArrowPathIcon className="w-6 h-6 text-primary_lt animate-spin" />
        )}
        {showCompleted && <CheckCircleIcon className="w-6 h-6 text-success" />}
      </div>
      <label
        htmlFor={id}
        className="block mb-2 text-sm font-medium text-text_secondary"
      >
        {label}
      </label>
      <input
        id={id}
        value={value}
        autoComplete={autoComplete}
        autoFocus={isFocused}
        type={type ?? 'text'}
        placeholder={placeholderText}
        required={required}
        className="bg-neutral_2 text-decoration-none border border-bordered text-text text-sm rounded-lg focus:ring-primary focus:border-primary block w-full p-2.5 outline-none border-transparent focus:border-transparent focus:ring-0"
        onChange={onChange}
        ref={inputRef}
        readOnly={readOnly} // Add the readOnly attribute to the input
      />
      {error && <p className="text-error text-sm mt-2">{error}</p>}
    </div>
  )
}
