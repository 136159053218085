import * as Sentry from '@sentry/react'
import { useEffect } from 'react'

let initialized = false
export default function SentryWrapper() {
  //initialize Sentry
  useEffect(() => {
    if (initialized) return
    initialized = true
    //initialize sentry once
    Sentry.init({
      dsn:
        'https://188e1d9b6c4b62e14752d73b0e62d72d@o1035081.ingest.us.sentry.io/4507488608649216',
      integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
      ],
      // Performance Monitoring
      tracesSampleRate: 1.0, //  Capture 100% of the transactions
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      //   tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
      // Session Replay
      replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
      ignoreErrors: [
        "Failed to load Stripe.js"
      ]
    })
  }, [])

  return null
}
