import React, { useEffect, useState } from 'react'
import Modal from '../Modal'
import { useTranslation } from '../../utils/localization'
import Typography from '../Typography'
import Button from '../Button'

export default function ({ isOpen, onCloseRequested, onEnable }) {
  const t = useTranslation()
  const onClose = () => {
    onCloseRequested()
  }

  return (
    <Modal
      isOpen={isOpen}
      onCloseRequested={onClose}
      className="relative w-full h-full sm:h-auto sm:max-w-[700px] overflow-y-hidden"
    >
      {/* Content */}
      <div className="w-full h-[100vh] sm:max-h-[700px] m-auto flex flex-col items-center justify-between text-text py-[60px] sm:py-[40px]">
        {/* Upper Section */}
        <div className="absolute top-0 flex flex-col items-center justify-center w-full h-[50%] bg-[#1d1d26] pt-[80px] lg:pt-[100px] pb-[16px]">
          <div>
            <Typography className="font-title text-[44px] font-bold text-center text-white leading-10 tracking-wide">
              {t`MEMBERS`}
            </Typography>
            <Typography className="font-title text-[44px] font-bold text-center text-white tracking-wide">
              {t`DISCOUNT`}
            </Typography>
          </div>

          <Typography
            variant="h2"
            className="font-title text-center text-white tracking-wide mt-6"
          >
            Up to <span className="bg-white text-aldoo_black rounded p-1 text-[38px]">40%</span> {t`cheaper translation`}
          </Typography>

          {/* Colorful bars */}
          <div className="w-full h-[16px] flex justify-between mt-auto ">
            <div className="w-[65%] h-full bg-[#ff7801] rounded-r-lg" />
            <div className="w-[30%] h-full bg-[#007af3] rounded-l-lg " />
          </div>
        </div>

        {/* Lower Section */}
        <div className="absolute bottom-0 flex flex-col items-center justify-center w-full h-[50%] bg-[#c71e48] gap-12">
          <div>
            <Typography
              variant="h2"
              className="font-title text-center text-white tracking-wide"
            >
              {t`Subscribe & get a discount`}
            </Typography>
            <Typography
              variant="h2"
              className="font-title text-center text-white tracking-wide"
            >
              {t`on every translation`}
            </Typography>
          </div>

          <Button
            onClick={onEnable}
            className="w-[240px] h-[60px] text-white text-[24px] sm:text-[26px] font-bold bg-gray-900 hover:bg-gray-800 py-2 px-4 rounded-full transition duration-200 ease-in-out"
          >
            {t`Enable Now`}
          </Button>
        </div>
      </div>
    </Modal>
  )
}
