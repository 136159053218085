// import { useLocation } from 'react-router'
import { useEffect, useState } from 'react'
import PubSub from 'pubsub-js'
let c = 0
export default function PageViewTracker({ children }) {
  useEffect(() => {
    // If window is not defined, return early
    if (!globalThis['window']) return

    //Handle the url change and announce it
    const handleURLChange = () => {
      const path = window.location.pathname + window.location.search
      PubSub.publish('Tracking_PageView', path)
    }

    // Check if the history methods have already been wrapped
    if (!window.__history_wrapped__) {
      window.__history_wrapped__ = true

      // Create a wrapper around history.pushState and history.replaceState
      const wrapHistoryMethod = (method) => {
        const originalMethod = history[method]
        return function () {
          const result = originalMethod.apply(this, arguments)
          handleURLChange()
          return result
        }
      }

      // Override pushState and replaceState to detect changes
      history.pushState = wrapHistoryMethod('pushState')
      history.replaceState = wrapHistoryMethod('replaceState')
    }

    // Listen for popstate and hashchange events
    window.addEventListener('popstate', handleURLChange)
    window.addEventListener('hashchange', handleURLChange)

    // Clean up event listeners on component unmount
    return () => {
      window.removeEventListener('popstate', handleURLChange)
      window.removeEventListener('hashchange', handleURLChange)
    }
  }, [])

  return children || null
}
