import React from 'react'

const formatFilename = (filename) => {
  if (!filename) return ''
  // extract and decode the filename along with the extension from the path
  const parts = filename.split('/')
  return decodeURIComponent(parts[parts.length - 1])
}

const FileInfoPanel = ({ filename, fileSize, controller }) => {
  const topPosition = controller.params.closeAction ? 'top-0' : 'top-[60px]'

  return (
    //TODO: this will position the FileInfoPanel at the bottom of the screen
    //  <div className="relative fixed top-[calc(100vh-100px)] left-0 w-full bg-black bg-opacity-70 text-white py-2 px-4 flex justify-between items-center z-10">
    <div
      style={{ zIndex: 4 }}
      className={`absolute ${topPosition} left-0 w-full bg-black bg-opacity-70 text-white py-2 px-4 flex justify-between items-center`}
    >
      <div className="flex-grow text-center">
        <span className="text-sm font-medium">{formatFilename(filename)}</span>
      </div>
      <div className="text-sm font-medium">
        <span>{fileSize}</span>
      </div>
    </div>
  )
}

export default FileInfoPanel
