import React, { useEffect, useState } from 'react'
import { useTranslation } from '../../utils/localization'
import { Bars3Icon } from '@heroicons/react/24/outline'
import MenuMobile from './menuMobile.jsx'
import AddCreditsButton from '../AddCreditsButton/index.jsx'
import { useAuth } from '../../utils/platform_auth'
import PricingButton from '../PricingButton'
import { useRecoilValueLoadable } from 'recoil'
import {
  accountSelector,
  subscriptionSelector,
} from '../../utils/platform_auth'
import { useSearchParamMutation } from '../../utils/searchParams'
import { SEARCH_QUERY_FLAGS } from '../../utils/routing'
import { Spinner } from '../Spinner'
import DiscountBar from './discountBar.jsx'
import ChatButtonSmall from '../Chat/chat-button-small.jsx'

export default function NavbarMobile() {
  const t = useTranslation()
  const [menuOpen, setMenuOpen] = useState(false)
  const auth = useAuth()
  const { contents: account, state: accountState } = useRecoilValueLoadable(
    accountSelector,
  )
  const {
    contents: subscription,
    state: subscriptionState,
  } = useRecoilValueLoadable(subscriptionSelector)

  const {
    add: addSearchParam,
    remove: removeSearchParam,
  } = useSearchParamMutation()

  const isLoggedIn = typeof auth === 'object'

  const loaded = accountState !== 'loading' && subscriptionState !== 'loading'

  return (
    <div>
      <div
        className="h-[128px] w-full flex flex-col items-center justify-center border-b-[1px] border-bordered"
        style={{ zIndex: 0 }}
      ></div>
      <div
        className="fixed top-0 left-0 w-full flex flex-col items-center justify-between"
        style={{ zIndex: 200 }}
      >
        <DiscountBar />

        {/* Main NavBar */}
        <div className="w-full h-16 select-none bg-navbar flex items-center justify-between border-b-[1px] border-bordered">
          <div className="flex gap-5 items-center justify-center">
            <button onClick={() => setMenuOpen(!menuOpen)}>
              <Bars3Icon className="pl-4 h-12 w-12 text-text" />
            </button>

          <ChatButtonSmall/>
          </div>
          {<MenuMobile isOpen={menuOpen} setMenuOpen={setMenuOpen} />}
          {!loaded && <Spinner className="mr-4" />}


          {isLoggedIn && (
            <div className="mr-4">
              <AddCreditsButton />
            </div>
          )}

          {loaded && !isLoggedIn && (
            <div className="flex flex-row gap-3 items-stretch flex-no-shrink h-16">
              <PricingButton />
              <a
                className="min-w-[120px] justify-center flex-no-grow flex-no-shrink relative py-2 px-4 leading-normal font-normal text-white no-underline flex items-center bg-primary hover:bg-primary_lt"
                href={addSearchParam(SEARCH_QUERY_FLAGS.SIGNIN)}
              >
                {t`Sign in`}
              </a>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
