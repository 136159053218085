import { useState } from 'react'
import { motion, useMotionValue, useTransform } from 'framer-motion'
import config from '../../tailwind.config'

const ToggleSwitch = ({ isOn, onChange }) => {
  // Function to handle toggle switch change
  const handleToggle = () => {
    const newValue = !isOn
    if (onChange) onChange(newValue)
  }

  const primaryColor = globalThis.getComputedStyle
    ? `rgb(${getComputedStyle(document.documentElement).getPropertyValue(
        '--color-primary',
      )} )`
    : ''

  const bgColor = globalThis.getComputedStyle
    ? `rgb(${getComputedStyle(document.documentElement).getPropertyValue(
        '--color-neutral_2',
      )} )`
    : ''

  return (
    <div
      className="relative w-14 h-6 bg-gray-300 rounded-full cursor-pointer"
      onClick={handleToggle}
    >
      {/* Background */}
      <motion.div
        className="absolute inset-0 flex items-center justify-between px-1 rounded-full"
        initial={{ backgroundColor: '#f3f4f6' }}
        animate={{
          backgroundColor: isOn ? primaryColor : bgColor,
        }}
        transition={{ duration: 0.2 }}
      ></motion.div>

      {/* Switch */}
      <motion.div
        className="absolute top-0 w-6 h-6 bg-white rounded-full shadow-sm"
        drag="x"
        style={{
          boxShadow: `0 0 5px 0 rgba(0, 0, 0, 0.5)`, // Thicker shadow background color
        }}
        dragConstraints={{ left: 0, right: 30 }}
        dragElastic={0}
        onDragEnd={(event, info) => {
          if (info.offset.x > 15) {
            if (onChange) onChange(true)
          } else {
            if (onChange) onChange(false)
          }
        }}
        animate={{ x: isOn ? 30 : 0 }}
      />
    </div>
  )
}

export default ToggleSwitch
