import Button from '../Button'
import downloadFile from '../../utils/useDownloadFile'
import { ArrowDownTrayIcon } from '@heroicons/react/24/solid'
import Tooltip from '../Tooltip'
import { useTranslation } from '../../utils/localization'

const formatFilename = (filename) => {
  // extract and decode the filename along with the extension from the path
  const parts = filename.split('/')
  return decodeURIComponent(parts[parts.length - 1])
}

export default ({ controller }) => {
  const t = useTranslation()
  const filename = controller.params?.filename && formatFilename(controller.params.filename)

  return (
    controller.params.input && (
      <div className={'flex items-center space-x-2'}>
        <Tooltip text={t(`Download`)}>
          <Button
            variant="viewer_controls_small"
            onClick={() => {
              downloadFile(controller.params.input, filename)
            }}
          >
            <ArrowDownTrayIcon className="h-5 w-5" />
          </Button>
        </Tooltip>
      </div>
    )
  )
}
