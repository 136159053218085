import startAuth from '../../utils/oauth'
import React from 'react'
import Button from '../Button'

export function AuthorizationButton({ provider, label, onCompleted }) {
  async function completed(payload) {
    if (!payload) return
    const { google_id, apple_id, facebook_id, email } = payload
    const credentials = { google_id, apple_id, facebook_id, email }
    onCompleted(credentials)
  }

  return (
    <Button
      className="h-16 rounded-lg bg-white border border-primary hover:border-primary_lt text-[#252525] hover:text-link transition-colors duration-150 flex items-center justify-center gap-2"
      onClick={() => startAuth(provider, completed)}
    >
      <img
        src={`/static/images/logos/${provider}.svg`}
        alt={`sign in with ${provider}`}
        className="w-8 h-8" // Adjust the size as needed
      />
      {label}
    </Button>
  )
}
