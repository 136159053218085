import React, { useEffect } from 'react'
import StaticImg from '../StaticImg/index.jsx'
import { useTranslation } from '../../utils/localization.js'
import Backdrop from '../Backdrop/index.jsx'
import Typography from '../Typography/index.jsx'
import Card from '../Card/index.jsx'
import { Spinner } from '../Spinner/index.jsx'
import { EnvelopeOpenIcon } from '@heroicons/react/24/solid'
import Modal from '../Modal/index.jsx'
import { useState } from 'react'
import PTRestAPI from '../../utils/pt_rest_api.jsx'

export default function WaitingAuthBackdrop({ email, open }) {
  const t = useTranslation()
  const [isOpen, setIsOpen] = useState(!!open)

  useEffect(() => {
    setIsOpen(!!open)
  }, [open])

  return (
    <Modal
      isOpen={isOpen}
      className="relative w-full h-full sm:h-auto sm:max-w-[700px] bg-canvas overflow-y-auto scrollbar-thin scrollbar-thumb-neutral_3 scrollbar-track-canvas"
      onCloseRequested={() => {
        setIsOpen(false)
        PTRestAPI.BreakAwaitAuth = true
      }}
    >
      <div>
        {open === 'waiting' && <Spinner />}
        {open === true && (
          <div className="mt-[50px] flex flex-col justify-center items-center">
            <EnvelopeOpenIcon className="h-16 w-16 text-primary" />
            <Typography align="center" className="mt-5 text-text text-2xl">
              {t(`Please check your email to confirm your credentials!`)}
            </Typography>
            {/* Line Divider */}
            <div className="w-full border border-bordered my-10"></div>

            <Typography align="center" className="mt-12 text-text text-lg">
              {email}
            </Typography>
            <div align="center" className="text-neutral_3 mb-1 mt-20">
              {t`You'll be automatically redirected once confirmed.`}
              <br />
              {t`If you don't receive an email, please check your spam folder.`}
            </div>
          </div>
        )}
      </div>
    </Modal>
  )
}
