import React from 'react'
import { useEffect } from 'react'
import ReactGA from 'react-ga4'

import { GOOGLE_ANALYTICS_ID } from '../../config'
import PubSub from 'pubsub-js'

export default function GoogleTracking({ children }) {
  useEffect(() => {
    if (!GOOGLE_ANALYTICS_ID) return
    ReactGA.initialize(GOOGLE_ANALYTICS_ID)

    //---------------------------------------------
    // Send the page view detected by the PageViewTracker
    //---------------------------------------------
    PubSub.subscribe('Tracking_PageView', (_, url) => {
      ReactGA.send({ hitType: 'pageview', page: url })
    })

    PubSub.subscribe('Tracking_NewAccountCreated', () => {})

    PubSub.subscribe('Tracking_InitiateCheckout', (_, priceInfo) => {
      ReactGA.event({
        category: 'InitiateCheckout',
        action: priceInfo.pid,
        value: priceInfo.price,
        currency: priceInfo.currency,
      })

      ReactGA.event({
        event: 'begin_checkout',
        ecommerce: {
          currency: priceInfo.currency,
          value: priceInfo.price,
          items: [
            {
              item_id: priceInfo.pid,
              price: priceInfo.price,
              quantity: 1,
            },
          ],
        },
      })
    })

    PubSub.subscribe('Tracking_Subscribed', (_, data) => {
      //Specific Subscription
      ReactGA.event({
        category: 'Subscription',
        action: data.pid,
        value: data.price,
        currency: data.currency,
      })

      //------------------------------------------------
      //announce aggregate specific purchase
      //------------------------------------------------
      ReactGA.event({
        category: 'Purchase',
        action: 'purchase',
        value: data.price,
        currency: data.currency,
        items: [
          {
            item_id: data.pid,
          },
        ],
      })
      //------------------------------------------------
    })

    //data is:
    //price: example => 1.99
    //currency: example => USD, BGN
    //pid: example => aldoo_coin_pack_1
    PubSub.subscribe('Tracking_Purchase', (_, data) => {
      //announce the specific purchase
      ReactGA.event({
        category: 'Purchase',
        action: data.pid,
        value: data.price,
        currency: data.currency,
      })

      //------------------------------------------------
      //announce aggregate specific purchase
      //------------------------------------------------
      ReactGA.event({
        category: 'Purchase',
        action: 'purchase',
        value: data.price,
        currency: data.currency,
        items: [
          {
            item_id: data.pid,
          },
        ],
      })
      //------------------------------------------------
    })
  }, [])

  return children || null
}
