import React from 'react';

export default function SignInPanel({ onSignIn }) {
  return (
    <div className="p-4 space-y-3 h-[40vh] max-h-[500px] overflow-y-auto flex flex-col justify-center items-center">
      {/* Welcome intro */}
      <div className="text-center text-gray-500">
        <h1 className="text-lg font-bold">Please Sign in to Chat</h1>
        <p className="text-sm mt-2">
          Sign in to get started.
        </p>
        <button
          className="mt-4 px-4 py-2 bg-red-500 text-white rounded-lg shadow-lg hover:bg-red-600 transition duration-300"
          onClick={onSignIn} // Calls the sign-in function passed via props
        >
          Sign in
        </button>
      </div>
    </div>
  );
}
