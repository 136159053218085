import React, { useEffect, useState } from 'react'
import './typing-notification.css'

const TypingNotification = ({ name, isVisible }) => {
  const [isTyping, setIsTyping] = useState(false)

  useEffect(() => {
    setIsTyping(isVisible)
  }, [isVisible])

  if (!name) return null

  return (
    <div className="block m-1 text-sm typing-notification-placeholder">
      {isTyping && (
        <p className="m-1 text-left text-gray-500 text-sm">
          {name} is typing
          <span className="relative ml-1">
            <span className="typing-dot">
              <span className="typing-dot">.</span>
              <span className="typing-dot">.</span>
              <span className="typing-dot">.</span>
            </span>
          </span>
        </p>
      )}
    </div>
  )
}

export default TypingNotification
