
export default class Navigation {

    static prompt = {
        title: "title",
        message: "message"
    }

    static nextUrl
    static onPrompt
    static onOpen
    static onClose
    static promptUrls = []
    static condition = () => true

    static close() {
        if (this.onPrompt)
            this.onPrompt(false)
        if (this.onClose)
            this.onClose()
    }
    static confirmNavigation = () => {
        window.location.href = this.nextUrl
        this.close()
    }
    static cancelNavigation = () => {
        this.close()
    }

    /**
     *
     * @param {*} options title, message, condition, path
     * @returns
     */
    static registerPromptFor(options) {
        const { path, title, message, condition, onClose, onOpen } = options
        //if this url is matched when goto is called, we will prompt the user
        if (this.promptUrls.includes(path)) return
        this.promptUrls.push(path)
        this.prompt = { title, message }
        this.condition = condition
        this.onClose = onClose
        this.onOpen = onOpen
    }

    static unregisterPromptFor(url) {
        //remove the url from the prompt list
        this.promptUrls = this.promptUrls.filter((u) => u !== url)
    }

    static goTo(url) {

        const currentUrl = window.location.href
        const currentPathname = new URL(currentUrl).pathname

        //make sure the url is also a pathname
        if (url.startsWith('http')) {
            url = new URL(url).pathname
        }

        //compare path names
        if (!this.promptUrls.includes(currentPathname) || (this.condition && !this.condition())) {
            window.location.href = url
            return
        }
        this.nextUrl = url

        if (this.onPrompt && (!this.condition || this.condition())) {
            //reset any default browser listeners on open
            if (this.onOpen)
                this.onOpen()
            //show the prompt
            this.onPrompt(true)
        }

    }
}

