import UserProfile from '../components/UserProfile'
import OrderHistory from '../components/OrderHistory'
import UserCreditsPanel from '../components/UserCreditsPanel'
import UserSubscriptionsPanel from '../components/UserSubscriptionsPanel'
import PreferencesPanel from '../components/PreferencesPanel'

import {
  UserIcon,
  QueueListIcon,
  CreditCardIcon,
  Cog6ToothIcon,
  CircleStackIcon,
  ArrowLeftStartOnRectangleIcon,
} from '@heroicons/react/24/solid'


//This file holds the items for the settings menu
export default [
  {
    id: 'profile',
    title: 'User Profile',
    content: <UserProfile />,
    icon: <UserIcon className="h-5 mr-2" />,
  },
  {
    id: 'order-history',
    title: 'Order History',
    content: <OrderHistory />,
    icon: <QueueListIcon className="h-5 mr-2" />,
  },
  {
    id: 'credits',
    title: 'Credits',
    content: <UserCreditsPanel />,
    icon: <CircleStackIcon className="h-5 mr-2" />,
  },
  {
    id: 'subscriptions',
    title: 'Subscriptions',
    content: <UserSubscriptionsPanel />,
    icon: <CreditCardIcon className="h-5 mr-2" />,
  },
  {
    id: 'preferences',
    title: 'Preferences',
    content: <PreferencesPanel />,
    icon: <Cog6ToothIcon className="h-5 mr-2" />,
  },
]
