import moment from 'moment'
import React, { useState } from 'react'
import { useTranslation } from '../../utils/localization'
import PTRestAPI from '../../utils/pt_rest_api'
import AddCreditsButton from '../AddCreditsButton'
import DataTable from '../DataTable'
import Divider from '../Divider'
import PurchasePanel from '../PaymentController/PurchasePanel'
import Typography from '../Typography'

const UserCreditsPanel = () => {
  const t = useTranslation()
  const [showCreditsPanel, setShowCreditsPanel] = useState(false)

  const creditRenders = [
    {
      id: 'created',
      title: t(`Date`),
      headerClassName: 'p-2 w-[200px]',
      itemClassName: 'p-2',
      render: (value) =>
        value ? moment(value).format('DD/MM/YYYY HH:mm') : '',
    },
    {
      id: 'amount',
      title: t(`Credits Amount`),
      headerClassName: 'w-[200px]',
      render: (value) => value,
    },
    {
      id: 'type',
      title: t(`Order type`),
      headerClassName: 'w-[200px]',
      render: (value) => {
        return value == 'coins' ? t`credit pack` : t`subscription`
      },
    },
    {
      id: 'platform',
      title: t(`Platform`),
      headerClassName: 'w-[200px]',
      render: (value) => value,
    },
  ]

  return (
    <div className="pb-10">
      <PurchasePanel
        type="credits"
        isOpen={showCreditsPanel}
        onCloseRequested={() => setShowCreditsPanel(false)}
      />
      <DataTable
        preferredHeight="500px"
        itemHeight="h-[50px]"
        title={t`Credits Transactions`}
        apiMethod={PTRestAPI.ListUserTransactions}
        renders={creditRenders}
        noData={
          <Typography className="p-20 text-text md:text-lg text-center">
            {t`You don't have any transactions yet. You can purchase credits by clicking the button below.`}
          </Typography>
        }
      />
      <div className="flex flex-col items-center w-full pt-5">
        <Divider className="mb-8" />
        <AddCreditsButton />
      </div>
    </div>
  )
}

export default UserCreditsPanel
