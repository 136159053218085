import React, { useEffect, useState } from 'react'
import Button from '../Button'
import {
  MagnifyingGlassPlusIcon,
  MagnifyingGlassMinusIcon,
} from '@heroicons/react/24/solid'
import Tooltip from '../Tooltip'
import { useTranslation } from '../../utils/localization'
import isMobile from 'is-mobile'

import Dropdown from '../Dropdown'

const Zoom = ({ controller }) => {
  const t = useTranslation()
  const [zoomLevel, setZoomLevel] = useState(
    controller?.params ? controller.params.zoom * 100 : 100,
  )
  const zoomOptions = [25, 50, 75, 100, 125, 150, 175, 200, 400]

  const [isMobileDevice, setIsMobileDevice] = useState(undefined)

  useEffect(() => {
    setIsMobileDevice(isMobile())
  }, [])

  useEffect(() => {
    if (controller) controller.setZoom(zoomLevel / 100)
  }, [zoomLevel])

  const handleZoomIn = () => {
    const currentIndex = zoomOptions.indexOf(zoomLevel)
    if (currentIndex < zoomOptions.length - 1) {
      setZoomLevel(zoomOptions[currentIndex + 1])
    }
  }

  const handleZoomOut = () => {
    const currentIndex = zoomOptions.indexOf(zoomLevel)
    if (currentIndex > 0) {
      setZoomLevel(zoomOptions[currentIndex - 1])
    }
  }

  const handleZoomChange = (value) => {
    setZoomLevel(parseInt(value))
  }

  return (
    <div className="flex items-center space-x-2">
      <Tooltip text={t(`Zoom out`)}>
        <Button variant="viewer_controls_small" onClick={handleZoomOut}>
          <MagnifyingGlassMinusIcon className="h-4 w-4" />
        </Button>
      </Tooltip>
      <Dropdown
        markSelected={false}
        className={''}
        value={zoomLevel}
        options={zoomOptions}
        onItemRender={(item) => `${item}%`}
        onChange={handleZoomChange}
        menuPosition={isMobileDevice ? 'top' : 'bottom'}
      />
      <Tooltip text={t(`Zoom in`)}>
        <Button variant="viewer_controls_small" onClick={handleZoomIn}>
          <MagnifyingGlassPlusIcon className="h-4 w-4" />
        </Button>
      </Tooltip>
    </div>
  )
}

export default Zoom
