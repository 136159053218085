import React, { useState } from 'react'
import Button from '../Button'
import OrderStatusBubble from '../OrderStatusBubble'
import DataTable from '../DataTable'
import { useTranslation } from '../../utils/localization'
import { useAuth } from '../../utils/platform_auth'
import moment from 'moment'
import OrderDetailsDialog from '../OrderDetailsDialog'
import Typography from '../Typography'
import PTRestAPI from '../../utils/pt_rest_api'

const OrderHistory = ({}) => {
  const t = useTranslation()
  const auth = useAuth()

  const [openDetails, setOpenDetails] = useState(null)

  const orderRenders = [
    {
      id: 'details',
      title: t(`Contents`),
      headerClassName: 'p-2 w-[280px]',
      render: (value) => {
        //get the first file name from the files array and display it
        let preview = value.files?.length > 0 ? value.files[0] : ''
        preview =
          preview !== 'N/A'
            ? preview.substring(preview.lastIndexOf('/') + 1)
            : (preview = t`N/A`)

        //add ellipsis to the file name if it is too long
        return value.files?.length > 0 ? (
          <div
            className="text-link hover:underline overflow-hidden overflow-ellipsis whitespace-nowrap"
            onClick={() => setOpenDetails(value)}
          >
            {preview}
          </div>
        ) : (
          <div>{t`N/A`}</div>
        )
      },
    },
    {
      id: 'status',
      title: t(`Status`),
      headerClassName: 'w-[100px]',
      render: (value) => {
        if (value == 'delivered') value = 'completed'
        return <OrderStatusBubble status={value} />
      },
    },
    {
      id: 'created',
      title: t(`Created`),
      headerClassName: 'p-2 w-[140px]',
      itemClassName: 'p-2',
      render: (value) => {
        //format the date as 24 hour time
        return moment(value).format('DD/MM/YYYY HH:mm')
      },
    },
    {
      id: 'type',
      title: t(`Digital Service`),
      headerClassName: 'w-[100px]',
      render: (value) => value,
    },
    {
      id: 'platform',
      title: t`Platform`,
      headerClassName: 'w-[100px]',
      render: (value) => value,
    },
  ]

  return (
    <div className="h-full pb-10">
      <OrderDetailsDialog
        isOpen={openDetails !== null}
        orderDetails={openDetails}
        onClose={() => setOpenDetails(null)}
      />

      <DataTable
        title={t('Your Orders')}
        apiMethod={PTRestAPI.ListUserOrders}
        renders={orderRenders}
        noData={
          <Typography variant="h3" className="text-text">
            {t`You don't have any orders yet.`}
          </Typography>
        }
      />
    </div>
  )
}

export default OrderHistory
