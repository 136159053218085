import React, { useState, useEffect, useRef } from 'react'
import Typography from '../Typography'
import { useTranslation } from '../../utils/localization'
import TextField from '../TextField'
import SocialLinksPanel from '../SocialLinksPanel'
import {
  useAuth,
  userProfileSelector,
  useRefreshProfile,
} from '../../utils/platform_auth'
import { useRecoilValueLoadable } from 'recoil'
import PTRestAPI from '../../utils/pt_rest_api'

const UserProfile = () => {
  const t = useTranslation()
  const auth = useAuth()
  const { contents: userProfile } = useRecoilValueLoadable(userProfileSelector)
  const refreshProfile = useRefreshProfile()
  const nameRef = useRef()
  const [username, setUsername] = useState(userProfile?.name ?? '')
  const [nameError, setNameError] = useState('')
  const [email, setEmail] = useState(userProfile?.email ?? '')
  const [saving, setSaving] = useState({})
  const debounceTimeout = useRef(null)

  useEffect(() => {
    if (
      userProfile &&
      userProfile.name != undefined &&
      userProfile.name != ''
    ) {
      setUsername(userProfile.name)
    }
    if (
      userProfile &&
      userProfile.email != undefined &&
      userProfile.email != ''
    )
      setEmail(userProfile.email)
  }, [userProfile])

  useEffect(() => {
    if (saving.username === 'done') {
      setTimeout(() => {
        setSaving({ ...saving, username: 'idle' })
      }, 1000)
    }
  }, [saving])

  useEffect(() => {
    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current)
    }
    //save username
    debounceTimeout.current = setTimeout(async () => {
      if (saving.username === 'save') {
        if (!username || username == '') {
          setNameError(t`Name is required`)
          nameRef.current.focus()
          return
        }
        const saveUsername = username.trim()
        await PTRestAPI.ChangeUserName(auth, saveUsername)
        refreshProfile()

        setSaving({
          ...saving,
          username: 'done',
        })
      }
    }, 1000)
  }, [username])

  // Handle property change
  const handlePropertyChange = (propertyName) => (e) => {
    const newValue = e.target.value
    const key = propertyName.charAt(0) + propertyName.slice(1)

    // Set the new value
    if (propertyName === 'username') {
      setUsername(newValue)
      setNameError('')
    } else if (propertyName === 'email') {
      setEmail(newValue)
    }

    // Start saving
    setSaving({ ...saving, [key]: 'save' })
  }

  return (
    <div className="flex flex-col lg:flex-row pt-20 md:pt-10 md:p-10 gap-5 mx-auto">
      <div className="max-w-[480px] w-full lg:w-1/2 flex flex-col gap-4 text-text p-4 bg-paper rounded shadow">
        <Typography variant="h4" className="text-text mb-4">
          {t`Profile Details`}
        </Typography>

        <TextField
          inputRef={nameRef}
          label={t(`Username`)}
          error={nameError}
          value={username || ''}
          onChange={handlePropertyChange('username')}
          showProgress={saving.username === 'save' && !nameError}
          showCompleted={saving.username === 'done'}
        />

        {/* Email */}
        <TextField
          label={t(`Email`)}
          onChange={() => {}}
          value={email}
          readonly
        />
      </div>

      <SocialLinksPanel className="max-w-[480px] lg:w-1/2" />
    </div>
  )
}

export default UserProfile
