export default function useDownloadFile(urlOrFile, filename) {
  let filePromise

  if (typeof urlOrFile === 'string') {
    // If the URL is provided, fetch the file
    filePromise = fetch(urlOrFile).then((response) => response.blob())
  } else if (urlOrFile instanceof File) {
    // If a File object is provided, resolve with the File object
    filePromise = Promise.resolve(urlOrFile)
  } else {
    // Invalid input, reject with an error
    return Promise.reject(
      new Error('Invalid input. Expected a URL or a File object.'),
    )
  }

  filePromise
    .then((blob) => {
      const objectUrl = window.URL.createObjectURL(blob)
      const anchor = document.createElement('a')
      anchor.href = objectUrl
      anchor.target = '_blank'
      anchor.download = filename
      document.body.appendChild(anchor)
      anchor.click()
      document.body.removeChild(anchor)
      window.URL.revokeObjectURL(objectUrl) // Clean up
    })
    .catch((error) => console.error('Error downloading file:', error))
}
