import isMobile from 'is-mobile'
import React, { useEffect, useState } from 'react'
import { navigate } from 'vike/client/router'
import Success from '../../pages/success'
import { SEARCH_QUERY_FLAGS } from '../../utils/routing'
import {
  useSearchParamMutation,
  useSearchParamValue,
  updateQueryParams,
} from '../../utils/searchParams'
import Modal from '../Modal'
import PaymentPanel from '../PaymentController/PurchasePanel'
import Signin from '../Signin'
import SubscribersDiscount from '../SubscribersDiscount'
import NavbarDesktop from './navbarDesktop'
import NavbarMobile from './navbarMobile'

export default function Navbar() {
  const [isMobileDevice, setIsMobileDevice] = useState(undefined)
  const signin = useSearchParamValue(SEARCH_QUERY_FLAGS.SIGNIN, false) !== false

  const plans = useSearchParamValue(SEARCH_QUERY_FLAGS.PLANS, false) !== false
  const packages =
    useSearchParamValue(SEARCH_QUERY_FLAGS.PACKAGES, false) !== false
  const success =
    useSearchParamValue(SEARCH_QUERY_FLAGS.SUCCESS, false) !== false
  const offer = useSearchParamValue(SEARCH_QUERY_FLAGS.OFFER, false) !== false

  const {
    add: addSearchParam,
    remove: removeSearchParam,
    replace: replaceSearchParam,
  } = useSearchParamMutation()

  useEffect(() => {
    setIsMobileDevice(isMobile())
  }, [])

  if (isMobileDevice === undefined) return null

  return (
    <>
      {isMobileDevice ? <NavbarMobile /> : <NavbarDesktop />}

      <Modal
        className="max-w-[500px] md:w-[500px] h-full sm:h-auto"
        isOpen={signin}
        onCloseRequested={() => {
          localStorage.removeItem('open-chat-on-login')

          navigate(
            removeSearchParam(
              SEARCH_QUERY_FLAGS.SIGNIN,
              SEARCH_QUERY_FLAGS.RETURN_TO,
            ),
          )
        }}
      >
        {signin && <Signin />}
      </Modal>

      <Modal
        className="sm:w-[800px]"
        isOpen={success}
        onCloseRequested={() => {
          //navigate to the return_to url
          navigate(
            removeSearchParam(
              SEARCH_QUERY_FLAGS.SUCCESS,
              SEARCH_QUERY_FLAGS.RETURN_TO,
              SEARCH_QUERY_FLAGS.PAYMENT_INTENT,
            ),
          )
        }}
      >
        {success && <Success />}
      </Modal>
      {/* Subscription */}
      <PaymentPanel
        type="subscription"
        isOpen={plans}
        onCloseRequested={() =>
          navigate(removeSearchParam(SEARCH_QUERY_FLAGS.PLANS))
        }
      />
      {/* Credits */}
      <PaymentPanel
        type="credits"
        isOpen={packages}
        onCloseRequested={() =>
          navigate(removeSearchParam(SEARCH_QUERY_FLAGS.PACKAGES))
        }
      />
      {/* Please add proper logic to SubscribersDiscount */}
      <SubscribersDiscount
        onEnable={() => {
          navigate(
            replaceSearchParam(
              SEARCH_QUERY_FLAGS.OFFER,
              SEARCH_QUERY_FLAGS.PLANS,
            ),
          )
        }}
        isOpen={offer}
        onCloseRequested={() => {
          navigate(removeSearchParam(SEARCH_QUERY_FLAGS.OFFER))
        }}
      />
    </>
  )
}
