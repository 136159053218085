import React, { useRef, useState, useEffect, useLayoutEffect } from 'react'
import { Document, Page, pdfjs, PasswordResponses } from 'react-pdf'

import 'react-pdf/dist/esm/Page/AnnotationLayer.css'
import 'react-pdf/dist/esm/Page/TextLayer.css'
import { motion } from 'framer-motion'

import ToolBar from './toolbar'
import MiniMap from './minimap'
import FileInfoPanel from '../FileInfoPanel'
import { formatFileSize } from '../../utils/localization'
import { Spinner } from '../Spinner'
import isMobile from 'is-mobile'
import PasswordPanel from '../PasswordPanel'

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`

const PDFViewer = ({
  standalone,
  input,
  password,
  closeAction,
  filename,
  appController,
}) => {
  const [isLoading, setIsLoading] = useState(true)
  const [numPages, setNumPages] = useState(null)
  const [isMobileDevice, setIsMobileDevice] = useState(undefined)
  const [promptForPassword, setPromptForPassword] = useState(false)
  const [passwordIncorrect, setPasswordIncorrect] = useState(false)

  const viewer = useRef()
  const pageHeight = 800

  //get the filename from the pdfURL as it may a blob or a file or url
  const fileSize = input?.size ? formatFileSize(input.size) : ''

  const [params, setParams] = useState({
    zoom: 1,
    rotate: 0,
    minimap: false,
    numPages: 0,
    currentPage: 1,
    closeAction,
    input,
    filename,
    standalone,
  })

  useEffect(() => {
    setIsMobileDevice(isMobile())
    controller.setZoom(setIsMobileDevice ? 0.75 : 1)
  }, [])

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages)
    setParams({ ...params, numPages })
    setIsLoading(false)
    setPromptForPassword(false)
    setPasswordIncorrect(false)
  }

  const updatePageFromScroll = () => {
    if (!viewer.current) return
    const page =
      Math.floor(
        (viewer.current.scrollTop + pageHeight * params.zoom - 20) /
          (pageHeight * params.zoom + 8),
      ) || 1

    setParams({ ...params, currentPage: page })
  }
  // update the current page based on the calculated scroll position
  useEffect(() => {
    if (viewer.current) {
      viewer.current.addEventListener('scroll', updatePageFromScroll)
      return () => {
        viewer.current?.removeEventListener('scroll', updatePageFromScroll)
      }
    }
  }, [viewer.current, params])

  //----------------------------
  // This is a workaround to fix the issue of the pdf not rendering correctly when
  // user navigates from the current tab and then back to it
  useLayoutEffect(() => {
    const change = (e) => {
      if (document.hidden) {
      } else {
        const zoom = parseFloat(localStorage.getItem('refreshZoom') || 1)
        controller.setZoom(zoom + 0.01)
        setTimeout(() => {
          controller.setZoom(zoom)
        }, 100)
      }
    }

    document.addEventListener('visibilitychange', change)
    return () => {
      document?.removeEventListener('visibilitychange', change)
    }
  }, [])
  //----------------------------

  useLayoutEffect(() => {
    updatePageFromScroll()
  }, [params.zoom])

  useEffect(() => {
    controller.setZoom(isMobileDevice ? 0.75 : 1.25)
  }, [isMobileDevice])

  const controller = {
    params,
    appController,
    setZoom: (zoom) => {
      setParams((params) => ({ ...params, zoom }))
      localStorage.setItem('refreshZoom', zoom)
    },
    setRotate: (rotate) => {
      setParams((params) => ({ ...params, rotate }))
    },
    gotoPage: (page) => {
      setParams((params) => ({ ...params, currentPage: page }))
      viewer.current.scrollTo(
        0,
        (page - 1) * pageHeight * controller.params.zoom + page * 8 + 20,
      )
    },
    toggleMinimap: (visible) => {
      setParams((params) => ({ ...params, minimap: visible }))
    },
  }

  const [passwordCallback, setPasswordCallback] = useState(null)

  const onPassword = (callback, reason) => {
    //if the viewer has received a password from the server, use it
    if (password) {
      callback(password)
      return
    }
    const callbackProxy = (documentPassword) => {
      // Cancel button handler
      if (documentPassword === null) {
        // Reset your `document` in `state`, un-mount your `<Document />`, show custom message, whatever
      }

      //either pass the password from the server or the one that came from the password dialog
      callback(documentPassword)
    }
    setPasswordCallback({ callbackProxy })

    switch (reason) {
      case PasswordResponses.NEED_PASSWORD: {
        setPromptForPassword(true)
        break
      }
      case PasswordResponses.INCORRECT_PASSWORD: {
        setPasswordIncorrect(true)
        break
      }
      default:
    }
  }

  if (isMobileDevice === undefined) return

  return (
    <div className="w-full h-full relative bg-canvas">
      <div className="w-full h-full ">
        {isMobileDevice ? (
          <div>
            <ToolBar controller={controller} />
            <MiniMap controller={controller} file={input} />
            <FileInfoPanel
              controller={controller}
              filename={filename}
              fileSize={fileSize}
            />
          </div>
        ) : (
          <div>
            <ToolBar controller={controller} />
            <MiniMap controller={controller} file={input} />
            <FileInfoPanel
              controller={controller}
              filename={filename}
              fileSize={fileSize}
            />
          </div>
        )}
      </div>

      <PasswordPanel
        documentName={filename}
        onPasswordSubmit={async (password) => {
          passwordCallback?.callbackProxy(password)
        }}
        onCloseRequested={() => {
          //reset the order if the user abandons the file
          setPromptForPassword(false)
          setPasswordIncorrect(false)
          controller.appController.newOrder()
        }}
        passwordIncorrect={passwordIncorrect}
        isOpen={promptForPassword}
      />

      <motion.div
        ref={viewer}
        className="w-full absolute top-0 scrollbar scrollbar-thin scrollbar-thumb-neutral_1 scrollbar-track-canvas overflow-y-scroll h-full flex justify-center m-0"
        animate={{ paddingLeft: params.minimap ? 200 : 0 }}
        transition={{ duration: 0.5 }} // Adjust duration as needed
      >
        {isLoading && <Spinner size="20" className="fixed top-1/2 left-1/2" />}

        <Document
          file={input}
          onLoadSuccess={onDocumentLoadSuccess}
          onPassword={onPassword}
        >
          <div className="flex flex-col gap-2 pt-[120px] pb-[60px]">
            {Array.from(new Array(numPages), (el, index) => (
              <div key={`page_${index + 1}`}>
                <motion.div
                  whileHover={{
                    scale: 1.02,
                    //add shadow
                    boxShadow: '0px 0px 8px 2px rgba(0,0,0,0.1)',
                    zIndex: 2,
                    position: 'relative',
                  }} // Scale animation on hover
                >
                  <Page
                    scale={params.zoom}
                    rotate={params.rotate}
                    key={`page_${index + 1}`}
                    pageNumber={index + 1}
                    height={pageHeight}
                  />
                </motion.div>
                <div className="w-full" key={index} />
              </div>
            ))}
          </div>
        </Document>
      </motion.div>
    </div>
  )
}

export default PDFViewer
