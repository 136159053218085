import React, { useEffect, useState } from 'react'
import Button from '../Button'
import Typography from '../Typography'
import { useTranslation } from '../../utils/localization'
import Dropdown from '../Dropdown'
//import hero icon arrow left and right for the previous and next buttons
import { ArrowLeftIcon, ArrowRightIcon } from '@heroicons/react/24/outline'

const PaginationControlsDesktop = ({
  rowsPerPage,
  onRowsPerPageChange,
  startIndex,
  endIndex,
  totalItems,
  onPageChange,
  page,
  totalPages,
}) => {
  const t = useTranslation()

  return (
    <div className="flex justify-between items-center mt-4">
      <div className="flex flex-row w-[150px] items-center">
        <Dropdown
          menuPosition="top"
          className={'w-[150px] mx-2'}
          value={rowsPerPage}
          options={[10, 20, 50]}
          onChange={onRowsPerPageChange}
        />
      </div>
      {/* TODO: add Typography and localization */}
      <Typography className="text-text">
        {startIndex + 1}–{endIndex} {t`of`} {totalItems}{' '}
      </Typography>

      <div className="ml-2 flex flex-row">
        {/* Previous page button */}
        <Button
          onClick={() => onPageChange(Math.max(page - 1, 1))}
          disabled={page === 1}
          variant="viewer_controls"
          className="flex-1/2 mr-3 font-medium"
        >
          <ArrowLeftIcon className="w-5 h-5" />
        </Button>

        {/* Next page button */}
        <Button
          onClick={() => onPageChange(Math.min(page + 1, totalPages))}
          disabled={page === totalPages}
          variant="viewer_controls"
          className="flex-1/2 font-medium"
        >
          <ArrowRightIcon className="w-5 h-5" />
        </Button>
      </div>
    </div>
  )
}

export default PaginationControlsDesktop
