import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

const OnKey = (key, action) => {
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === key) action()
    }

    // Add event listener on mount
    window.addEventListener('keydown', handleKeyDown)

    // Clean up event listener on unmount
    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [key, action])
}

OnKey.propTypes = {
  key: PropTypes.string.isRequired,
  action: PropTypes.func.isRequired,
}

export default OnKey
