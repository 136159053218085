// useFetchSupportedLanguages.js
import { useEffect } from 'react'
import { useSetRecoilState } from 'recoil'
import { supportedTranslationLanguagesAtom } from '../atoms/languages'
import PTRestAPI from './pt_rest_api'
import { usePromise } from './usePromise'

const useFetchSupportedLanguages = () => {
  const setSupportedLanguages = useSetRecoilState(
    supportedTranslationLanguagesAtom,
  )

  //write as getPromise
  const { result: languages } = usePromise(
    async () => {
      const languages = await PTRestAPI.GetSupportedTranslationLanguages()
      return languages
    },
    false,
    false,
  )

  useEffect(() => {
    if (languages) {
      setSupportedLanguages(languages)
    }
  }, [languages])
}

export default useFetchSupportedLanguages
