import { useState, useEffect } from 'react'
import { ChatBubbleLeftEllipsisIcon } from '@heroicons/react/24/outline'
import PubSub from 'pubsub-js'

export default function ChatButtonSmall() {
  const [unreadMessages, setUnreadMessages] = useState(0)

  useEffect(() => {
    const token = PubSub.subscribe('unread-messages', (_, count) => {
      setUnreadMessages(count)
    })

    return () => {
      PubSub.unsubscribe(token)
    }
  }, [])

  return (
    <div
      className="relative h-[35px] w-[35px] bg-red-500 text-white rounded-full shadow-lg cursor-pointer flex items-center justify-center transition-all duration-300 ease-in-out"
      style={{ outline: '2px solid white' }} // 3px white outline
      onClick={() => PubSub.publish('show-chat')}
    >
      {/* Support chat icon */}
      <ChatBubbleLeftEllipsisIcon className="w-6 h-6 text-white" />

      {/* Unread messages badge */}
      {unreadMessages > 0 && (
        <span className="absolute top-6 left-6 h-5 w-5 bg-red-600 text-white text-xs rounded-full flex items-center justify-center">
          {unreadMessages}
        </span>
      )}
    </div>
  )
}
