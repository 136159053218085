import React, { useState, useLayoutEffect, useEffect, useRef } from 'react'
import { motion } from 'framer-motion'
import html2canvas from 'html2canvas'
import isMobile from 'is-mobile'

function Page({ input, index, controller, onRenderComplete, renderIndex }) {
  const canvasRef = useRef(null)

  useEffect(() => {
    const renderCanvas = async () => {
      const canvas = canvasRef.current
      const content = document.querySelector(
        `.docx-wrapper > section.docx:nth-child(${index + 1})`,
      )
      if (content && canvas) {
        await html2canvas(content, {
          canvas,
          scale: 0.2,
        })
        onRenderComplete()
      }
    }

    if (renderIndex === index) renderCanvas()
  }, [input, index, onRenderComplete])

  return (
    <div
      className="relative mx-auto w-[180px] h-[calc(180px*1.3)] shadow-lg"
      onClick={() => {
        controller.gotoPage(index + 1)
      }}
    >
      <canvas ref={canvasRef} width={180} height={234} />
    </div>
  )
}

const MiniMap = ({ input, controller }) => {
  const [numPages, setNumPages] = useState(0)
  const [renderQueue, setRenderQueue] = useState([])
  const [currentRenderIndex, setCurrentRenderIndex] = useState(0)
  const renderQueueRef = useRef([])
  const [isMobileDevice, setIsMobileDevice] = useState(undefined)

  useEffect(() => {
    // Get the number of pages by counting the number of sections
    const pages = document.querySelectorAll('.docx-wrapper>section.docx')
    setNumPages(pages.length)
    const initialRenderQueue = Array.from({ length: pages.length }).map(
      (_, idx) => idx,
    )
    setRenderQueue(initialRenderQueue)
    renderQueueRef.current = initialRenderQueue
    setCurrentRenderIndex(0) // Start with the first page
  }, [input, controller.params.minimap])

  const handleRenderComplete = () => {
    const nextIndex = currentRenderIndex + 1
    if (nextIndex < renderQueueRef.current.length) {
      setCurrentRenderIndex(nextIndex)
    }
  }

  useEffect(() => {
    setIsMobileDevice(isMobile())
  }, [])

  if (isMobileDevice === undefined) return null

  const desktopStyle = {
    top: '0px',
    left: 0,
    width: '200px',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
    zIndex: 19,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  }
  const desktopClassName =
    'bg-neutral_3 relative h-[calc(100vh-125px)] overflow-hidden'

  const mobileStyle = {
    top: '100px',
    left: 0,
    width: '200px',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
    zIndex: 19,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  }
  const mobileClassName =
    'bg-paper absolute h-[calc(100%-160px)] overflow-hidden'

  return (
    controller.params.minimap && (
      <motion.div
        initial={{ x: -200 }}
        animate={{
          x: controller.params.minimap ? 0 : -200,
        }}
        transition={{ duration: 0.5 }}
        className={isMobileDevice ? mobileClassName : desktopClassName}
        style={isMobileDevice ? mobileStyle : desktopStyle}
      >
        <div className="h-full py-10 flex flex-col w-full overflow-y-auto hide-scrollbar">
          <div className="flex flex-col gap-5">
            {Array.from({ length: numPages }).map((_, index) => (
              <div key={index}>
                <Page
                  index={index}
                  input={input}
                  controller={controller}
                  onRenderComplete={handleRenderComplete}
                  renderIndex={currentRenderIndex}
                />

                <div className="text-sm text-text py-2 text-text text-white text-center">
                  {index + 1}
                </div>
              </div>
            ))}
          </div>
        </div>
      </motion.div>
    )
  )
}

export default MiniMap
