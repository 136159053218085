import axios from 'axios'
import { useEffect } from 'react'
import { usePageContext } from 'vike-react/usePageContext'
import { navigate } from 'vike/client/router'
import Button from '../../components/Button/index.jsx'
import { Spinner } from '../../components/Spinner/index.jsx'
import Typography from '../../components/Typography'
import { useTranslation } from '../../utils/localization.js'
import { useAuth, useRefreshAccount } from '../../utils/platform_auth.jsx'
import PTRestAPI from '../../utils/pt_rest_api.jsx'
import { getApiUrl, SEARCH_QUERY_FLAGS } from '../../utils/routing.js'
import { useSearchParamValue } from '../../utils/searchParams.js'
import { sleep } from '../../utils/time.js'
import { usePromise } from '../../utils/usePromise.js'

export default function Success() {
  const pi = useSearchParamValue(SEARCH_QUERY_FLAGS.PAYMENT_INTENT)

  if (!pi) return <SuccessSubscribe />

  return <SuccessPurchase intent={pi} />
}

function SuccessSubscribe() {
  const t = useTranslation()
  const context = usePageContext()
  const redirectTo = useSearchParamValue(SEARCH_QUERY_FLAGS.RETURN_TO)
  const refreshAccount = useRefreshAccount()
  const auth = useAuth()

  const { result, pending, execute } = usePromise(
    async () => {
      await sleep(2000)
      return await PTRestAPI.GetUserSubscriptions(auth)
    },
    true,
    false,
  )

  useEffect(() => {
    if (!pending && !result) {
      execute()
      return
    }

    //if the purchase is successful, refresh the user account and track the purchase
    if (result?.length > 0) {
      refreshAccount()
    }
  }, [result, pending])

  const text =
    result?.length > 0
      ? t`Thank you for your purchase!`
      : t(`We're processing your purchase...`)

  return (
    <div className="flex w-full h-[50vh] justify-center items-center">
      <div className="flex flex-col justify-center items-center gap-8">
        {!result?.length && <Spinner className="mb-4" />}
        <Typography variant="h1">{t(text)}</Typography>
        <Button
          variant="action"
          onClick={() => {
            //navigate to the return to page
            navigate(redirectTo ?? context.urlPathname)
          }}
        >
          {t`Continue`}
        </Button>
      </div>
    </div>
  )
}

function SuccessPurchase({ intent }) {
  const t = useTranslation()
  const context = usePageContext()
  const redirectTo = useSearchParamValue(SEARCH_QUERY_FLAGS.RETURN_TO)
  const refreshAccount = useRefreshAccount()
  const apiUrl = getApiUrl()
  const auth = useAuth()

  const { result, pending, execute, reset } = usePromise(
    async () => {
      const response = await axios.post(
        `${apiUrl}/payment-gateway/check-transaction`,
        { credentials: auth, id: intent },
      )
      return response.data
    },
    true,
    false,
  )

  useEffect(() => {
    if (!pending && !result) {
      execute()
      return
    }

    if (result && !result.completed) {
      //wait a bit and retry
      setTimeout(() => reset(), 1000)
      return
    }

    //if the purchase is successful, refresh the user account and track the purchase
    if (result?.completed) {
      refreshAccount()
    }
  }, [result, pending])

  const text = result?.completed
    ? t('Thank you for your purchase!')
    : t(`We're processing your purchase...`)

  return (
    <div className="flex w-full h-[50vh] justify-center items-center">
      <div className="flex flex-col justify-center items-center gap-8">
        <h3 className="text-center text-3xl text-text">{text}</h3>
        {!result?.completed && <Spinner className="mb-4" />}

        {result?.completed && (
          <Button
            variant="action"
            onClick={() => {
              //navigate to the return to page
              navigate(redirectTo ?? context.urlPathname)
            }}
          >
            {t`Continue`}
          </Button>
        )}
      </div>
    </div>
  )
}
