import React, { useState, lazy, Suspense, useEffect } from 'react'
import Modal from '../Modal'
import Button from '../Button'
import Typography from '../Typography'
import { useTranslation } from '../../utils/localization'
import PTRestAPI from '../../utils/pt_rest_api'

// Lazy load the ToggleSwitch component
import ToggleSwitch from '../ToggleSwitch'
import { showNotification } from '../Notification'

const ContactPanel = ({ isOpen, onCloseRequested }) => {
  const t = useTranslation()

  const [questionType, setQuestionType] = useState('')
  const [message, setMessage] = useState('')
  const [email, setEmail] = useState('')
  const [agreed, setAgreed] = useState(false)
  const [file, setFile] = useState(null)

  const [errors, setErrors] = useState({})
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [submitError, setSubmitError] = useState(null)

  //reset the form when the modal is closed
  useEffect(() => {
    if (!isOpen) {
      setQuestionType('')
      setMessage('')
      setEmail('')
      setAgreed(false)
      setFile(null)
      setErrors({})
      setSubmitError(null)
      setIsSubmitting(false)
    }
  }, [isOpen])

  const handleQuestionTypeChange = (e) => {
    setQuestionType(e.target.value)
  }

  const handleMessageChange = (e) => {
    setMessage(e.target.value)
  }

  const handleEmailChange = (e) => {
    setEmail(e.target.value)
  }

  const handleAgreementChange = (value) => {
    setAgreed(value)
  }

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0]
    setFile(selectedFile)
  }

  const validateForm = () => {
    const errors = {}
    if (!questionType) errors.questionType = t`Please select a question type.`
    if (!message) errors.message = t`Please enter a message.`
    if (!email) errors.email = t`Please enter an email.`
    if (!agreed) errors.agreed = t`You must agree to the terms.`

    //if there is a file and it's more than 20MB, show an error
    if (file && file.size > 20 * 1024 * 1024) {
      errors.file = t`File size must be less than 20MB.`
    }

    return errors
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    e.stopPropagation()
    setSubmitError(null)
    const validationErrors = validateForm()
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors)
      return
    }
    setErrors({})

    setIsSubmitting(true)
    try {
      const payload = {
        questionType,
        message,
        email,
        file,
      }

      const response = await PTRestAPI.SendUserSiteContact(payload)

      if (response.error) {
        setSubmitError(t(`Server error: %error%`, response.error))
        return
      }

      if (response.result) {
        showNotification({
          message: `Thank you! We'll review your message immediately.`,
          className: 'bg-primary text-text_inv',
        })
      }

      // Handle successful submission (e.g., close the modal, show a success message)
      onCloseRequested()
    } catch (error) {
      setSubmitError(
        t`An error occurred while submitting your message. Please try again.`,
      )
    }
    setIsSubmitting(false)
  }

  return (
    <Modal
      isOpen={isOpen}
      onCloseRequested={onCloseRequested}
      className="sm:w-[500px] sm:max-w-[700px] h-full md:h-auto text-sm sm:text-base"
      zIndex={410}
      title={t`Contact Us`}
    >
      <div>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label
              className={`block font-bold mb-2 ${
                errors.questionType ? 'text-error' : 'text-text_secondary'
              }`}
            >
              {t`Ask a Question`}
            </label>
            <select
              value={questionType}
              onChange={handleQuestionTypeChange}
              className={`text-text bg-neutral_1 border ${
                errors.questionType ? 'border-error' : 'border-bordered'
              } rounded p-2 w-full`}
            >
              <option value="">{t`Select an option`}</option>
              <option value="report_issue">{t`Report an Issue`}</option>
              <option value="ask_question">{t`Ask a Question`}</option>
              <option value="suggest_feature">{t`Suggest a Feature`}</option>
              <option value="contact_sales">{t`Contact Sales`}</option>
            </select>
          </div>

          <div className="mb-4">
            <label
              className={`block font-bold mb-2 ${
                errors.message ? 'text-error' : 'text-text_secondary'
              }`}
            >
              {t`Message`}
            </label>
            <textarea
              value={message}
              onChange={handleMessageChange}
              className={`outline-none text-text bg-neutral_2 border ${
                errors.message ? 'border-error' : 'border-bordered'
              } rounded p-2 w-full`}
              rows="4"
            ></textarea>
          </div>

          <div className="mb-4">
            <label
              className={`block font-bold mb-2 ${
                errors.email ? 'text-error' : 'text-text_secondary'
              }`}
            >
              {t`Email`}
            </label>
            <input
              type="email"
              value={email}
              onChange={handleEmailChange}
              className={`outline-none text-text bg-neutral_2 border ${
                errors.email ? 'border-error' : 'border-bordered'
              } rounded p-2 w-full`}
            />
          </div>

          <div className="mb-4">
            <label className="block font-bold text-text_secondary mb-2">
              {t`Attach File (optional)`}
            </label>
            <input
              type="file"
              onChange={handleFileChange}
              className="text-text bg-neutral_2 border border-bordered rounded p-2 w-full"
            />
            {errors.file && (
              <p className="text-error text-sm mt-2">{errors.file}</p>
            )}
          </div>

          <div className="mt-8 mb-6">
            <div className="flex items-center gap-4 text-text_secondary">
              <ToggleSwitch isOn={agreed} onChange={handleAgreementChange} />
              <Typography>
                {t`I agree to the`}{' '}
                <a href="/terms-of-use" className="text-link">
                  {t`Terms of Service`}
                </a>{' '}
                {t`and`}{' '}
                <a href="/privacy-policy" className="text-link">
                  {t`Privacy Policy`}
                </a>
              </Typography>
            </div>
            <div
              className={`${
                errors.agreed ? 'visible' : 'invisible'
              } w-full mt-2 h-0.5 bg-error`}
            ></div>
          </div>
          <div className="mb-4">
            <Button
              type="submit"
              variant="action"
              className="w-full"
              disabled={isSubmitting}
            >
              {isSubmitting ? t`Sending...` : t`Send`}
            </Button>
            {Object.keys(errors).length > 0 && (
              <p className="text-error mb-4 text-center">
                {t`Please correct the highlighted fields above.`}
              </p>
            )}
            {submitError && <p className="text-error">{submitError}</p>}
          </div>
        </form>
        <Typography className="w-full text-center text-sm text-text_secondary">
          {t`All files are treated confidentially.`}
        </Typography>
      </div>
    </Modal>
  )
}

export default ContactPanel
