import { CheckIcon } from '@heroicons/react/24/outline'
import { ChevronLeftIcon } from '@heroicons/react/24/solid'
import { useState } from 'react'
import {
  useCurrencyTranslation,
  useTranslation
} from '../../../utils/localization.js'
import { Coupon } from '../../Coupon/index.jsx'
import { Link } from '../../Link/index.jsx'
import PaymentController from '../../PaymentController/index.jsx'
import Typography from '../../Typography/index.jsx'

//TODO: Add the real bullets (current code is only for representation purposes)
export function Bullet({ text }) {
  return (
    <div className="flex items-start justify-start items-center mb-2">
      <CheckIcon className="mr-2 pb-1 w-6 text-text_secondary" />
      <Typography className="text-base text-text">{text}</Typography>
    </div>
  )
}

export default function PaymentDesktop({ pid, onCancel }) {
  const t = useTranslation()
  const { localizedCost } = useCurrencyTranslation()
  const [originalPrice, setOriginalPrice] = useState()

  //get the Checkout component and meaningful purchase data
  const {
    paymentInfo,
    setPaymentInfo,
    productData,
    currency,
    Checkout,
  } = PaymentController({
    pid,
    type: 'subscription',
  })

  //----------------------------------------------------------
  //Produce Details
  //----------------------------------------------------------
  const product = productData && productData[0]
  const titleText = t`Activate Your Plan`

  return (
    <div className="flex flex-col w-full">
      <div className="absolute">
        <button
          className="absolute top-[-16px] left-[-10px]"
          onClick={onCancel}
        >
          <ChevronLeftIcon className="w-7 h-7 text-text" />
        </button>
      </div>
      {/* ----- Left and right columns -----*/}
      <div className="flex flex-col md:flex-row items-center justify-between w-full gap-10 px-4 md:px-10 lg:px-36 pt-1 space-y-5 md:space-y-0">
        {/* ----- Left column -----*/}
        <div className="w-full max-w-lg md:h-[505px] flex flex-col justify-between space-y-0 md:pt-[60px] lg:pt-[73px] lg:pt-0">
          <Typography variant="h1">{titleText}</Typography>

          {paymentInfo && (
            <div className="flex flex-col w-full">
              <div className="flex flex-row items-center justify-between w-full">
                <Typography className="text-2xl text-text m-0">
                  {product?.name}
                </Typography>
                <div>
                  <Typography className="text-2xl text-text m-0">
                    {localizedCost(paymentInfo?.cost, currency, 100)}
                  </Typography>

                  {originalPrice && (
                    <Typography className="text-lg text-text line-through m-0">
                      {localizedCost(originalPrice, currency, 100)}
                    </Typography>
                  )}
                </div>
              </div>
            </div>
          )}
          {/* -----Divider----- */}
          <div className="w-full sm:w-90 my-6 border-t border-bordered" />
          {/* -----Bullets----- */}
          <div className="pl-2 lg:pl-4">
            {product?.description?.split('\n')?.map((text, i) => (
              <Bullet key={i} text={text} />
            ))}
          </div>
          <Coupon
            subscription={paymentInfo?.subscription}
            onCouponApplied={(updatedPaymentInfo) => {
              //store the original price
              setOriginalPrice(paymentInfo.cost)
              //set the updated payment info
              setPaymentInfo(updatedPaymentInfo)
            }}
          />
        </div>
        {/* ----- Right column -----*/}
        <div className="max-w-[550px] w-full flex flex-col items-start">
          <div className="max-w-[550px] w-full">
            {/* The product checkout */}
            <Checkout />
          </div>
        </div>
      </div>
      {/* Privacy Policy notice */}
      <div className="flex items-center justify-center w-full h-full mt-10">
        <Typography className="text-neutral_2 text-sm text-center">
          {t`For more info of how we process your personal data, please read our`}
          <Link
            to="/privacy-policy"
            className="text-link hover:text-primary_lt"
          >
            {' ' + t`Privacy policy`}
          </Link>
          .
        </Typography>
      </div>
    </div>
  )
}
