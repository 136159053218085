export default function Typography({variant, color, align, className, children}) {
  switch (align) {
    case 'center':
      className = "text-center " + className
      break
    case 'right':
      className = "text-right " + className
      break
    case 'left':
    default:
      className = "text-left " + className
      break
  }
  switch (color) {
    case 'primary':
      className = "text-primary_inv " + className
      break
    case 'error':
      className = "text-theme-error " + className
      break
    default:
      break
  }
  switch (variant) {
    case 'title':
      return <h1 className={"text-4xl sm:text-[46px] md:lg:text-[50px] lg:text-[54px] font-extrabold text-text " +className}>{children}</h1>
    case 'h1':
      return <h1 className={"font-title font-extrabold text-text text-2xl sm:text-3xl lg:text-4xl " +className}>{children}</h1>
    case 'h2':
      return <h2 className={"font-title text-text uppercase font-bold text-lg sm:text-xl lg:text-2xl " +className}>{children}</h2>
    case 'h3':
      return <h3 className={"font-title text-text font-bold text-lg sm:text-xl lg:text-2xl " +className}>{children}</h3>
    case 'h4':
      return <h4 className={"font-title text-text font-semibold sm:text-lg lg:text-xl " +className}>{children}</h4>
    case 'subtitle':
      return <h5 className={"font-title text-text font-semibold sm:text-md lg:text-lg " +className}>{children}</h5>
    case 'paragraph':
      return <p className={"text-text font-normal text-sm sm:text-base " +className}>{children}</p>
    default:
      return <div className={"" +className}>{children}</div> /* All definitions are assigned per case */
  }

}
