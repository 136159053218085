import React, {
  useState,
  useEffect,
  useRef,
  forwardRef,
  useImperativeHandle,
} from 'react'
import { motion } from 'framer-motion'
import { createPortal } from 'react-dom'

const Notification = forwardRef((props, ref) => {
  const [notification, setNotification] = useState({
    message: '',
    className: '',
  })
  const [isVisible, setIsVisible] = useState(false)
  const timeoutRef = useRef(null)

  const [isClient, setIsClient] = useState(false)

  useEffect(() => {
    // Only turn on client-specific rendering after mounting
    setIsClient(true)
  }, [])

  const show = ({ message, className = '' }) => {
    setNotification({ message, className })
    setIsVisible(true)

    clearTimeout(timeoutRef.current)
    timeoutRef.current = setTimeout(() => {
      setIsVisible(false)
    }, 5000) // Auto-hide after 5 seconds
  }

  const closeNotification = () => {
    setIsVisible(false)
  }

  useImperativeHandle(ref, () => ({
    show,
  }))

  useEffect(() => {
    return () => {
      clearTimeout(timeoutRef.current)
    }
  }, [])

  return isClient
    ? createPortal(
        <div>
          {isVisible && (
            <div
              className="fixed top-4 left-0 w-full flex justify-center"
              style={{ zIndex: 1000 }}
            >
              <motion.div
                className={`fixed top-10 mx-auto transform -translate-x-1/2 bg-blue-500 text-white p-4 rounded shadow-lg flex items-center ${notification.className}`}
                initial={{ y: -20 }}
                animate={{ y: 0 }}
                exit={{ y: -20 }}
                transition={{ duration: 0.3 }}
              >
                <span>{notification.message}</span>
                <button
                  className="ml-4 text-white focus:outline-none"
                  onClick={closeNotification}
                >
                  ×
                </button>
              </motion.div>
            </div>
          )}
        </div>,
        document.body,
      )
    : null
})

// Create a singleton instance of the notification component
let notificationInstance = null

export const showNotification = (config) => {
  if (notificationInstance) {
    notificationInstance.show(config)
  }
}

// Higher-order component to inject the notification instance
const NotificationProvider = () => {
  const notificationRef = useRef()

  useEffect(() => {
    notificationInstance = notificationRef.current
  }, [])

  return <Notification ref={notificationRef} />
}

export default NotificationProvider
