//When redirecting with the Navigation service, it will show a confirmation dialog before redirecting.
import { useEffect } from 'react'
import Navigation from './navigation'
import { showPromptDialog } from '../components/PromptDialog'
export default () => {
  useEffect(() => {
    Navigation.onPrompt = (prompt) => {
      if (!prompt) return
      showPromptDialog({
        title: Navigation?.prompt.title,
        message: Navigation?.prompt.message,
        onConfirm: Navigation?.confirmNavigation,
        onCancel: Navigation?.cancelNavigation,
      })
    }
  }, [])
}
