import React, { useEffect, useState } from 'react'
import { useRecoilState } from 'recoil'
import { navigate } from 'vike/client/router'
import { useTranslation } from '../../utils/localization'
import { userPreferencesSelector } from '../../utils/platform_auth'
import { SEARCH_QUERY_FLAGS } from '../../utils/routing'
import { useSearchParamValue } from '../../utils/searchParams'
import { useSearchParamMutation } from '../../utils/searchParams.js'
import useThemeController from '../../utils/useThemeController'
import Dropdown from '../Dropdown'
import ToggleSwitch from '../ToggleSwitch'
import Typography from '../Typography'

const PreferencesPanel = () => {
  const t = useTranslation()
  const [currentGroup, setCurrentGroup] = useState(0) // Track the current control group
  const controller = useThemeController()
  const [changeFlag, setChangeFlag] = useState(false)
  const refresh = () => setChangeFlag(!changeFlag)
  const [controlGroups, setControlGroups] = useState([])
  const [userPreferences, setUserPreferences] = useRecoilState(
    userPreferencesSelector,
  )

  const [localPrefs, setLocalPrefs] = useState({ userChange: false })

  const groupParam = useSearchParamValue('group')
  const { add: addSearchParam } = useSearchParamMutation()

  //update the search param based on the current group
  useEffect(() => {
    if (controlGroups.length === 0) return
    //navigate to the current group
    navigate(
      addSearchParam(
        SEARCH_QUERY_FLAGS.PREFERENCES_GROUP,
        controlGroups[currentGroup].key,
      ),
    )
  }, [currentGroup, controlGroups])

  // Set the current group based on the URL query parameter
  useEffect(() => {
    if (controlGroups.length === 0 || !groupParam) return

    const groupIndex = controlGroups.findIndex(
      (group) => group.key === groupParam,
    )
    //set if found
    if (groupIndex !== -1) setCurrentGroup(groupIndex)
  }, [groupParam, controlGroups])

  //update the local prefs
  useEffect(() => {
    //only on first load
    if (localPrefs.userChange !== false) return
    setLocalPrefs(userPreferences)
  }, [userPreferences])

  //update the remote prefs
  useEffect(() => {
    //only on user changes will update the remote prefs
    if (!localPrefs.userChange) return
    const payload = { ...localPrefs }
    delete payload.userChange
    setUserPreferences(payload)
  }, [localPrefs])

  //Controls
  useEffect(() => {
    setControlGroups([
      // Define your control groups
      {
        title: t('Theme Mode'),
        key: 'theme',
        controls: [
          {
            label: 'Theme',
            type: 'select',
            options: ['light', 'dark'],
            onChange: (value) => {
              // Handle theme change
              controller.setTheme(value.toLowerCase())
              refresh()
            },
            get: () => {
              return controller.getTheme()
            },
          },
        ],
      },
      {
        title: 'Notifications',
        key: 'notifications',
        controls: [
          {
            label: 'Get an email when you have unread support chat messages',
            type: 'checkbox',
            onChange: (value) => {
              // Handle email notification change
              setLocalPrefs({
                ...localPrefs,
                chat_notify_email: value,
                //mark the user change
                userChange: true,
              })
            },
            get: () => {
              return localPrefs.chat_notify_email
            },
          },
        ],
      },
      {
        /*}
      {
        title: 'Language',
        controls: [
          {
            label: 'App Language',
            type: 'select',
            options: [],
            onChange: (value) => {
              refresh()
              // Handle language change
            },
            get: () => {
              return 'English'
            },
          },
        ],
      },
      // Add more control groups as needed
    */
      },
    ])
  }, [localPrefs])

  // Function to handle changing the current group
  const changeGroup = (index) => {
    setCurrentGroup(index)
  }

  return (
    <div className="flex sm:m-6 md:m-12 mt-[120px]">
      {/* Control group titles as horizontal menu */}
      <div className="w-[160px] border-r border-bordered text-text pl-[12px] sm:pl-0">
        <Typography variant="h4">{t`Preferences`}</Typography>

        <ul className="">
          {controlGroups?.map((group, index) => (
            <li key={index}>
              <button
                className={` text-sm md:text-base hover:text-link py-2 cursor-pointer ${
                  index === currentGroup ? 'font-medium text-link' : ''
                }`}
                onClick={() => changeGroup(index)}
              >
                {group.title}
              </button>
            </li>
          ))}
        </ul>
      </div>

      {/* Current control group contents */}
      <div className="flex flex-col ml-6 md:ml-12 gap-2">
        <div className="mb-[8px]">
          <label className="text-text text-lg font-bold mr-2">
            {controlGroups[currentGroup]?.title}
          </label>
        </div>

        {controlGroups[currentGroup]?.controls?.map((control, index) => (
          <div
            key={index}
            className="mb-4 flex flex-col md:flex-row gap-4 md:gap-0 md:items-center"
          >
            <label className="text-sm md:text-base text-text_secondary mr-2 min-w-[160px]">
              {control.label}
            </label>

            <div className="min-w-[150px]">
              {control.type === 'checkbox' && (
                <ToggleSwitch
                  isOn={control.get()}
                  onChange={control.onChange}
                />
              )}
              {control.type === 'select' && (
                <Dropdown
                  value={control.get()}
                  options={control.options}
                  onChange={control.onChange}
                />
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default PreferencesPanel
