/**
 * This component is responsible for managing the payment process.
 */
import {
  Elements,
  PaymentElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import axios from 'axios'
import PubSub from 'pubsub-js'
import React, { useEffect, useState } from 'react'
import { useRecoilValue } from 'recoil'
import { usePageContext } from 'vike-react/usePageContext'
import { navigate } from 'vike/client/router'
import { STRIPE_API_KEY } from '../../config.js'
import { useTranslation } from '../../utils/localization'
import { currencyAtom, useAuth } from '../../utils/platform_auth'
import PTRestAPI from '../../utils/pt_rest_api'
import {
  getActualDomainUrl,
  getApiUrl,
  SEARCH_QUERY_FLAGS,
} from '../../utils/routing'
import { searchParamsToString } from '../../utils/searchParams'
import { usePromise } from '../../utils/usePromise'
import theme from '../../utils/useThemeController'
import Button from '../Button'
import PaymentSkeleton from '../PaymentSkeleton'
import Typography from '../Typography/index.jsx'

const stripePromise = loadStripe(STRIPE_API_KEY)

//Checkout component
function Checkout({ paymentInfo, type, pid }) {
  const stripe = useStripe()
  const elements = useElements()
  const pageContext = usePageContext()
  const [error, setError] = useState()
  const [loading, setLoading] = useState(false)
  const [ready, setReady] = useState(false)

  const domain = getActualDomainUrl()
  const t = useTranslation()

  if (!paymentInfo) return <PaymentSkeleton />
  const { payment } = paymentInfo

  // console.log(pageContext?.urlPathname + pageContext?.urlSearch);
  async function confirm() {
    setLoading(true)
    let successUrl = pageContext.urlPathname + `?${SEARCH_QUERY_FLAGS.SUCCESS}`
    //add the payment intent to the success url and redirect payload
    if (type === 'credits') {
      successUrl += `&${SEARCH_QUERY_FLAGS.PAYMENT_INTENT}=${payment}`
    }
    //set the return and remove the payment params
    successUrl += `&${SEARCH_QUERY_FLAGS.RETURN_TO}=${
      pageContext?.urlPathname +
      pageContext?.urlParsed.searchOriginal.replace(
        /&packages|\?packages|&plans|\?plans/,
        '',
      )
    }`

    try {
      const res = await stripe.confirmPayment({
        elements,
        redirect: 'if_required',
        confirmParams: {
          return_url: `${domain}/success`,
        },
      })

      if (res.paymentIntent && res.paymentIntent.status === 'succeeded') {
        //------------------------------------------------------------
        //track the purchase
        //------------------------------------------------------------
        const paymentIntent = res.paymentIntent
        const price = paymentIntent.amount / 100 //convert from cents to floats
        const { currency } = paymentIntent
        PubSub.publish('Tracking_Purchase', {
          price,
          //the real currency is the one used by the payment gateway
          currency,
          //the Aldoo Store product pid purchased: Ex: a coin pack
          pid,
        })
        //------------------------------------------------------------
        //navigate to the success page
        await navigate(successUrl)
      }

      if (res.error) setError(res.error.message)
    } catch (e) {
      console.log(e)
      setError(e.message)
    } finally {
      setLoading(false)
    }
  }

  return (
    <form>
      {stripe && elements && <PaymentElement onReady={() => setReady(true)} />}

      <div className="flex flex-col ">
        <Button
          variant="action"
          className={`mt-10 ${
            loading || !ready
              ? 'opacity-50 cursor-not-allowed'
              : 'hover:bg-primary-dark'
          }`}
          disabled={loading}
          onClick={confirm}
        >
          {loading || !ready ? 'Loading...' : t`Buy Now`}
        </Button>
      </div>
      {error && <p className="text-error">{error}</p>}
    </form>
  )
}

//Payment controller
/**
 * @param {string} pid - The product id from the Aldoo store
 * @param {string} type - The type of payment to be made: 'credits' or 'subscription'
 */
export default ({ pid, type }) => {
  const currency = useRecoilValue(currencyAtom)
  const apiUrl = getApiUrl()
  const themeID = theme().getTheme()
  const auth = useAuth()
  const [paymentInfo, setPaymentInfo] = useState()

  //----------------------------------------------
  //get the product data
  //----------------------------------------------
  const {
    result: productData,
    pending: productPending,
    execute: getProduct,
  } = usePromise(() => PTRestAPI.GetStoreProducts(auth, { pid }), true, false)

  //----------------------------------------------
  //initiate stripe payment
  //----------------------------------------------
  const stripeEndPoint = `${apiUrl}${
    type === 'credits'
      ? '/payment-gateway/initiate-product-payment'
      : '/payment-gateway/initiate-payment'
  }`
  const {
    result: productPaymentInfo,
    pending: payloadPending,
    execute: initiatePayment,
  } = usePromise(
    async () => {
      const response = await axios.post(stripeEndPoint, {
        pid,
        credentials: auth,
        currency,
      })
      return response.data
    },
    true,
    false,
  )
  //set the product payment info form the initial payment request
  //Note that coupons may alter the payment info via setPaymentInfo
  useEffect(() => {
    if (productPaymentInfo) {
      setPaymentInfo(productPaymentInfo)
    }
  }, [productPaymentInfo])

  useEffect(() => {
    if (auth) {
      getProduct()
      initiatePayment()
    }
  }, [auth])

  //----------------------------------------------
  //Register the product checkout
  //----------------------------------------------
  useEffect(() => {
    if (paymentInfo) {
      //get the product once stripe is ready
      PubSub.publish('Tracking_ViewContent', {
        pid,
        price: paymentInfo.cost,
        currency: paymentInfo.currency,
      })
    }
  }, [paymentInfo])

  //Wrap the checkout in stripe elements
  const StripeElements = ({ children }) => {
    const t = useTranslation()
    return (
      <div>
        <Typography
          variant="h4"
          className="mb-5 text-2xl text-center text-text"
        >
          {t`Payment Method`}
        </Typography>
        <Elements
          stripe={stripePromise}
          options={{
            clientSecret: paymentInfo?.clientSecret,
            loader: 'always',
            appearance: {
              variables: {
                colorDanger: themeID == 'dark' ? '#facc15' : '#df1b41',
                colorBackground: themeID == 'dark' ? '#090909' : '#f9f9f9',
                colorText: themeID == 'dark' ? 'white' : '#090909',
              },
            },
          }}
        >
          {children}
        </Elements>
      </div>
    )
  }

  //export the interface for the payment controller
  return {
    Checkout: () => (
      <StripeElements>
        <Checkout
          pid={pid}
          type={type}
          paymentInfo={paymentInfo}
          onReady={() => setReady(true)}
        />
      </StripeElements>
    ),
    //this method is exposed so coupons can alter the payment info
    setPaymentInfo,
    paymentInfo,
    productData,
    currency,
  }
}
