// src/components/Menu.js
import React from 'react'
import Typography from '../Typography/index.jsx'
import { useTranslation } from '../../utils/localization.js'

const DiscountBar = () => {
  const t = useTranslation()
  
  return (
    <div 
        className="w-full h-[64px] flex flex-row items-center justify-center gap-4 bg-[#9e2046] text-white py-8"
      >
        <img 
          src="/static/images/discount-icon.svg"
          className="h-[48px]"
        />
        <a 
          className="text-base sm:text-md md:text-lg font-extrabold underline cursor-pointer hover:no-underline"
          href="/?plans"
          
        >
          <Typography>
            {t`Save 40% on Premium Translation`}
          </Typography>
        </a>
      </div>
  )
}

export default DiscountBar
