import React from 'react'
import Button from '../Button'
import Typography from '../Typography'
import { useTranslation } from '../../utils/localization'
import { formatThousands } from '../../utils/localization'
import { navigate } from 'vike/client/router'
import { SEARCH_QUERY_FLAGS } from '../../utils/routing.js'
import { useSearchParamMutation } from '../../utils/searchParams.js'

const AddCreditsDesktop = ({ account, hasSubscription }) => {
  const t = useTranslation()
  const { add: addSearchParam } = useSearchParamMutation()

  return (
    <div>
      <div className="flex flex-row h-[50px] gap-6 items-center p-[5px] rounded">
        <div className="flex flex-row items-baseline gap-2 pl-2">
          <Typography className="text-2xl text-text font-black">
            {formatThousands(account?.coins)}
          </Typography>
          <Typography className="text-md text-text font-bold">
            {t`credits`}
          </Typography>
        </div>
        <Button
          variant="action"
          onClick={() =>
            navigate(
              addSearchParam(
                hasSubscription
                  ? SEARCH_QUERY_FLAGS.PACKAGES
                  : SEARCH_QUERY_FLAGS.PACKAGES,
              ),
            )
          }
        >
          {t`Add Credits`}
        </Button>
      </div>
    </div>
  )
}

export default AddCreditsDesktop
