import axios from 'axios';
import AldooAppMessageClient from './aldoo-app-message-client';
import { useRef, useState } from 'react';

export default function CustomerSupportController() {
  //this is a credentials object for the current local user
  //it contains a deviceID, userID, email
  const [appMessageClient, setAppMessageClient] = useState(null);
  const chatAPI = useRef(null);
  const localUserRef = useRef(null);
  const doPollingRef = useRef(null);

  const client = AldooAppMessageClient();

  const init = async ({ api, user }) => {
    if (!api) return;
    chatAPI.current = api;
    localUserRef.current = user;
    //connect the the chat api
    client.connect({
      localUser: localUserRef.current,
      //replace the v1/chat with v1/aam
      api: chatAPI.current.replace('/chat', '/aam')
    });
    //Create a new message client
    setAppMessageClient(client);
  };

  const isOnline = () => {
    return appMessageClient && appMessageClient.isConnected();
  };

  // Get the contacts of the user
  const getContacts = async (userID) => {
    if (!chatAPI.current) return null;
    const localUser = localUserRef.current;
    if (!localUser) return [];

    const res = await axios.post(chatAPI.current, {
      deviceID: localUser.deviceID,
      getContacts: {
        userID,
        deviceID: localUser.deviceID,
        email: localUser.email
      }
    });
    return res.data;
  };

  const sendFile = async ({ file, to, messageID, from, onProgress }) => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('to', to);
    formData.append('from', from);
    formData.append('messageID', messageID);
    formData.append('fileUpload', 1);

    //add the local user credentials to the form data
    const localUser = localUserRef.current;
    if (!localUser) return;
    formData.append('deviceID', localUser.deviceID);
    formData.append('email', localUser.email);

    const res = await axios.post(chatAPI.current, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      onUploadProgress: (progressEvent) => {
        const progress = Math.round(
          (progressEvent.loaded / progressEvent.total) * 100
        );
        if (onProgress) {
          onProgress(progress);
        }
      }
    });

    return res.data;
  };

  // Get the contacts of the user
  const getSupportUser = async (api) => {
    if (!api) api = chatAPI.current;
    if (!api) return null;

    try {
      const res = await axios.post(api, {
        getSupportUser: {
          userID: localUserRef.current?._id
        }
      });
      return res.data;
    } catch (error) {
      console.log('Error while getting support user:', error);
    }
    return null;
  };

  /**
   * Array of messages that contain the messageID and status
   * @param {*} param0
   * @returns
   */
  const setMessageStatus = async ({ from, to, messages, status }) => {
    if (!chatAPI.current) return null;
    const localUser = localUserRef.current;
    if (!localUserRef.current) return;

    const res = await axios.post(chatAPI.current, {
      deviceID: localUser.deviceID,
      setMessageStatus: {
        from,
        to,
        messages,
        status,
        deviceID: localUser.deviceID,
        email: localUser.email
      }
    });

    return res.data;
  };

  const sendTypingStatus = ({ to, from, typing }) => {
    if (!chatAPI.current) return null;

    appMessageClient.emit({
      to,
      event: 'user-is-typing',
      data: { typing, from }
    });
  };

  //payload is {message, data}
  const sendMessage = async ({ to, from, payload }) => {
    if (!chatAPI.current) return null;
    const localUser = localUserRef.current;
    if (!localUser) return;

    const res = await axios.post(chatAPI.current, {
      deviceID: localUser.deviceID,
      sendMessage: {
        to,
        from,
        payload,
        deviceID: localUser.deviceID,
        email: localUser.email
      }
    });

    return res.data;
  };

  const updateMessage = async (message) => {
    if (!chatAPI.current) return null;
    const localUser = localUserRef.current;
    if (!localUser) return;

    const messageID = message._id;
    const payload = message;

    const res = await axios.post(chatAPI.current, {
      deviceID: localUser.deviceID,
      updateMessage: {
        messageID,
        payload,
        deviceID: localUser.deviceID,
        email: localUser.email
      }
    });

    return res.data;
  };

  // Get the message history between two users
  const getMessageHistory = async ({
    from,
    to,
    page,
    pageSize,
    after,
    before
  }) => {
    if (!chatAPI.current) return null;
    const localUser = localUserRef.current;
    if (!localUser) return [];

    const res = await axios.post(chatAPI.current, {
      deviceID: localUser.deviceID,
      getMessageHistory: {
        from,
        to,
        page,
        pageSize,
        after,
        before,
        deviceID: localUser.deviceID,
        email: localUser.email
      }
    });

    return res.data;
  };

  const findContact = async ({ userID, userEmail }) => {
    if (!chatAPI.current) return null;
    const localUser = localUserRef.current;
    if (!localUser) return;
    const res = await axios.post(chatAPI.current, {
      deviceID: localUser.deviceID,
      findContact: {
        userID,
        userEmail,
        deviceID: localUser.deviceID,
        email: localUser.email
      }
    });

    return res.data;
  };

  const addContact = async ({ owner, contact }) => {
    if (!chatAPI.current) return null;
    const localUser = localUserRef.current;
    if (!localUser) return;

    const res = await axios.post(chatAPI.current, {
      deviceID: localUser.deviceID,
      addContact: {
        owner,
        contact,
        deviceID: localUser.deviceID,
        email: localUser.email
      }
    });

    return res.data;
  };

  const removeContact = async ({ owner, contact }) => {
    if (!chatAPI.current) return null;
    const localUser = localUserRef.current;
    if (!localUser) return;

    const res = await axios.post(chatAPI.current, {
      deviceID: localUser.deviceID,
      removeContact: {
        owner,
        contact,
        deviceID: localUser.deviceID,
        email: localUser.email
      }
    });

    return res.data;
  };

  const setContactState = async ({ user, contact, state }) => {
    if (!chatAPI.current) return null;
    const localUser = localUserRef.current;
    if (!localUser) return;

    const res = await axios.post(chatAPI.current, {
      deviceID: localUser.deviceID,
      setContactState: {
        userID: user,
        contact,
        state,
        deviceID: localUser.deviceID,
        email: localUser.email
      }
    });

    return res.data;
  };

  // Poll the server for new messages
  const listenForMessages = ({ from, to, onMessages, bypassPolling }) => {
    if (!chatAPI.current) return null;
    const localUser = localUserRef.current;
    if (!localUser) return;

    const intervalSeconds = 2;
    let lastMessageTime = null;
    let allMessages = [];
    let pollingInterval = null;

    const doPolling = async () => {
      //if true will now get the messages from the server
      //used to defined a condition like if the chat is not visible
      if (bypassPolling && bypassPolling()) return;
      try {
        const messageResponse = await getMessageHistory({
          from,
          to,
          page: 1,
          pageSize: 20,
          after: lastMessageTime
        });

        if (messageResponse.messages.length > 0) {
          lastMessageTime = messageResponse.messages[0].time; // Update the last message time
          const newMessages = messageResponse.messages.reverse(); // Reverse to show the latest at the bottom
          allMessages = [...allMessages, ...newMessages]; // Add new messages to allMessages

          // Call the onMessages callback with the new messages
          if (onMessages) {
            onMessages(newMessages);
          }
        }
      } catch (error) {
        console.error('Error while polling for new messages:', error);
      }
    };

    //run the polling function immediately
    doPolling();

    // Set the function so external systems can trigger a poll
    doPollingRef.current = doPolling;

    // Start polling every N seconds
    pollingInterval = setInterval(doPolling, intervalSeconds * 1000);

    // Function to stop polling
    const stopPolling = () => {
      if (pollingInterval) {
        clearInterval(pollingInterval);
      }
    };

    // Return the stopPolling function to allow manual stopping of the polling
    return {
      stopPolling
    };
  };

  const fetchMessages = () => {
    if (doPollingRef.current) {
      doPollingRef.current();
    }
  };

  return {
    init,
    sendFile,
    updateMessage,
    appMessageClient,
    sendMessage,
    setMessageStatus,
    getContacts,
    getMessageHistory,
    getSupportUser,
    isOnline,
    fetchMessages,
    sendTypingStatus,
    setContactState,
    findContact,
    addContact,
    removeContact,
    listenForMessages // Return the new method
  };
}
