import React from 'react'
import { ArrowDownTrayIcon, TrashIcon } from '@heroicons/react/24/outline'
import CircularProgress from '../CircularProgress'

const FileBubble = ({ message, avatar, isSupport }) => {
  const { data } = message
  const { file, status, progress } = data

  let iconOrProgress
  if (status === 'uploading') {
    iconOrProgress = <CircularProgress progress={progress} />
  } else if (status === 'ready') {
    iconOrProgress = (
      <div
        className="bg-blue-900 p-2 rounded-full cursor-pointer"
        onClick={() => window.open(data.fileUrl, '_blank')}
      >
        <ArrowDownTrayIcon className="w-6 h-6 text-white" />
      </div>
    )
  } else if (status === 'deleted') {
    iconOrProgress = <TrashIcon className="w-6 h-6 text-white cursor-pointer" />
  }

  return (
    <div
      className={`flex ${
        isSupport ? 'items-start' : 'justify-end'
      } space-x-2`}
      style={{ maxWidth: '100%' }} // Ensure it stays within the chat area
    >
      {isSupport && avatar && (
        <img src={avatar} alt="Support" className="w-8 h-8 rounded-full" />
      )}

      <div
        className="min-h-[90px]  relative bg-blue-700 text-white p-3 rounded-lg text-sm flex items-center justify-between w-full"
        style={{ maxWidth: '90%'  }} // Set a percentage-based max width
      >
        <div className="absolute top-1 left-2 font-bold">File</div>
        <div className="flex-1 truncate">{file}</div>
        <div className="ml-3">{iconOrProgress}</div>
      </div>
    </div>
  )
}

export default FileBubble
