//create a component called Toolbar that will be used to display the toolbar of the PDF viewer. The toolbar will contain the following buttons:
// - Open file button
// - Print button
// - Download button
// - Rotate left button
// - Rotate right button

import React from 'react'
import Button from '../Button'
//Tools
import Zoom from '../FileViewerTools/zoom'
import Rotate from '../FileViewerTools/rotate'
import MinimapToggle from '../FileViewerTools/minimap-toggle'
import Preview from '../FileViewerTools/preview'
import Download from '../FileViewerTools/download'
import PageNavigator from '../FileViewerTools/page-navigator'
import NewFile from '../FileViewerTools/new-file'
import isMobile from 'is-mobile'
import { useState, useEffect } from 'react'
import History from '../FileViewerTools/history'
export default ({ controller }) => {
  const [isMobileDevice, setIsMobileDevice] = useState(undefined)

  useEffect(() => {
    setIsMobileDevice(isMobile())
  }, [])

  if (isMobileDevice === undefined) return null

  return isMobileDevice ? (
    <div className="absolute bottom-0 justify-between w-full z-10 flex flex-row h-[60px] bg-sidebar px-5 overflow-x-auto whitespace-nowrap -webkit-overflow-scrolling-touch">
      <div className="flex flex-row space-x-2 gap-5">
        <Preview controller={controller} />
        <NewFile controller={controller} />
        <MinimapToggle controller={controller} />
        <Download controller={controller} />
        <PageNavigator controller={controller} />
        <Rotate rotate={controller.setRotate} />
        <Zoom controller={controller} />
        <History controller={controller} />
      </div>
    </div>
  ) : (
    <div className="relative top-0 justify-between w-full z-10 flex flex-row h-[60px] space-x-2 bg-sidebar gap-5 px-5 overflow-x-auto whitespace-nowrap -webkit-overflow-scrolling-touch">
      <Preview controller={controller} />
      <NewFile controller={controller} />
      <MinimapToggle controller={controller} />
      <Download controller={controller} />
      <PageNavigator controller={controller} />
      <Rotate rotate={controller.setRotate} />
      <Zoom controller={controller} />
      <History controller={controller} />
    </div>
  )
}
