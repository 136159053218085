import Button from '../Button'
import { useTranslation } from '../../utils/localization'
import { showPromptDialog } from '../PromptDialog/index.jsx'
import Tooltip from '../Tooltip/index.jsx'
export default ({ controller }) => {
  const t = useTranslation()

  //new file can't be called if there is no appController
  if (!controller.appController) return null

  return (
    <div className={'flex items-center space-x-2'}>
      <Tooltip text={t`Start over with a new file`}>
        <Button
          variant="action"
          onClick={() => {
            showPromptDialog({
              message: t`Are you sure you want to leave this translation?`,
              title: t`Leave Translation`,
              onConfirm: controller.appController.newOrder,
              onCancel: () => {},
            })
          }}
        >
          {t`New File`}
        </Button>
      </Tooltip>
    </div>
  )
}
