import React, { useEffect, useState } from 'react'
import isMobile from 'is-mobile'
import AddCreditsMobile from './addCreditsMobile.jsx'
import AddCreditsDesktop from './addCreditsDesktop.jsx'

import { useSearchParamMutation } from '../../utils/searchParams.js'
import { useRecoilValueLoadable } from 'recoil'
import {
  accountSelector,
  subscriptionSelector,
} from '../../utils/platform_auth'

const AddCreditsButton = () => {
  const { add: addSearchParam } = useSearchParamMutation()
  const [isMobileDevice, setIsMobileDevice] = useState(undefined)

  const { contents: account, state: accountState } = useRecoilValueLoadable(
    accountSelector,
  )
  const {
    contents: subscription,
    state: subscriptionState,
  } = useRecoilValueLoadable(subscriptionSelector)

  useEffect(() => {
    setIsMobileDevice(isMobile())
  }, [])

  if (isMobileDevice === undefined) return null

  return (
    <>
      {isMobileDevice ? (
        <AddCreditsMobile account={account} hasSubscription={!!subscription} />
      ) : (
        <AddCreditsDesktop account={account} hasSubscription={!!subscription} />
      )}
    </>
  )
}

export default AddCreditsButton
